import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Opportunity} from './opportunityComponent'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {SingleEventsFxn} from "../Events/action";
import {useDispatch} from "react-redux";

const $ = window.$

const Gallery = (props) => {
    let dispatch = useDispatch()
    const filterSelectionInner = (value) => {
        window.filterSelectionFxn(value)
    }
    let [gallery, setGallery] = useState([])
    let [data, setData] = useState({})
    useEffect(() => {
        loadSingleEvent()
    }, [])
    const loadSingleEvent = async () => {
        let eventId = window.location.pathname.split('/').pop()
        let resp = await dispatch(SingleEventsFxn(eventId));
        setData(resp)
        if (resp && resp.gallery && resp.gallery.length) {
            setGallery(resp.gallery)
        }
        setTimeout(() => {
            window.filterSelectionFxn('webinar')
        }, 500)
    }

    return (
        <div>
            <Helmet>
                <script src="../assets/js/gallery.js" type="text/javascript"></script>
            </Helmet>
            <section className="banner blog-banner gallery-banner">

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="content text-left">
                                <h1>Personal Meets & Webinar</h1>
                              <p>
                                {data.name}
                              </p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="img">
                                <LazyLoadImage src="/assets/image/gallery-banner.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/*   <section className="launch-line">
                <div className="container">

                    <p>Navigate through the snapshots and videos capturing the highlights of <br/>our regular education
                        and
                        immigration seminars; student testimonials narrating <br/>their success stories and why they
                        find our
                        assistance and guidance services valuable; <br/>our immigration partners expressing how
                        connecting with us
                        has been lucrative<br/> and, much more. Contact us and be a part of our roll of honours!</p>

                </div>
            </section>
*/}
            <section className="gallery-box">

                <div className="container">
                    <div id="myBtnContainer">
                      <button className='btn active'> Personal Meets & Webinar</button>
                    </div>

                    <div>
                        <div id="gallery">

                            {gallery && gallery.length ? gallery.map((item) => {
                                return (
                                    <>
                                        <LazyLoadImage src={item.path} alt={item.name} key={item._id}
                                                       className="column webinar img-responsive"/>
                                    </>
                                )
                            }) : null}


                        </div>

                    </div>
                </div>

            </section>

            <Opportunity/>

            <Helmet>
                <script src="../assets/js/gallery.js" type="text/javascript" async defer/>

            </Helmet>


        </div>
    )
}
export default Gallery
