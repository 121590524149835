import React, { Suspense, useEffect, useState } from 'react'
import { LazyLoadImage as Image } from 'react-lazy-load-image-component'
import renderHTML from 'react-render-html'
import { facilityImages } from '../../components/_utils/homeUtil'
import RelatedBuildings from '../SIngleProperties/RelatedBuildings'
import RoomComponent from '../SIngleProperties/RoomComponent'
import { accomodationListFxn } from '../actions'
// import stickHeaderScript from '../assets/customJs/stickHeader.js'
import SliderComponent from './sliderComponent'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
// const SliderComponent = loadable(() => import('./sliderComponent'))
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { connect } from 'react-redux'


const StudentAcomodation = (props) => {
  let [property_slug, setProperty_slug] = useState('')
  let [roomId, setRoomId] = useState('')
  useEffect(() => {
    getSinglePropertyData()
  }, [])
  const [accommodation, setAccomodation] = useState({})
  const getSinglePropertyData = async () => {
    // const pathname = window.location.pathname
    // const property_slug = pathname.split('/student-accommodation/')[1]
    const pathname = window.location.pathname
    const property_slug = pathname.split('/').pop()
    const params = new URLSearchParams(window.location.search)
    let room_Id = parseInt(params.get('roomId'))
    if (room_Id) {
      setRoomId(room_Id)
    }
    setProperty_slug(pathname)

    let resp = await accomodationListFxn({property_slug : pathname})
    if (resp) {
      setAccomodation(resp.accommodation)
    }
  }

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/singleProperty.css' />
        <link rel='stylesheet' href='../../unizHome/css/slider.css' />
        <script src='../../unizHome/scripts/slick.min.js' async></script>
        <script src='../../unizHome/scripts/stickHeader.js' async></script>
      </Helmet>
      <div className='sub-banner sb-two'>
        <div className='overlay'>
          <div className='container'>
            <div className='breadcrumb-area'>
              <h1>Properties Detail</h1>
              <ul className='breadcrumbs'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='active'>Properties Detail</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {accommodation && accommodation.building ? (
        <div className='content-area  properties-details-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-10 col-md-12 col-sm-12'>
                <SliderComponent
                  accommodation={accommodation.building.buildingRelated}
                />
              </div>
              <div className='col-lg-10 col-md-12 col-sm-12'>
                <div className='heading-properties clearfix sidebar-widget sw2 '>
                  <div className='pull-left'>
                    <h3>{accommodation.building.name}</h3>
                    <div>
                      <i className='fa fa-map-marker' />
                      &nbsp;
                      {accommodation.building.address}
                    </div>
                  </div>
                  <div className='pull-right'>
                    <h3>
                      <span>{accommodation.building.min_price}/</span>
                    </h3>
                    <h5>{accommodation.building.price_structure}</h5>
                  </div>
                </div>
                <div className='properties-description mb-30'>
                  <div className='main-title-4'>
                    <h1>
                      <span>Description</span>
                    </h1>
                  </div>
                  <div>{renderHTML(accommodation.building.desc)}</div>
                  <br />
                </div>

                <div className='properties-condition'>
                  <div className='main-title-4'>
                    <h1>
                      <span>Facilities</span>
                    </h1>
                  </div>
                  <ul className='condition row'>
                    {accommodation.building.facilities &&
                    accommodation.building.facilities.length
                      ? accommodation.building.facilities.map(
                        (eachFac, key) => {
                          return (
                            <li key={key} className={'col-md-4 space'}>
                              {facilityImages[eachFac.class] ? (
                                <LazyLoadImage
                                  src={facilityImages[eachFac.class]}
                                  height={20}
                                  width={20}
                                  style={{ marginRight: 10 }}
                                />
                              ) : null}
                              <span> {eachFac.facilities_name}</span>
                            </li>
                          )
                        }
                      )
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {accommodation && accommodation.building ? (
        <RoomComponent
          roomId={roomId}
          building={accommodation.building}
          property_slug={property_slug}
          {...props}
        />
      ) : null}
      {accommodation && accommodation.building ? (
        <RelatedBuildings building={accommodation.building} />
      ) : null}
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAcomodation)
