import React, { useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { searchDataFxn } from '../actions'
import _ from 'lodash'


const CityComponent = (props) => {
  let { data } = props
  let openPage = (item) => {
    window.open(`/properties-list/${item.slug}`, '_blank')
  }
  return (
    <div className={'filterBlock'}>
      <h3>Cities</h3>
      <ul>
        {data.map((item) => {
          return (
            <li key={item.id}>
              <a onClick={() => {
                openPage(item)
              }}>{item.name}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
const BuildingComponent = (props) => {
  let { data } = props
  let openPage = (item) => {
    let propertyUrl = item.link.split('/').pop()
    window.open(`/student-accommodation/${propertyUrl}`, '_blank')
  }
  return (
    <div className={'filterBlock'}>
      <h3>Buildings</h3>
      <ul>
        {data.map((item) => {
          return (
            <li key={item.id}>
              <a onClick={() => {
                openPage(item)
              }}>{item.name}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
const BannerComponent = (props) => {
  let [visibleSearch, setVisibleSearch] = useState(false)
  let [allUkCities, setAllUkCities] = useState([])
  let [allUkBuildings, setAllUkBuildings] = useState([])
  let [ukCities, setUkCities] = useState([])
  let [ukBuildings, setUkBuildings] = useState([])
  let [search, setSearch] = useState('')
  const events = {
    showSearch: () => {
      setVisibleSearch(true)
    },
    hideSearch: () => {
      setTimeout(() => {
        setVisibleSearch(false)
        setUkCities([])
        setUkBuildings([])
        setSearch('')
      }, 200)
    },
    loadData: async () => {
      let { data } = await searchDataFxn()
      let { Cities, Buildings } = data
      let cityData = _.filter(Cities, (item) => {
        return item.country_name == 'United Kingdom'
      })
      setAllUkCities(cityData)
      let buildingData = _.filter(Buildings, (item) => {
        return item.link.indexOf('-uk') >= 0
      })
      setAllUkBuildings(buildingData)
    },
    filterData: (value) => {
      setSearch(value)
      let findCity = _.filter(allUkCities, (item) => {
        return item.name.match(new RegExp(`^${value}`, 'ig'))
      })
      let findBuilding = _.filter(allUkBuildings, (item) => {
        return item.name.match(new RegExp(`^${value}`, 'ig'))
      })
      setUkCities(findCity)
      setUkBuildings(findBuilding)
    }
  }
  useEffect(() => {
    events.loadData()
  }, [])
  return (
    <>
      <section className='banner banner1'>
        <LazyLoadImage src='../unizHome/banner.jpg' alt='' />

        <div className='bannerContainer'>
          <div className={'banner-content'}>
            <div className={'row'}>
              <div className='col-lg-12'>
                <div className='content bannerText'>
                  <h1>FIND YOUR DREAM HOUSE </h1>
                  <p>Find new & featured property located in your local city.</p>
                </div>
                <div className='search-area'>
                  <div className='search-area-inner'>
                    <form>
                      <div className='w-83 form-group b-right'>
                        <input
                          type='text'
                          name='full-name'
                          className='input-text search-fields'
                          placeholder='Enter Keyword'
                          value={search}
                          onChange={(e) => {
                            events.filterData(e.target.value)
                          }}
                          onFocus={() => {
                            // events.showSearch()
                          }}
                          onBlur={() => {
                            events.hideSearch()
                          }}
                        />
                      </div>
                      <div
                        className='w-17 w-17-2 form-group b-right b-right2'>
                        <button className='search-button'>
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                  {((ukCities && ukCities.length) || (ukBuildings && ukBuildings.length)) && search ?
                    <div className={'propertySearch'}>
                      <div className={'innerBox'}>
                        {
                          ukCities && ukCities.length ?
                            <>
                              <CityComponent data={ukCities} />
                            </> : null
                        }
                        {
                          ukBuildings && ukBuildings.length ?
                            <>
                              <BuildingComponent data={ukBuildings} />
                            </> : null
                        }
                      </div>
                    </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default BannerComponent
