import React, { Suspense, useState, useEffect } from 'react'
import { LazyLoadImage as Image } from 'react-lazy-load-image-component'
import _ from 'lodash'
import { facilityImages } from '../../components/_utils/homeUtil'
import { propertiesListFxn } from '../actions'
import { Helmet } from 'react-helmet'
import { Pagination } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const checkedImg = require('../../localImage/checked.png')
const unCheckedImg = require('../../localImage/unchecked.png')

const SingleBuilding = ({ item }) => {
  const maxFacilities = 4
  const toggleShowAll = () => setShowAll(!showAll)
  const [showAll, setShowAll] = useState(false)

  let openPage = () => {
    let propertyUrl = item.relative_url.split('/').pop()
    window.open(`/student-accommodation/${propertyUrl}`, '_blank')
  }
  return (
    <div
      className='property row g-0 fp2 clearfix wow fadeInUp delay-03s'
      key={item.id.toString()}>
      <div className='col-lg-5 col-md-5 col-sm-12' style={{ padding: 0 }}>
        <div className='property-img'>
          <div className='property-price'>${item.min_price} /week</div>
          <LazyLoadImage
            src={item.image_path_thumb}
            alt='fp-list'
            className='img-responsive hp-1'
          />
          <div className='property-overlay'>
            <a
              onClick={() => {
                openPage()
              }}
              className='overlay-link'>
              <i className='fa fa-link' />
            </a>

          </div>
        </div>
      </div>
      <div className='col-lg-7 col-md-7 col-sm-12 property-content'>
        <div className='info'>
          <h1 className='title'>
            <a
              onClick={() => {
                openPage()
              }}>
              {item.name}
            </a>
          </h1>
          <h3 className='property-address'>
            <a
              onClick={() => {
                openPage()
              }}>
              <i className='fa fa-map-marker' style={{ marginRight: 5 }} />
              {item.address}
            </a>
          </h3>
          <ul className='facilities-list facilities-list2 clearfix'>
            {item.facilities && item.facilities.length
              ? item.facilities
                .slice(0, showAll ? item.facilities.length : maxFacilities)
                .map((eachFac, key) => {
                  return (
                    <li key={key} style={{ marginTop: 2 }}>
                      {facilityImages[eachFac.class] ? (
                        <Image
                          src={facilityImages[eachFac.class]}
                          height={22}
                          width={22}
                          style={{ marginRight: 10 }}
                        />
                      ) : null}
                      <span style={{ fontSize: 12.8 }}>{eachFac.title}</span>
                    </li>
                  )
                })
              : null}
          </ul>
          <div className={'alignRight'}>
            {item.facilities && item.facilities.length > maxFacilities ? (
              <a onClick={toggleShowAll} className={'showMoreBtn'}>
                {showAll
                  ? 'Less...'
                  : `+${item.facilities.length - maxFacilities} more...`}
              </a>
            ) : null}
          </div>

          <span style={{ marginTop: 11, fontSize: 16 }} className='fa fa-map-marker'>
            {item.distance}
          </span>
        </div>
        <div className='property-footer'>
          <span className='left'>
            <a>
              <i className='fa fa-user' />
              {item.provider_name}
            </a>
          </span>
          <span className='right'>
            <a>{item.rating} Rating</a>
          </span>
        </div>
      </div>
    </div>
  )
}
const CheckboxGroup = props => {
  let {
    options,
    name,
    filterName,
    handleChange,
    data,
    selectedValues,
    setFilterCheckboxState
  } = props

  const maxCheckBoxes = 6
  const toggleShowAll = () => setShowAll(!showAll)
  const [showAll, setShowAll] = useState(false)

  let checkIsExists = value => {
    let find = _.find(data, item => {
      return item == value
    })
    return find ? find : false
  }

  let checkIsExistsNew = value => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][value] === true) {
        return true
      }
    }
    return false
  }
  const handleCheckboxChange = value => {
    handleChange(name, value, options)
  }

  return (
    <div className={'custom-checkbox'}>
      <div className='main-title-4'>
        <h1>{filterName}</h1>
      </div>
      <ul key={JSON.stringify(data)}>
        {options && options.length
          ? options
            .slice(0, showAll ? options.length : maxCheckBoxes)
            .map((item, key) => {
              return (
                <li key={item.label}>
                  <a
                    onClick={() => {
                      handleCheckboxChange(item.value)
                    }}>
                      <span className={'checkbox'}>
                        {checkIsExists(item.value) ||
                        checkIsExistsNew(item.value) ? (
                          <Image src={checkedImg} height={25} />
                        ) : (
                          <Image src={unCheckedImg} height={25} />
                        )}
                      </span>
                    &nbsp; {item.label}
                  </a>
                </li>
              )
            })
          : null}
      </ul>
      <div className={'alignRight'}>
        {options && options.length > maxCheckBoxes ? (
          <a onClick={toggleShowAll} className={'showMoreBtn'}>
            {showAll ? 'Less...' : `+${options.length - maxCheckBoxes} more...`}
          </a>
        ) : null}
      </div>
    </div>
  )
}

const PropertiesList = props => {
  let [filterData, setFilterData] = useState({})
  const [city, setCityinfo] = useState('')
  let [building, setBuilding] = useState([])
  let [pagination, setPagination] = useState({})
  let [page, setPage] = useState(1)

  let loadPropertyList = async () => {
    const pathname = window.location.pathname
    let city = pathname.split('/').pop()
    if (city) {
      setCityinfo(city)
    }

    let resp = getQueryFilter()
    let data = await propertiesListFxn({ city, page, ...resp })
    let { pagination, buildings } = data
    if (data) {
      setBuilding(buildings)
    }
    if (pagination) {
      setPagination(pagination)
    }
  }

  useEffect(() => {
    updateFilterCheckboxState()
  }, [])


  const updateFilterCheckboxState = () => {
    setFilterCheckboxState((prevState) => {
      return {
        ...prevState,
        accommodationType: filterData.provider_type_id
          ? filterData.provider_type_id
          : [],
        roomType: filterData['room-types']
          ? filterData['room-types'].split('|')
          : [],
        facilities: filterData.facilities
          ? filterData['facilities'].split('|')
          : []

      }
    })
  }
  const [filterCheckboxState, setFilterCheckboxState] = useState({
    accommodationType: [],
    roomType: [],
    facilities: [],
    cancellationPolicy: []
  })

  const handleAccommodationTypeChange = (name, value, options) => {
    let oldData = filterCheckboxState[name]
    if (oldData.includes(value)) {
      oldData = _.reject(oldData, item => {
        return item == value
      })
    } else {
      oldData = [value]
    }
    setFilterCheckboxState(prevData => {
      return {
        ...prevData,
        [name]: oldData
      }
    })
  }


  const handleTypeChange = (name, value) => {
    let oldData = filterCheckboxState[name]
    if (oldData.includes(value)) {
      oldData = _.reject(oldData, item => {
        return item == value
      })
    } else {
      oldData.push(value)
    }
    setFilterCheckboxState(prevData => {
      return {
        ...prevData,
        [name]: oldData
      }
    })
  }

  const AccommodationType = [
    { label: 'Student Accommodation', value: '1' },
    { label: 'Student Housing', value: '2' },
    {
      label: 'Young or Working Professional Housing',
      value: '3'
    }
  ]

  const RoomType = [
    { label: 'Shared Room', value: 'shared-room' },
    { label: 'Shared Flat', value: 'shared-flat' },
    { label: 'Entire Place', value: 'entire-place' },
    { label: 'En-Suite', value: 'en-suite-room' },
    { label: 'Studio', value: 'studio' },
    { label: 'Single', value: 'single-room' },
    { label: 'Twin', value: 'twin' },
    { label: 'Double', value: 'double-room' },
    { label: 'Flat', value: 'flat' },
    { label: 'Penthouse', value: 'penthouse' },
    { label: 'Triple', value: 'triple' },
    { label: 'Quadruple', value: 'quadruple' }
  ]

  const Facilities = [
    { value: '247-security-cctv', label: '24/7 Security + CCTV' },
    { value: 'accessibility', label: 'Accessibility' },
    { value: 'advice', label: 'Advice' },
    { value: 'air-conditioning', label: 'Air Conditioning' },
    { value: 'bed-and-breakfast', label: 'Bed and Breakfast' },
    { value: 'bills-included', label: 'Bills Included' },
    { value: 'central-heating', label: 'Central Heating' },
    { value: 'cleaning-service', label: 'Cleaning Service' },
    { value: 'communal-games-room', label: 'Communal Games Room' },
    { value: 'communal-kitchen', label: 'Communal Kitchen' },
    { value: 'emergency', label: 'Emergency' },
    { value: 'flexible-start-dates', label: 'Flexible Start Dates' },
    { value: 'fully-furnished', label: 'Fully-Furnished' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'laundry', label: 'Laundry' },
    { value: 'on-site-cinema-room', label: 'On-Site Cinema Room' },
    { value: 'on-site-gym', label: 'On-Site Gym' },
    { value: 'on-site-maintenance', label: 'On-Site Maintenance' },
    { value: 'on-site-management', label: 'On-Site Management' },
    { value: 'parking', label: 'Parking' },
    { value: 'pet-friendly', label: 'Pet Friendly' },
    { value: 'secure-bike-storage', label: 'Secure Bike Storage' },
    { value: 'secure-rooms', label: 'Secure Rooms' },
    { value: 'social-area', label: 'Social Area' },
    { value: 'storage-space', label: 'Storage Space' },
    { value: 'study-rooms', label: 'Study Rooms' },
    { value: 'swimming-pool', label: 'Swimming Pool' },
    { value: 'under-18', label: 'Under 18' },
    { value: 'vending-machine', label: 'Vending Machine' },
    { value: 'wi-fi', label: 'Wi-Fi' }
  ]
  const CancellationPolicy = [
    { label: 'COVID-19 Cancellation Policy', value: 'covid' },
    { label: 'No Visa No Pay', value: 'novisa' },
    { label: 'No Place No Pay', value: 'noplace' }
  ]

  const getQueryFilter = () => {
    let obj = {}
    let covid = false
    let noVisa = false
    let noPlace = false
    if (
      filterCheckboxState.accommodationType &&
      filterCheckboxState.accommodationType.length
    ) {
      /*  newPath += `&provider_type_id=${filterCheckboxState.accommodationType.join(
            '%7C'
        )}`*/
      obj['provider_type_id'] = filterCheckboxState.accommodationType.join('%7C')
    }
    if (filterCheckboxState.roomType && filterCheckboxState.roomType.length) {
      // newPath += `&room-types=${filterCheckboxState.roomType.join('%7C')}`
      obj['roomType'] = filterCheckboxState.roomType.join('%7C')

    }
    if (
      filterCheckboxState.facilities &&
      filterCheckboxState.facilities.length
    ) {
      // newPath += `&facilities=${filterCheckboxState.facilities.join('%7C')}`
      obj['facilities'] = filterCheckboxState.facilities.join('%7C')

    }
    if (
      filterCheckboxState.cancellationPolicy &&
      filterCheckboxState.cancellationPolicy.length
    ) {
      const cancellationPolicy = filterCheckboxState.cancellationPolicy


      if (cancellationPolicy.includes('covid')) {
        covid = true
      }

      if (cancellationPolicy.includes('novisa')) {
        noVisa = true
      }

      if (cancellationPolicy.includes('noplace')) {
        noPlace = true
      }
    }

    return {
      ...obj,
      covid: covid,
      novisa: noVisa,
      noplace: noPlace
    }
  }


  useEffect(() => {
    loadPropertyList()
  }, [filterCheckboxState, page])


  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/propertyList.css' />

      </Helmet>
      <div className='sub-banner'>
        <div className='overlay'>
          <div className='container'>
            <div className='breadcrumb-area'>
              <h1>Properties Listing</h1>
              <ul className='breadcrumbs'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='active'>Student Accommodation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='properties-section-body content-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12 col-xs-12'>
              <div className='option-bar'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h4>
                      <span className='heading-icon'>
                        <i className='fa fa-th-list'></i>
                      </span>
                      <span className='title'>Student Accommodation</span>
                    </h4>
                  </div>
                  <div className='col-lg-7'></div>
                </div>

              </div>
              {building && building.length
                ? building.map((item, key) => {
                  return <SingleBuilding item={item} />
                })
                : null}

              {pagination && pagination.count ?
                <Pagination
                  defaultCurrent={pagination.page}
                  defaultPageSize={pagination.count}
                  onChange={(page) => {
                    setPage(page)
                  }}
                  total={pagination.total_count} /> : null}
            </div>
            <div className='col-lg-4 col-md-12 col-xs-12'>
              <div className='sidebar-widget advanced-search'>
                <form method='GET'>
                  <div className='form-group'>
                    <CheckboxGroup
                      options={AccommodationType}
                      name={'accommodationType'}
                      filterName={'Accommodation Type'}
                      data={filterCheckboxState.accommodationType}
                      handleChange={handleAccommodationTypeChange}
                    />
                  </div>
                  <div className='form-group'>
                    <CheckboxGroup
                      options={Facilities}
                      name={'facilities'}
                      filterName={'Facilities'}
                      data={filterCheckboxState.facilities}
                      setFilterCheckboxState={setFilterCheckboxState}
                      handleChange={handleTypeChange}
                    />
                  </div>
                  <div className='form-group'>
                    <CheckboxGroup
                      options={RoomType}
                      name={'roomType'}
                      filterName={'Room Type'}
                      data={filterCheckboxState.roomType}
                      handleChange={handleTypeChange}
                    />
                  </div>
                  <div className='form-group'>
                    <CheckboxGroup
                      options={CancellationPolicy}
                      name={'cancellationPolicy'}
                      filterName={'Cancellation Policy'}
                      data={filterCheckboxState.cancellationPolicy}
                      handleChange={handleTypeChange}
                    />
                  </div>

                  {/*<div className="range-slider">
                      <label>Area</label>
                      <div
                        data-min="0"
                        data-max="10000"
                        data-unit="Sq ft"
                        data-min-name="min_area"
                        data-max-name="max_area"
                        className="range-slider-ui ui-slider"
                        aria-disabled="false"
                      ></div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="range-slider">
                      <label>Price</label>
                      <div
                        data-min="0"
                        data-max="150000"
                        data-unit="USD"
                        data-min-name="min_price"
                        data-max-name="max_price"
                        className="range-slider-ui ui-slider"
                        aria-disabled="false"
                      ></div>
                      <div className="clearfix"></div>
                    </div>*/}
                  <div className='form-group mb-0'>
                    {/* <button
                                            type="submit"
                                            className="button-md button-theme btn-3 w-100"
                                            onClick={handleSearch}>
                                            Search
                                        </button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PropertiesList
