import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Modal,
    Input,
    Upload,
    Select,
    notification
} from 'antd';
import { updateStudentDataFxn } from "../actions";
import ArtBoard from "../../../assets/Artboard–20.png";
import PassFront from "../../../assets/Passport-Front1.png";
import Pancard from "../../../assets/Pan-card-icon.png";
import OtherDoc from "../../../assets/otherdoc.png";
import { relationTypes } from "../reeudoUtils";

const { Option } = Select;

const AddBasicDetails = (props) => {
    const {
        form: { getFieldDecorator, setFieldsValue },
        onClose,
        visible,
    } = props;

    const dispatch = useDispatch();
    const [uploadedDocuments, setUploadedDocuments] = useState([]);

    const documents = [
        { label: 'Passport', imgSrc: PassFront, docName: 'PASSPORT' },
        { label: 'Aadhaar Card', imgSrc: OtherDoc, docName: 'AADHAAR' },
        { label: 'PAN', imgSrc: Pancard, docName: 'PAN' },
        { label: 'Offer Letter', imgSrc: ArtBoard, docName: 'OFFER_LETTER' },
        { label: 'Cheque Copy', imgSrc: ArtBoard, docName: 'CHEQUE' },
        { label: 'Other Document', imgSrc: OtherDoc, docName: 'OTHER' },
    ];

    const inputTypes = {
        fields: [
            {
                key: "relationToStudent",
                label: "Relation to Student",
                required: true,
                placeholder: "Enter your relation to the student",
                type: 'select',
                options: relationTypes,
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        relationToStudent: x
                    });
                }
            },
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "phoneNumber",
                label: "Phone",
                required: true,
                placeholder: "Phone",
                type: "text",
            },
            {
                key: "emailAddress",
                label: "Email",
                required: true,
                placeholder: "Email",
                type: "text",
            },
        ],
    };

    const handleFileChange = (file, docName, docType) => {
        const newDocuments = [...uploadedDocuments, { file, docName, docType }];
        setUploadedDocuments(newDocuments);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { form } = props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const formData = new FormData();

                Object.keys(values).forEach(key => {
                    formData.append(key, values[key]);
                });

                uploadedDocuments.forEach((doc, index) => {
                    formData.append(`documents[${index}]`, doc.file);
                    formData.append(`documentName[${index}]`, doc.name);
                    formData.append(`documentType[${index}]`, doc.type);
                });

                let resp = await dispatch(updateStudentDataFxn(formData));

                if (resp.success) {
                    notification.success({ message: 'Details saved successfully' });
                    onClose();
                } else {
                    notification.error({ message: resp.message });
                }
            }
        });
    };


    return (
        <Modal
            visible={visible}
            title={"Add Basic Details"}
            onCancel={onClose}
            width={"68%"}
            footer={null}
        >
            <Form>
                <div>
                    <h6>Document Upload
                        <span style={{ fontSize: '10px', color: 'red' }}>(Max File Size 5MB)</span>
                    </h6>
                    <div className={'row'}>
                        {documents.map((doc, index) => (
                            <div key={index} className={'mt20 custom-exchange-col col-md-2'}>
                                <div
                                    className='custom-exchange bordered'
                                    title={<span className='custom-exchange-title'>{doc.label}</span>}
                                    bordered={false}
                                    style={{ textAlign: 'center', marginBottom: '16px' }}
                                >
                                    <div className="custom-exchange-body">
                                        <p className={'doc-label'}>{doc.label}</p>
                                        <img src={doc.imgSrc} alt={doc.label} className="custom-exchange-img" />
                                        <Upload
                                            beforeUpload={(file) => {
                                                handleFileChange(file, doc.docName, doc.docName);
                                                return false;
                                            }}
                                            showUploadList={false}
                                        >
                                            <Button type='primary' className="custom-exchange-btn">Upload</Button>
                                        </Upload>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'row'}>
                        {inputTypes.fields.map(field => (
                            <div key={field.key} className={'col-md-6'}>
                                <Form.Item label={field.label}>
                                    {getFieldDecorator(field.key, {
                                        rules: [{ required: field.required, message: `${field.label} is required` }],
                                    })(
                                        field.type === 'select' ? (
                                            <Select
                                                placeholder={field.placeholder}
                                                onChange={field.onChange}
                                            >
                                                {field.options.map(option => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <Input placeholder={field.placeholder} className={'form-control'} />
                                        )
                                    )}
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                </div>

                <Button type={"primary"} htmlType="submit" className={"mt-4 ml-4 round-bt text-center"} onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
        </Modal>
    );
};

export default Form.create()(AddBasicDetails);
