import {apiUrl} from "../../settings";

export const addBookingUrl = () => {
    return apiUrl + '/addBooking'
}
export const singleBookingUrl = () => {
    return apiUrl + '/singleBooking'
}
export const updateBookingUrl = () => {
    return apiUrl + '/updateBooking'
}
export const submitFinalCheckoutUrl = () => {
    return apiUrl + '/submitFinalCheckout'
}


export const submitEnquiriesUrl = () => {
    return apiUrl + '/submitEnquiries'
}


