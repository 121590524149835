import React, {useState, useEffect, useRef} from 'react';
import PageHeaderWrapper from '../../../components/PageHeaderWrapper';
import {
    Button,
    Card, Col, DatePicker,
    Row, Tooltip
} from 'antd';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import {TableComp} from 'sz-react-utils-lite';
import {useHistory} from 'react-router-dom';
import {dateFilterRange, InputBox, newFormatDisplayDate} from "../../../components/_utils/appUtils";
import moment from "moment";
import {allTransactionFxn} from "../actions";
import AddBasicDetails from './agentDocs'
import {ColumnWidth} from "../../WebComponent/columnWidth";
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const initFilters = {
    startDate: moment().subtract(2, 'months').format(dateFormat),
    endDate: moment().format(dateFormat),

};
let reeudoMaster = ['admin', 'reeudoManager', 'itTeam']
const TxnList = (props) => {
    let {user} = props;
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState(initFilters);
    const [isModalVisible, setModalVisible] = useState(false);
    const tableRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };
    const apiRequest = async () => {
        let params = {
            results: 100,
            count: 100
        }
        return new Promise(async (resolve) => {
            let resp = await dispatch(allTransactionFxn(params));
            if (resp && resp.data) {
                let sortedData = resp.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setTotal(resp.total)
                console.log(resp, "resppp")
                resolve({data: sortedData})
            }
        });
    };


    const handleEdit = (appId) => {
        history.push(`/editTxnStudent?appId=${appId}`);
    };

    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            render: (val, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item) => <div>{newFormatDisplayDate(item)}</div>
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone No',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        // },
        {
            title: 'Agent',
            key: 'name',
            dataIndex: 'agentId',
            hidden: !reeudoMaster.includes(user.userType),
            render: (item) => {
                return (
                    <ColumnWidth width={150}>
                        {item && item.name ? item.name : ""}
                    </ColumnWidth>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            hidden: !reeudoMaster.includes(user.userType),
            render: (item, record) => {
                let {agentId} = record;
                return (
                    agentId ? <div>
                        <MobileEmailInfo data={agentId} type={'email'}
                                         value={agentId && agentId.email ? agentId.email : ""}/>
                    </div> : null
                )
            }
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile',
            key: 'mobile',
            hidden: !reeudoMaster.includes(user.userType),
            render: (item, record) => {
                let {agentId} = record;
                return (
                    agentId ? <div>
                        <MobileEmailInfo data={agentId} type={'email'}
                                         value={agentId && agentId.mobile ? agentId.mobile : ""}/>
                    </div> : null
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Txn Id',
            dataIndex: 'rappId',
            key: 'rappId',
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 80,
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {user && user.userType && reeudoMaster.includes(user.userType) ? <Tooltip title="Edit">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => handleEdit(record.rappId)}
                                icon="edit"
                            />
                        </Tooltip> : null}
                        {user && user.userType == "agent" ?
                            <Tooltip title="Update">
                                <Button
                                    shape="circle"
                                    size="small"
                                    onClick={showModal}
                                    icon="sync"
                                />
                            </Tooltip> : null}
                    </React.Fragment>
                );
            }
        }
    ];

    const updateFilter = (data) => {
            setFilters((prevState) => ({
                ...prevState,
                ...data
            }));
        }
    ;

    useEffect(() => {
            if (filters.startDate || filters.endDate) {
                if (tableRef.current) {
                    tableRef.current.reload();
                }
            }
        }
        , [filters.startDate, filters.endDate]);

    const FilterComponent = () => {
            return (
                <>
                    <Row gutter={12} className={'filter_box mt5'}>
                        <Col span={6}>
                            <div className='filter-box'>
                                <InputBox title={'Search by date'}>
                                    <RangePicker
                                        format={dateFormat}
                                        value={[
                                            filters.startDate ? moment(filters.startDate, dateFormat) : null,
                                        filters.endDate ? moment(filters.endDate, dateFormat) : null
                                    ]}
                                    onChange={(dates) => {
                                        updateFilter({
                                            startDate: dates ? dates[0] : null,
                                            endDate: dates ? dates[1] : null,
                                        });
                                    }}
                                        ranges={dateFilterRange}
                                    />
                                </InputBox>
                            </div>
                        </Col>
                    </Row>
                </>
            );
        }
    ;

    const getColumns = () => {
        let newCol = _.filter(columns, (item) => {
            return !item.hidden
        })
        return newCol
    }
    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>All Transactions (Total : {total || 0})</h5>

                        </div>
                        <div className='card-body table-responsive'>
                            <FilterComponent/>

                            <TableComp
                                columns={getColumns()}
                                apiRequest={apiRequest}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <AddBasicDetails
                visible={isModalVisible}
                onClose={closeModal}

            />


        </>


    );
};

export default TxnList;
