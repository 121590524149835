import React from 'react'
import { useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'
import { apiUrl2 } from '../../settings'
import { useRef } from 'react'
import { Button, Col, Drawer, Form, Icon, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useEffect } from 'react'
import moment from 'moment'


import { push } from 'connected-react-router'
import { sopListFxn } from './template/action/template'
import { displayDate } from '../../components/_utils/appUtils'
// let initalState = ({
//     topBanner: {},
//     registrationBanner: {},
//     chiefGuestProfile:{}
// })

let initalState = {
  topBanner: '',
  registrationBanner: '',
  chiefGuestProfile: ''
}

const SopList = (props) => {
const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await sopListFxn({
        ...params,
        sortField: '_id',
        sortOrder: 'descend',
        regExFilters: [
          "country",
          "university",
          "course",
          "name",
          "gender",
          "maritalStatus",
          "address",
          "mobile",
          "email",
          "examType",
        ],
      });
      console.log(data, "asdasda")
      resolve(data);
    });
  };
  const handleClick = (record) => {
    window.open(`${apiUrl2}/unizView/${record._id}`, '_blank');
  };

  const columns = [
    {
      title: "Sr. no",
      key: "index",
      dataIndex: "index",
      width: 50,
      render: (item, record, index) => {
        return index + 1;
      }
    },
    {
      title: "University",
      dataIndex: "universityId",
      key: "universityId",
      width: "200",
      render: (item, record) => {
        return (
          <>
            <p>{item && item.universityName ? item.universityName : null}</p>

            {/* <div>
                            {record._id}
                        </div>*/}

          </>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "countryId",
      key: "countryId",
      render: (item, record) => {
        return <p>{item && item.countryName ? item.countryName : null}</p>;
      },
    },
    {
      title: "Course",
      dataIndex: "courseId",
      key: "courseId",
      render: (item, record) => {
        return <p>{item && item.courseName ? item.courseName : null}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "studentId.name",
      key: "sopStudentId.name",
      render: (item, record) => {
        return <p>{record && record.sopStudentId && record.sopStudentId.name}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "studentId.email",
      key: "sopStudentId.email",
      render: (item, record) => {
        return <p>{record && record.sopStudentId && record.sopStudentId.email}</p>;
      },
    },
    {
      title: "Mobile",
      dataIndex: "studentId.mobile",
      key: "sopStudentId.mobile",
      render: (item, record) => {
        return <p>{record && record.sopStudentId && record.sopStudentId.mobile}</p>;
      },
    },
    {
      title: "Address",
      dataIndex: "studentId.address",
      key: "sopStudentId.address",
      render: (item, record) => {
        return <p>{record && record.sopStudentId && record.sopStudentId.address}</p>;
      },
    },
    {
      title: "Added Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => {
        return (
          displayDate(item)
        )
      }
    },

    {
      title: "Template",
      dataIndex: "templateId",
      key: "templateId",
      render: (item) => {
        return <p>
          {item && item.templateName ? item.templateName : ""}
        </p>;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (val, record) => {
        let {generatedSop, generatedSopDoc} = record;
        return (
          <div className={'btn_group'}>

            {generatedSopDoc && generatedSopDoc.path ?
              <>
                <Tooltip title={'Download SOP'}>
                  <a className={'empty_btn mr-2 mt-1'}
                     href={generatedSopDoc.path} target={'_blank'}
                     download={generatedSopDoc.fileName}>
                    <i className={'fa fa-download far'} style={{color:'black'}}></i>
                  </a>
                </Tooltip>
              </> : null}

            <button
              type="button"
              onClick={() => {handleClick(record)}}
              style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                padding: 0,
                margin: 0
              }}
            >
              <i className={'fa fa-eye far'} ></i>
            </button>
            <Tooltip title={'Re-generate sop'}>
              <button className='btn ml-1' onClick={() => {
                dispatch(push(`/generate-sop?studentId=${record.sopStudentId.studentId}`));
              }}>
                <img src={'../dist/img/reload.png'} />
              </button>
            </Tooltip>{' '}

          </div>
        );
      },
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Sop List: </h5>


            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}




              />
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Form.create()(SopList)
