import {default as React, useEffect, useState, useRef} from "react";
import {useLocation,  useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
  Button,
  Card,
  Drawer,
  Form,
  notification,
  HyperLink, Popconfirm, Modal, Icon, Row, Col
} from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import templateCategory from './templateCategory'
import {
  AddTemplateSectionFxn,
  getSingleTemplateFxn,
  getSingleTemplatetFxn,
  getTemplateCategoryListFxn,
  getTemplatetCategoryListFxn, RemoveSectionFxn
} from './action/template'
import { apiUrl } from '../../../settings'
import TemplateCategory from './templateCategory'
import renderHTML from 'react-render-html'
import TemplateSectionEdit from './components/templateSectionEdit'
import FileInput from '../../../components/_utils/fileInput'
import { push } from 'connected-react-router'
// import TemplateSectionEdit from './component/templateSectionEdit'


let initState = {
  categorySection: {},
  visible: false,
};
let {confirm} = Modal

const TemplateView = (props) => {

  let {countryId} = props;
  let location = useLocation();
  const url = window.location.href;
  const searchParams = new URLSearchParams(url.split("?")[1]);
  const templateId = searchParams.get("id");

  const {data} = location.state || {};
  const {getFieldDecorator} = props.form;
  const [template, setTemplate] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenCategory, setIsDrawerOpenCategory] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  let [sectionData, setSectionData] = useState(initState);

  const dispatch = useDispatch();
  let tableRef = useRef();
  useEffect(() => {
    getSingleTemplate(templateId);
    getCategoryList()
  }, [templateId]);
console.log(templateId, "templateId")
  const getCategoryList = async () => {
    let params = {
      results: 100,
      countryId: countryId,
    }
    console.log(template, "sadfghjkl")
    let x = await getTemplateCategoryListFxn((params));
    console.log(x.data, 'llllllllllllllllllllllll')
    setCategoryList(x.data);
  };

  const getSingleTemplate = async (templateId) => {
    if (templateId) {
      let {data} = await getSingleTemplateFxn(templateId);
      console.log(data,'dataaaaaaaaaaaaaaaaa')
      if (!data.error) {
        setTemplate(data);
      }
    }
  };


  let inputTypes = {
    fields: [
      {
        key: "categoryId",
        label: "Category Name",
        span: 'col-md-12',
        required: true,
        placeholder: "Category Name",
        type: "select",
        options: categoryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.categoryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            categoryId: x,
          });
        },
      },
      {
        key: "title",
        label: "Section Title",
        // required: true,
        span: 12,
        placeholder: "Section Title",
        type: "text",
      },

      {
        key: "content",
        label: "Section Content",
        type: 'ckeditor',
        span: 12,
        required: true,
        onChange: (x) => {
          props.form.setFieldsValue({
            content: x,
          });
        },
      },
      // {
      //     key: "content",
      //     label: "Section Content",
      //     type: "styleEditor",
      //     required: true,
      //     onChange: (x) => {
      //         props.form.setFieldsValue({
      //             content: x,
      //         });
      //     },
      // },
    ],
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  // 2nd drawer
  let inputTypesCategory = {
    fields: [
      {
        key: "categoryName",
        label: "Category Name",
        required: true,
        placeholder: "Category Name",
        type: "text",
      },
    ],
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleDrawerOpenCategory = () => {
    setIsDrawerOpenCategory(true);
  };

  const handleDrawerCloseCategory = () => {
    setIsDrawerOpenCategory(false);
    getCategoryList()
  };

  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.universityId =
          template && template.universityId && template.universityId._id
            ? template.universityId._id
            : null;
        valData.countryId =
          template && template.countryId && template.countryId._id
            ? template.countryId._id
            : null;
        valData.courseId =
          template && template.courseId && template.courseId._id
            ? template.courseId._id
            : null;
        valData.templateId = template && template._id ? template._id : null;
        let x = await AddTemplateSectionFxn(valData);

        if (!x.error) {
          notification.success({
            message: x.message,
          });
          getSingleTemplate(templateId);
          handleDrawerClose();
          props.form.setFieldsValue({
            categoryId: "",
            title: "",
            content: "",
          });
        }
      }
    });
  };
  const events = {
    showEditDrawer: (record) => {
      setSectionData({
        categorySection: record,
        visible: true,
      });
    },
    hideEditDrawer: async () => {
      setSectionData({
        categorySection: {},
        visible: false,
      });
     await getSingleTemplate(templateId)
    },
  };
  const deleteSection = async (sectionId) => {
    let resp = await dispatch(RemoveSectionFxn({sectionId, templateId}));
    getSingleTemplate(templateId)
  }
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <Card>
        <div className="justify-content-center">
          <div className="detail-box">
            {/*<div className="flex-box mb-3">*/}
            {/*  <h3 className="m-0">Template : {template && template.templateName*/}
            {/*    ? template.templateName*/}
            {/*    : null}</h3>*/}
            {/*  */}
            {/*</div>*/}

            <div className="row">
              <div className="col-md-8" >
                <h4><b>Template :</b> {template && template.templateName}</h4>
              </div>
              <div className="col-md-4" style={{textAlign:'right', marginTop:-10}}>
                <a type={"primary"}  onClick={() => {

                  dispatch(push(`/edit-template?_id=${templateId}`))}}><Icon type={'edit'} /></a>

              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 mb-1">
                <span className="left"><b>Country: {'  '}</b></span>
                <span className="right">
      {template && template.countryId && template.countryId.countryName
        ? template.countryId.countryName
        : null}
    </span>
              </div>
              <div className="col-md-6  mb-1">
                <span className="left"><b>University: {'  '}</b></span>
                <span className="right">
       {template &&
       template.universityId &&
       template.universityId.universityName
         ? template.universityId.universityName
         : null}
    </span>
              </div>


              <div className="col-md-12  mb-1">
                <span className="left"><b>Course: {'  '}</b></span>
                <span className="right">
     {template &&
     template.courseId &&
     template.courseId.courseName
       ? template.courseId.courseName
       : null}
    </span>
              </div>
              <div className="col-md-6  mb-1">
                <span className="left"><b>Header Design:</b> {'  '}</span>
                <span className="right">
    {template && template.headerDesign
      ? renderHTML(template.headerDesign)
      : null}
    </span>
              </div>
              <div className="col-md-6  mb-1">
                <span className="left"><b>Footer Design: {'  '}</b></span>
                <span className="right">
    {template && template.footerDesign
      ? renderHTML(template.footerDesign)
      : null}
    </span>
              </div>
            </div>
          </div>

        </div>
          </Card>
          <Button className="mt-3" type={"primary"}  style={{float:'right'}} onClick={handleDrawerOpen}>Add Section
          </Button>
        </div>
      </div>
      <br/>
      {template && template.sections && template.sections.length
        ? template.sections.map((item, i) => (
          <Card className="mb-4"
            key={i}

            // title={`${item && item.sectionId && item.sectionId.title} (${item.categoryId.categoryName})`}



          >
            <div className="row">
              <div className="col-md-8" >
                <p><b>{`${item && item.sectionId && item.sectionId.title} (${item.categoryId.categoryName})`}</b></p>
              </div>
              <div className="col-md-4" style={{textAlign:'right', marginTop:-10}}>
                <a type={"primary"} onClick={() => {events.showEditDrawer(item.sectionId)}}> <Icon type={'edit'} /></a>
                <Popconfirm title={'Are your sure, you want to delete this section?'}
                            onConfirm={() =>  deleteSection(item.sectionId)}
                            okText='Yes' cancelText='No'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}>
                    <Icon type={'delete'} />
                  </button>
                </Popconfirm>
              </div>
            </div>

            <p className={'text-dark'}>
              {item.sectionId && item.sectionId.content
                ? renderHTML(item.sectionId.content)
                : null}
            </p>

          </Card>

        ))
        : null}
      {/*{sectionData.visible ? (*/}
      {/*  <TemplateSectionEdit*/}
      {/*    visible={sectionData.visible}*/}
      {/*    onClose={events.hideEditDrawer}*/}
      {/*    sectionData={sectionData.categorySection}*/}
      {/*  />*/}
      {/*) : null}*/}
      {isDrawerOpenCategory ? (
        <TemplateCategory
          visible={isDrawerOpenCategory}
          onClose={handleDrawerCloseCategory}
          countryId={template.countryId}
          getCategoryList={() => {
            getCategoryList();
          }}
        />
      ) : null}

      {sectionData.visible ? (
        <TemplateSectionEdit
          visible={sectionData.visible}
          onClose={events.hideEditDrawer}
          sectionData={sectionData.categorySection}
        />
      ) : null}
      <Drawer
        placement="right"
        onClose={handleDrawerClose}
        visible={isDrawerOpen}

        width={"50%"}
        title={"Add Template-Section"}
      >
        <Form onSubmit={handleSubmit}>
          <div className='card unizportal'>
            <div className="row">
              {inputTypes.fields.map((item, key) => {
                const colClass = item.type === 'select' ? 'col-md-12' : 'col-md-12';

                return (
                  <div className={colClass} key={key}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ flexGrow: 1 }}>
                        <GetEachFormFields
                          {...props.form}
                          item={item}
                          formItemLayout={formItemLayout}
                        />
                      </div>
                      {item.type === 'select' && (
                        <div className="col-md-1" style={{marginTop:37}}>
                        <a
                          className={'linkBtn'}
                          onClick={handleDrawerOpenCategory}
                          style={{

                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >


                          <Icon type={'plus'} />
                        </a>
                        </div>
                      )}
                    </div>
                    {/*{key === 0 && (*/}
                    {/*  <Button*/}
                    {/*    type={"primary"}*/}

                    {/*    size="small"*/}
                    {/*    style={{ marginTop: "6px", padding: "4px 8px" }}*/}
                    {/*  >*/}
                    {/*    Add Category*/}
                    {/*  </Button>*/}
                    {/*)}*/}
                  </div>
                );
              })}
              <Form.Item>
                <Button type='primary' htmlType='submit' className={'btn'}>
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>

      </Drawer>
    </>
  );
};

export default Form.create()(TemplateView);
