import moment from "moment";
import { default as React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyleEditor from "react-style-editor";
import {
  Button,
  Card, Col,
  Form, Icon, Input,
  notification, Row, Table, Tooltip
} from 'antd'
// import {courseCategoryList } from "../../Masters/courseCategory/action";
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllUniversities } from '../../university/actions/university'
import { courseCategoryList, listAllCourse } from '../../course/actions/courseAction'
import { InputBox } from '../../../components/_utils/appUtils'
import { getSingleTemplateFxn, updateTemplateFxn } from './action/template'


const StyleComponent = (props) => {
  let { title, onChange, value } = props;
  return (
    <div className={"mt-3"}>
      <label style={{marginBottom: 5, marginLeft: 10}}>{title}</label>
      <StyleEditor
        style={{backgroundColor: "white", minHeight: 200}}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </div>
  );
};

const EditTemplate = (props) => {
  let dispatch = useDispatch();
  const { getFieldDecorator } = props.form;
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  let [editorKey, setEditorKey] = useState(moment());
  let [headerStyle, setHeaderStyle] = useState("");
  let [bodyStyle, setBodyStyle] = useState("");
  let [borderStyle, setBorderStyle] = useState("");
  let [footerStyle, setFooterStyle] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [originalDocument, setOriginalDocument] = useState({});
  const [oldOriginalDocument, setOldOriginalDocument] = useState({});


  const chooseOriginalTemplate = (e) => {
    const file = e.target.files[0];
    setOriginalDocument(file);
  };

  console.log(footerStyle, "footerStyle")
  const loadCountryFxn = async () => {
    let params ={
      results:100,
      count:100,
      active:[true,false]
    }
    let x = await dispatch(listAllCountries(params));
    setCountryList(x.data);
  };


  useEffect(() => {
    loadCountryFxn();
    handleCourseCategory();
    getSingleTemplate()
  }, []);
  const apiRequest = (id) => {
    return new Promise(async (resolve) => {
      let params ={
        results:100,
        count:100,
      }
      if (id) {
        params.universityCountry = id
      }
      params.showUniversity = [true, false]
      let respData = await dispatch(listAllUniversities({
        ...params,

        regExFilters: ['universityType', 'universityName']
      }))
      setUniversityList(respData.data)
      resolve(respData)

    })
  }
  const getRelatedCourse = async (id) => {
    return new Promise(async (resolve) => {
      let params ={
        results:100,
        count:100,

      }
      if (id) {
        params.courseUniversity = id
      }

      let respData = await dispatch(listAllCourse({
        ...params,
        regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
      }))
      console.log(respData, "qqqqqqqqqqqqq")
      setCourseList(respData.data)


    })
  };

  const handleCourseCategory = async () => {
    let params ={
      results:100,
      count:100,
      active:[true,false]
    }
    let x = await courseCategoryList(params);
    setCategoryList(x.data.data);
  };
  console.log(categoryList, "cccccccccccc")
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let fd = new FormData();
        valData.templateId = templateId;
        valData.headerStyle = headerStyle;
        valData.borderStyle = borderStyle;
        valData.bodyStyle = bodyStyle;
        valData.footerStyle = footerStyle;
        fd.append("obj", JSON.stringify(valData));

        if (originalDocument && originalDocument.name) {
          fd.append("originalDocument", originalDocument);
        }
        let x = await dispatch(updateTemplateFxn(fd));
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  //
  const getSingleTemplate = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let templateId = searchParams.get("_id");
    if (templateId) {
      let { data } = await getSingleTemplateFxn(templateId);
      console.log(data, "EWrtyuiytreww")
      if (!data.error) {
        setTemplateId(templateId);
        if(data.countryId && data.countryId._id ){
          loadCountryFxn(data.countryId._id);
        }
        if(data.universityId && data.universityId._id){
          getRelatedCourse(data.universityId._id);
        }
        if(data.countryId && data.countryId._id){
          apiRequest(data.countryId._id);
        }
      console.log(data.headerDesign, "data.universityId")
        setTimeout(() => {
          props.form.setFieldsValue({
            countryId: data.countryId && data.countryId._id ? data.countryId._id : null,
            universityId: data.universityId && data.universityId.universityName ? data.universityId._id : null,
            courseId: data.courseId && data.courseId._id ? data.courseId._id : null,
            categoryId: data.categoryId,
            templateName: data.templateName,
            headerDesign: extractTextFromHTML(data.headerDesign),
            footerDesign: extractTextFromHTML(data.footerDesign),
            fontsLink: data.fontsLink,
          });
          setHeaderStyle(data.headerStyle);
          setBorderStyle(data.borderStyle);
          setBodyStyle(data.bodyStyle);
          setFooterStyle(data.footerStyle);
          console.log(data.footerStyle, "data.originalDocument")

            setOldOriginalDocument(data.originalDocument);

          setEditorKey(moment());
        }, 500);
      }
    }
  };
  console.log(oldOriginalDocument, "oldOriginalDocument.name")
  const extractTextFromHTML = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  let inputTypes = {
    fields: [
      {
        key: "countryId",
        label: "Country",
        required: true,
        placeholder: "Country",
        type: "select",
        span: 12,
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryId: x,
          });
          apiRequest(x);
        },
      },

      {
        key: "universityId",
        label: "University",
        required: true,
        placeholder: "University",
        type: "select",
        span: 12,
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.universityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityId: x,
          });
          getRelatedCourse(x);
        },
      },

      {
        key: "courseId",
        label: "Course",
        required: true,
        placeholder: "Course",
        type: "select",
        span: 12,
        options: courseList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.courseName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            courseId: x,
          });
        },
      },

      {
        key: "templateName",
        label: "Template Name ",
        required: true,
        span: 12,
        placeholder: "Template Name",
        type: "text",

      },
      {
        key: "headerDesign",
        label: "Header Design",
        editorKey: editorKey,
        span: 12,
        type: "ckeditor",
        onChange: (x) => {
          props.form.setFieldsValue({
            headerDesign: x,
          });
        },
      },
      {
        key: "footerDesign",
        editorKey: editorKey,
        label: "Footer Design",
        span: 12,
        type: "ckeditor",
        onChange: (x) => {
          props.form.setFieldsValue({
            footerDesign: x,
          });
        },
      },

      // {
      //   key: "originalDocument",
      //   label: "Original Document",
      //   placeholder: "Original Document",
      //   type: "file",
      //   span: 12,
      //   fileName: originalDocument,
      //   urlPath:
      //     oldOriginalDocument && oldOriginalDocument.path
      //       ? oldOriginalDocument.path
      //       : "",
      //   onChange: chooseOriginalTemplate,
      // },
      {
        key: 'originalDocument',
        span: 12,
        customField: (
          <InputBox title={'Original Document'} className={'logoCls'}>
            <Input
              type={'file'}
              className={'form-control mt-2'}

              name={'originalDocument'}
              id={'logo'}
              onChangeCapture={chooseOriginalTemplate}

            />
            {oldOriginalDocument && oldOriginalDocument.url ?
              <Tooltip title={oldOriginalDocument.name} className="mt-2"> <a className={'linkBtn'} target={'_blank'}
                                                href={oldOriginalDocument.url}>
                <Icon type={'eye'}></Icon>
              </a> </Tooltip> : ''}
          </InputBox>
        )
      },
      {
        key: "categoryId",
        label: "Category",
        // required: true,
        placeholder: "Category",
        type: "select",
        options: categoryList,
        span: 12,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            categoryId: x,
          });
        },

      },
      {
        key: "fontsLink",
        // required: true,

        label: "Fonts Link",
        placeholder: "Fonts Link",
        type: "textarea",
        span: 24,
      },
    ],
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={handleSubmit}>
            <div className='form-box mt-4'>

              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Edit Template</h5>
              </div>

              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox1 ? { paddingBottom: 1 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox1}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}
                  <div className={"row"}>
                    <div className={"col-md-12"}>
                      <StyleComponent
                        title={"Header Style"}
                        value={headerStyle}
                        onChange={(e) => {
                          setHeaderStyle(e);
                        }}
                      />
                    </div>
                    <div className={"col-md-12"}>
                      <StyleComponent
                        title={"Border Style"}
                        value={borderStyle}
                        onChange={(e) => {
                          setBorderStyle(e);
                        }}
                      />
                    </div>
                    <div className={"col-md-12"}>
                      <StyleComponent
                        title={"Body Style"}
                        value={bodyStyle}
                        onChange={(e) => {
                          setBodyStyle(e);
                        }}
                      />
                    </div>
                    <div className={"col-md-12"}>
                      <StyleComponent
                        title={"Footer Style"}
                        value={footerStyle}
                        onChange={(e) => {
                          setFooterStyle(e);
                        }}
                      />
                    </div>
                  </div>
                  {/* {agents && agents.length ? <Col span={24} style={{ marginBottom: 20 }}>
                    <Card title={'Agents'} size={'small'}>
                      <div className={'guestEmailBox'}>
                        <ul>
                          {agents.map((item, index) => {
                            return (
                              <li className={'emailTag'} key={index}>
                                {item.name} ({item.companyName})
                                <Icon type={'close'}
                                      onClick={() => this.agentEvents.removeAgent(index)}
                                      className={'emailIcon'} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Card>
                  </Col> : null}*/}
                </Row>



                <Form.Item>
                  <Button type='primary' htmlType='submit'  className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>

        </div>
      </div>
      {/*<Card title={"Edit Template"}>*/}
      {/*  <Form >*/}


      {/*    <div className={"row"}>*/}
      {/*      <div className={"col-md-12"}>*/}
      {/*        <StyleComponent*/}
      {/*          title={"Header Style"}*/}
      {/*          value={headerStyle}*/}
      {/*          onChange={(e) => {*/}
      {/*            setHeaderStyle(e);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className={"col-md-12"}>*/}
      {/*        <StyleComponent*/}
      {/*          title={"Border Style"}*/}
      {/*          value={borderStyle}*/}
      {/*          onChange={(e) => {*/}
      {/*            setBorderStyle(e);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className={"col-md-12"}>*/}
      {/*        <StyleComponent*/}
      {/*          title={"Body Style"}*/}
      {/*          value={bodyStyle}*/}
      {/*          onChange={(e) => {*/}
      {/*            setBodyStyle(e);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className={"col-md-12"}>*/}
      {/*        <StyleComponent*/}
      {/*          title={"Footer Style"}*/}
      {/*          value={footerStyle}*/}
      {/*          onChange={(e) => {*/}
      {/*            setFooterStyle(e);*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div style={{ marginTop: "20px" }}>*/}
      {/*      <Button type={"primary"} htmlType="submit">*/}
      {/*        submit*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </Form>*/}
      {/*</Card>*/}
    </>
  );
};

export default Form.create()(EditTemplate);
