import React, {useEffect, useRef, useState} from "react"
import {Card, Row, Col, Form, notification, Tooltip, Icon, Modal, Input, Button} from "antd";
import {useDispatch} from "react-redux";
import {
    createAgentFxn,
    getFinalChangesInInrFxn,
    reeudoRatesFxn,
    reeudoSourceFxn,
    createQuotationFxn,
    createAgentTestFxn,
    allQuotationsFxn, allTransactionFxn,
    singleAgentMarginFxn, getReferenceUsersFxn
} from "../actions";
import {debounce} from 'lodash';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import "./styles.less"
import {DefaultTablePagination, displayDate, displayTime, fixed2Digit} from "../../../components/_utils/appUtils";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import _ from "lodash"
import {listAllContract} from "../../contract/actions/contract";
import {TableComp} from "sz-react-utils-lite";
import {directUniLetterRights} from "../../WebComponent/allowComponentRightsWise";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";
import NewTransaction from "./transactionDrawer";
import AddTxnStudent from "./addTxnStudent"
import {getSingleUser} from "../../users/actions/user"
import './styles.css'
import TransactionDetailsModal from "./transactionDetailsModal";
import ReeudoTimer from "../component/reeudoTimer";

const rupeeSymbol = "₹"
const options = {
    autoplay: false,
    loop: false,
    nav: true,
    dots: false,
    // dotClass: 'owl-dot dotNone',
    margin: 15,
    autoplayTimeout: 2000,
    infinite: false,
    speed: 1500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    responsive: {
        0: {
            items: 6,
            nav: true
        },
        600: {
            items: 6,
            nav: false
        },
        1000: {
            items: 6,
            nav: true,
            loop: false
        }
    }
    // autoWidth:true,

}

const RenderRow = (props) => {
    let {value, label, span} = props;
    return (
        <Col span={span}>
            {label}
            <h5 className={'font-weight-600'}>{value}</h5>
        </Col>
    )
}

const ReedosComponent = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}} = props
    let dispatch = useDispatch()
    let [reeudoData, setReeudoData] = useState({})
    let [currencyList, setCurrencyList] = useState([])
    let [sourceList, setSourceList] = useState([])
    let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({})
    let [finalChangesState, setFinalChangesState] = useState({})
    let [referenceUserList, setReferenceUserList] = useState({})
    const [studentModalVisible, setStudentModalVisible] = useState(false);
    let [total, setTotal] = useState(0)
    const [agentData, setAgentData] = useState({});
    let [transactionDrawer, setTransactionDrawer] = useState({
        visible: false,
        id: ''
    })
    let [transactionModal, setTransactionModal] = useState({
        visible: false,
        id: ''
    })
    const [agentMargin, setAgentMargin] = useState(0);

    const [isModalVisible, setModalVisible] = useState(false);
    const tableRef = useRef();
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const events = {
        getFlag: (item) => {
            let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
            return url
        },
        loadRates: async (latestAgentMargin = 0) => {
            let {data, success} = await dispatch(reeudoRatesFxn());
            console.log(data, "dtatat---------a");
            if (success) {
                let dataArr = [];
                let {currencyData, currencyOrder} = data;

                _.each(currencyOrder, (item) => {
                    let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
                    let resp = currencyData && currencyData[item] ? currencyData[item] : null;

                    const updatedRate = (parseFloat(resp.yourRate) + parseFloat(latestAgentMargin)).toFixed(2);

                    let obj = {
                        currency: item,
                        ...resp,
                        imgPath: url,
                        yourRate: updatedRate
                    };
                    dataArr.push(obj);
                });
                console.log(dataArr, "dattata");
                setCurrencyList(dataArr);
                setReeudoData(data);
            }
        },
        loadSource: async () => {
            let {data, success} = await dispatch(reeudoSourceFxn())
            if (success) {
                setSourceList(data);
            }
        },
        setDefaultParams: () => {
            let currency = getFieldValue('currency');
            if (!currency && currencyList.length) {
                const defaultCurrency = currencyList[0];
                setTimeout(() => {
                    setFieldsValue({
                        amount: 100,
                        currency: defaultCurrency.currency,
                        customerRate: ((parseFloat(defaultCurrency.yourRate) || 0)).toFixed(2),
                    });
                    setTimeout(() => {
                        events.getFinalChangesFxn()
                    }, 100)
                }, 100);
                setSelectedCurrencyObj(defaultCurrency);
            }
        },
        setDefaultSource: () => {
            setTimeout(() => {
                setFieldsValue({
                    sourceId: sourceList && sourceList.length ? sourceList[0].id : "",
                })
            }, 100)
        },
        chooseCurrency: (currency) => {
            let findDoc = _.find(currencyList, (item) => item.currency == currency);
            if (findDoc) {
                setSelectedCurrencyObj(findDoc);
                setTimeout(() => {
                    setFieldsValue({
                        customerRate: findDoc
                            ? ((parseFloat(findDoc.yourRate) || 0)).toFixed(2)
                            : "",
                    });
                }, 100);
            }
        },
        getFinalChangesFxn: async () => {
            let obj = {
                currency: getFieldValue('currency'),
                amount: getFieldValue('amount'),
                customerRate: getFieldValue('customerRate'),
                sourceId: getFieldValue('sourceId'),
            }
            if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(obj))
                if (success && data && data.charges) {
                    setFinalChangesState(data.charges)
                }
            }
        },

        // createAgent: async () => {
        //     let obj = {
        //         email: user.email,
        //         fname: user.name,
        //         lname: "",
        //         phone: user.mobile,
        //         grpIds: [
        //             "fd8b4f5d-27dc-4e84-b7a7-01ef5bdece9c",
        //             "f79df9e8-a80d-4c9b-bb10-a6368b22f58b",
        //             "ed24eb7a-53ef-4fbf-ad3c-7e21fe58e425",
        //             "9b2f733d-1454-4863-a719-56a1116f2cca",
        //             "51f3e22f-b7d9-4a53-a258-a86694890fdc",
        //             "633f0c5c-1f42-4929-8df1-cdfd8f9b8487",
        //             "b768a411-bf4c-4ba9-b28f-66ba9cdc422e"
        //         ]
        //     }
        //     let {data, success} = await dispatch(createAgentFxn(obj))
        //     console.log(data, "datat")
        //     if (data) {
        //         notification.success({message: "Agent created successfully"});
        //     } else {
        //         notification.error({message: "Error creating agent"});
        //     }
        // },
        singleAgent: async () => {
            let data = await dispatch(singleAgentMarginFxn({userId: user._id}));
            setAgentMargin(data.agentMargin);
            await events.loadRates(data.agentMargin);
        },
        createQuotation: async (formData) => {
            const finalChanges = finalChangesState || {};
            console.log(finalChanges, "sss");

            const obj = {
                userId: "",
                amount: finalChanges.fromCurrencyAmount || 0,
                bankFees: finalChanges.bank || 0,
                yourRate: finalChanges.yourRate || null,
                ibrRate: finalChanges.ibrRate || null,
                currentRate: finalChanges.fxRate || null,
                fxRate: finalChanges.fxRate || null,
                emailId: formData ? formData.emailAddress : "",
                pan: null,
                fromCurrency: finalChanges.fromCurrency || null,
                grossAmount: finalChanges.base || 0,
                gst: finalChanges.gst || 0,
                name: formData ? formData.studentName : "",
                nostroCharge: finalChanges.nostro || 0,
                tcsCharge: finalChanges.tcs || 0,
                phoneNo: formData ? formData.phoneNumber : "",
                toCurrency: finalChanges.toCurrency || null,
                totalAmount: finalChanges.total || 0,
                consultantName: null,
                consultantId: null,
                sourceId: 1,
                agentId: user._id,
            };
            if (formData && formData.referencedBy && formData.referencedBy !== "NA") {
                obj.referencedBy = formData.referencedBy;
            }

            let resp = await dispatch(createQuotationFxn(obj));
            console.log(resp, "resss")
            if (resp.success) {
                closeModal()
                tableRef.current.reload()
                notification.success({message: 'Quotation created successfully'});
                console.log('----------------dmo', resp);
                setTransactionModal({
                    visible: true,
                    id: resp.quotationData._id
                })
                // const pdfPath = resp.quotationData.quotationPdf.path;
                // window.open(pdfPath, '_blank');
                // tableRef.current.reload()
            }
        },
        apiRequest: () => {
            let params = {
                results: 100,
                count: 100
            }
            return new Promise(async (resolve) => {
                let resp = await dispatch(allQuotationsFxn(params))
                if (resp && resp.data) {
                    let sortedData = resp.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setTotal(resp.total)
                    console.log(resp, "resppp")
                    resolve({data: sortedData})
                } else {
                    resolve(resp)
                }
                // setTotal(resp.total)
                // resolve(resp);
            });
        },
        loadReferUsers: async () => {
            let {data} = await getReferenceUsersFxn();
            let finArr = [{_id: "NA", name: "NA"}]
            if (data && data.length) {
                finArr = [...finArr, ...data]
            }
            setReferenceUserList(finArr);
        }
    }

    const debouncedGetFinalChangesFxn = debounce(events.getFinalChangesFxn, 300);
    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };


    useEffect(() => {
        window.scrollTo(0, 0)

        events.singleAgent()
        // events.loadRates()
        events.loadSource()
        events.loadReferUsers()
    }, [])
    useEffect(() => {
        events.setDefaultParams()
    }, [currencyList])

    useEffect(() => {
        events.setDefaultSource()
    }, [sourceList])

    useEffect(() => {
        debouncedGetFinalChangesFxn();
    }, [getFieldValue('currency'), getFieldValue('amount'), getFieldValue('customerRate'), getFieldValue('sourceId')]);


    let inputTypes = {
        fields: [
            {
                key: 'currency',
                label: 'Currency',
                type: 'select',
                required: true,
                options: currencyList,
                keyAccessor: x => x.currencyCode,
                valueAccessor: (x) => {
                    return (
                        <>
                            <img src={x.imgPath} style={{height: 25}}/>
                            <span className={'ml20 font-weight-600'}>{x.currencyCode} - {x.currencyName}</span>
                        </>
                    )
                },
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        currency: x,
                    })
                }
            },
            {key: 'customerRate', label: 'Customer Rate', required: true},
            {key: 'amount', label: 'Amount', required: true},
            {
                key: 'sourceId',
                label: 'Source',
                type: 'select',
                required: true,
                options: sourceList,
                keyAccessor: x => x.id,
                valueAccessor: (x) => x.sourceOfFund,
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        sourceId: x,
                    })
                }
            },
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 70,
            render: (val, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (text) => <span>{displayDate(text, 'DD/MM/YYYY')}</span>,
        },

        {
            title: 'Agent Name',
            dataIndex: 'agentId.name',
            render: (text) => <span>{text}</span>,
        },
        // {
        //     title: 'Consultant',
        //     dataIndex: 'consultantName',
        //     render: (text) => <span>{text}</span>,
        // },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'Rate',
            dataIndex: 'currentRate',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'From Currency',
            dataIndex: 'fromCurrency',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Gross Amount',
            dataIndex: 'grossAmount',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'Action',
            dataIndex: 'quotationPdf',
            render: (text, record) => (
                <>
                    {record.quotationPdf && record.quotationPdf.path ? (
                        <Tooltip title={'Download Quotation'}>
                            <a download={record.quotationPdf.fileName}
                               className={'btn roundNew xs btn-default'}
                               href={record.quotationPdf.path}
                               target="_blank">
                                <Icon type={'download'}/><br/>
                                Download
                            </a>
                        </Tooltip>
                    ) : null}

                    <Tooltip title={' Get Updated Details - Quotation & Book Transaction'}>
                        <a className={'btn roundNew xs btn-default ml10'} onClick={() => {
                            setTransactionDrawer({
                                visible: true,
                                id: record._id
                            })
                        }}>
                            Get Updated Details<br/>
                            Quotation & Book Transaction
                        </a>
                    </Tooltip>
                </>
            ),
        }


    ];


    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>

                    <div className='card'>

                        <div className='table-head d-flex align-items-center all-student-search'>

                            <div className={'flexRow'}>
                                <div className={'mr50'}>
                                    IBR Rate<br/>
                                    <strong>{reeudoData.date ? displayDate(reeudoData.date, 'DD/MM/YYYY') : null}</strong>
                                </div>
                                <div className={'mr50'}>
                                    Last Updated<br/>
                                    <strong>{reeudoData.date ? displayTime(reeudoData.date, 'hh:mm:ss a') : null}</strong>
                                </div>
                                <ReeudoTimer callback={() => {

                                }}/>
                            </div>
                        </div>
                        <div className='card-body table-responsive'>
                            <div className={'currency-list reeudo-slider'}>
                                <OwlCarousel
                                    className="owl-theme "
                                    {...options}>
                                    {currencyList && currencyList.length ? currencyList.map((item) => {
                                        return (
                                            <Card className={'currency-card'}>
                                                <div className={'jcsb'}>
                                                    <div>
                                                        <div>
                                                            Live IBR / <span
                                                            className={'currency-name'}>{item.currency}</span>
                                                        </div>
                                                        <div className={'currency-value'}>
                                                            {item.rate}
                                                        </div>
                                                    </div>
                                                    <img src={events.getFlag(item.currency)}
                                                         style={{height: 25, width: "auto"}}/>
                                                </div>
                                                <div className={'your-rate'}>
                                                    Your Rate<br/>
                                                    <div className={'rate'}>
                                                        {item.yourRate}
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    }) : null}
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className='card-body table-responsive'>
                            <Row gutter={24}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <Col span={6} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        </Col>
                                    )
                                })}
                                {/*<Col span={4} className={'mt40'}>*/}
                                {/*    <a className={'btn roundNew btn-default btn-block lg'} onClick={() => {*/}
                                {/*        events.getFinalChangesFxn()*/}
                                {/*    }}><Icon type={'search'}/> Search</a>*/}
                                {/*</Col>*/}
                            </Row>
                            <Row className={'mt20'} gutter={15}>
                                <Col span={18}>
                                    <Card>
                                        <Row>
                                            <RenderRow label={'Currency Type'}
                                                       value={finalChangesState && finalChangesState.fromCurrency ? finalChangesState.fromCurrency : ""}
                                                       span={7}/>
                                            <RenderRow label={'Foreign Currency Amount'}
                                                       value={finalChangesState && finalChangesState.fromCurrencyAmount ? fixed2Digit(finalChangesState.fromCurrencyAmount) : ""}
                                                       span={6}/>
                                            <RenderRow label={'Rate'}
                                                       value={finalChangesState && finalChangesState.fxRate ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.fxRate)}` : ""}
                                                       span={5}/>
                                            <RenderRow label={'Gross Amount'}
                                                       value={finalChangesState && finalChangesState.base ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.base)}` : ""}
                                                       span={5}/>
                                        </Row>
                                        <Row className={'mt10'}>
                                            <RenderRow label={'Foreign Conversion GST Tax @18 %'}
                                                       value={finalChangesState && finalChangesState.gst ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.gst)}` : 0}
                                                       span={7}/>
                                            <RenderRow label={'Bank Processing Fee'}
                                                       value={finalChangesState && finalChangesState.bank ? `${rupeeSymbol} ${finalChangesState.bank}` : 0}
                                                       span={6}/>
                                            <RenderRow label={'Nostro Charge'}
                                                       value={finalChangesState && finalChangesState.nostro ? `${rupeeSymbol} ${finalChangesState.nostro}` : 0}
                                                       span={5}/>
                                            <RenderRow label={'TCS Charge*'}
                                                       value={finalChangesState && finalChangesState.tcs ? `${rupeeSymbol} ${finalChangesState.tcs}` : 0}
                                                       span={5}/>
                                        </Row>
                                        <div className={'mt5 gray-color'}>
                                            <small className={'mt1'}>*The Final TCS may change based on the historical
                                                transactions of the
                                                sender and the "Source of Fund"</small>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card>
                                        <div>
                                            Grand Total<br/>
                                            <h4 className={'font-weight-600 colorPrimaryDark'}>
                                                {rupeeSymbol} {finalChangesState && finalChangesState.total ? fixed2Digit(finalChangesState.total) : 0}
                                            </h4>
                                        </div>
                                        <a
                                            className={'btn btn-primary roundNew lg mt20 w-100'}
                                            onClick={() => {
                                                const customerRate = getFieldValue('customerRate');
                                                const yourRate = selectedCurrencyObj.yourRate;

                                                if (parseFloat(customerRate) < parseFloat(yourRate)) {
                                                    notification.warning({
                                                        message: 'Warning',
                                                        description: `Customer rate cannot be less than your rate (${yourRate}). Please adjust your rate.`,
                                                    });
                                                    return;
                                                }

                                                showModal();
                                            }}
                                        >
                                            Get Quote & Book Transaction
                                        </a>


                                    </Card>
                                    {/*<div>*/}
                                    {/*    <a className={'btn btn-primary roundNew lg mt10 w-100'}*/}
                                    {/*       onClick={() => {*/}
                                    {/*           setTransactionDrawer({*/}
                                    {/*               visible: true,*/}
                                    {/*               id: ''*/}
                                    {/*           })*/}
                                    {/*       }}>*/}
                                    {/*        Book Transaction*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-3'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>Recent Quotations:</h5>
                        </div>
                        <div className='card-body table-responsive'>

                            <TableComp
                                apiRequest={events.apiRequest}
                                columns={columns}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {transactionDrawer.visible ? (
                <NewTransaction
                    visible={transactionDrawer.visible}
                    quotationId={transactionDrawer.id}
                    onClose={() => {
                        setTransactionDrawer({
                            visible: false,
                            id: ''
                        })
                    }}
                    reeudoData={reeudoData}
                    currencyList={currencyList}
                    selectedCurrency={finalChangesState}
                />
            ) : null}
            {isModalVisible ?
                <AddTxnStudent
                    visible={isModalVisible}
                    onClose={closeModal}
                    referenceUserList={referenceUserList}
                    onSubmit={events.createQuotation}
                /> : null}

            {
                transactionModal.visible ? (
                    <TransactionDetailsModal
                        visible={transactionModal.visible}
                        quotationId={transactionModal.id}
                        onClose={() => {
                            setTransactionModal({
                                visible: false,
                                id: ''
                            })
                        }}
                    />
                ) : null
            }
        </>
    )
}

const WrapReedosComponent = Form.create()(ReedosComponent)

export default WrapReedosComponent
