import {apiUrl2} from '../../../settings'

export const reeudoRatesUrl = () => {
    return apiUrl2 + '/reeudo/rates'
}
export const reeudoSourceUrl = () => {
    return apiUrl2 + '/reeudo/source'
}
export const getFinalChangesInInrUrl = () => {
    return apiUrl2 + '/reeudo/final-changes-inr'
}
export const createAgentUrl = () => {
    return apiUrl2 + '/reeudo/create-agent'
}

export const createQuotationUrl = () => {
    return apiUrl2 + '/reeudo/create-quotation'
}
export const createAgentTestUrl = () => {
    return apiUrl2 + '/reeudo/create-agent-test'
}

export const singleAgentMarginUrl = () => {
    return apiUrl2 + '/reeudo/getAgentMargin'
}

export const updateAgentMarginUrl = () => {
    return apiUrl2 + '/reeudo/updateAgentMargin'
}

export const allQuotationUrl = () => {
    return apiUrl2 + '/reeudo/quotation'
}
export const singleQuotationUrl = () => {
    return apiUrl2 + '/reeudo/singleQuotation'
}
export const getTransactionUrl = () => {
    return apiUrl2 + '/reeudo/getTransactions'
}
export const transactionUrl = () => {
    return apiUrl2 + '/reeudo/transaction'
}

export const getMetaInfoUrl = () => {
    return apiUrl2 + '/meta-information'
}

export const updateStudentUrl = () => {
    return apiUrl2 + '/reeudo/update-student'
}

export const updateStudentDocUrl = () => {
    return apiUrl2 + '/reeudo/update-docs'
}

export const downloadStudentDocUrl = () => {
    return apiUrl2 + '/download-docs'
}

export const createSenderUrl = () => {
    return apiUrl2 + '/sender-details'
}

export const updateSenderDocUrl = () => {
    return apiUrl2 + '/update-sender-docs'
}

export const downloadSenderDocUrl = () => {
    return apiUrl2 + '/download-sender-docs'
}

export const getDocumentsByIdUrl = () => {
    return apiUrl2 + '/reeudo/get-docs'
}
export const createPaymentUrl = () => {
    return apiUrl2 + '/create-payment'
}

export const createBeneficiaryUrl = () => {
    return apiUrl2 + '/create-beneficiary'
}

export const paymentInstructionUrl = () => {
    return apiUrl2 + '/get-payment-instruction'
}


export const getFinalPaymentChangesUrl = () => {
    return apiUrl2 + '/get-final-charges'
}

export const updateStudentDataUrl = () => {
    return apiUrl2 + '/reeudo/update-student-data'
}

export const updateKycStatusUrl = () => {
    return apiUrl2 + '/update-kyc-status'
}

export const getA2FormUrl = () => {
    return apiUrl2 + '/get-a2-form'
}

export const uploadA2FormUrl = () => {
    return apiUrl2 + '/upload-signed-a2-form'
}

export const updateSenderUrl = () => {
    return apiUrl2 + '/update-sender-details'
}

export const getSenderDocsUrl = () => {
    return apiUrl2 + '/get-sender-docs'
}
export const getReferenceUsersUrl = () => {
    return apiUrl2 + '/reeudo/get-reference'
}
