import {apiUrl,apiUrl2} from '../../../settings'

export const addStudentUrl = () => {
    return apiUrl + '/web/student'
}

export const listStudentUrl = () => {
    return apiUrl + '/student'
}

// export const singleStudentUrl = id => {
//     return apiUrl + '/student/' + id
// }
export const singleStudentUrl = id => {
    return apiUrl2 + '/singleStudent/' + id
}

export const studentDetailsUrl = () => {
    return apiUrl + '/registerStudent/studentDetails'
}

export const searchStudent = () => {
    return apiUrl + '/search/student'
}

export const moveToNextDeskUrl = () => {
    return apiUrl + '/api/applicationMoveToNextDesk'
}
export const moveToVisaRefundUrl = () => {
    return apiUrl + '/api/applicationGoToVisaRefund'
}

export const uploadDocumentUrl = () => {
    return apiUrl + '/api/uploadDocument'
}

export const uploadApDocumentUrl = () => {
    return apiUrl + '/api/uploadAppDocument'
}

export const submitNoteUrl = () => {
    return apiUrl + '/api/submitNote'
}

export const submitCommentUrl = () => {
    return apiUrl + '/api/submitComment'
}

export const uploadProfileImageUrl = () => {
    return apiUrl + '/api/uploadProfileImage'
}

export const checkBranchUserExistsUrl = () => {
    return apiUrl + '/api/checkBranchUserExists'
}

export const rollbackDeskUrl = () => {
    return apiUrl + '/api/rollbackDesk'
}

export const quickAddStudentUrl = () => {
    return apiUrl + '/api/quickAddStudent'
}

export const withdrawalStudentUrl = () => {
    return apiUrl + '/api/withdrawalStudent'
}

export const applicationCloseDirectUrl = () => {
    return apiUrl + '/api/applicationCloseDirect'
}

export const exportStudentListUrl = () => {
    return apiUrl + '/api/exportStudentList'
}

export const transferToOtherAgentUrl = () => {
    return apiUrl + '/api/transferToOtherAgent'
}

export const checkReportExistsUrl = () => {
    return apiUrl + '/api/checkReportDownloadFile'
}
export const reCalculateCommissionUrl = () => {
    return apiUrl + '/api/reCalculateCommission'
}

export const updateApplicationTuitionFeesUrl = () => {
    return apiUrl + '/api/updateApplicationTuitionFees'
}
export const updateApplicationFamilyNameUrl = () => {
    return apiUrl + '/api/updateApplicationFamilyName'
}

export const reActivateApplicationUrl = () => {
    return apiUrl + '/api/reActivateApplication'
}

export const changeApplicationUniversityUrl = () => {
    return apiUrl + '/api/changeApplicationUniversity'
}

export const eduBoardAndUniversityUrl = () => {
    return apiUrl + '/api/eduBoardAndUniversity'
}
export const updateUniversityInApplicationUrl = () => {
    return apiUrl + '/api/updateUniversityInApplication'
}

export const moveToDraftUrl = () => {
    return apiUrl + '/api/draftApplication'
}
export const updateUkApplicationTuitionFeesUrl = () => {
    return apiUrl + '/api/updateUkApplicationTuitionFees'
}

export const transferApplicationUniversityL1Url = () => {
    return apiUrl + '/api/transferApplicationUniversityL1'
}

export const studentDuplicateRecordsUrl = () => {
    return apiUrl + '/api/studentDuplicateRecords'
}
export const singleStudentDetailsUrl = () => {
    return apiUrl + '/api/studentDetails'
}
export const checkAddStudentInAustraliaUrl = () => {
    return apiUrl + '/api/checkAddStudentInAustralia'
}
export const reActivateApplicationByAgentUrl = () => {
    return apiUrl + '/api/reActivateApplicationByAgent'
}
export const uploadLesterDocumentUrl = () => {
    return apiUrl + '/api/uploadLesterDocument'
}
export const onshoreStudentDocumentUrl = () => {
    return apiUrl + '/api/onshoreStudentDocument'
}

export const studentDiversityUrl = () => {
    return apiUrl + '/api/studentDiversity'
}

export const uploadMozaikLetterUrl = () => {
    return apiUrl + '/api/uploadMozaikLetter'
}

export const transferToTuitionFeeDepartmentUrl = () => {
    return apiUrl + '/api/transferToTuitionFeeDepartment'
}
export const updateCountryAndUniversityInApplicationUrl = () => {
    return apiUrl + '/api/updateCountryAndUniversityInApplication'
}

export const studentCurrentBalanceUrl = () => {
    return apiUrl + '/api/getStudentCurrentBalance'
}

export const getWithdrawalListUrl = () => {
    return apiUrl + '/student-api/withdrawal'
}
export const sendWithdrawalOtpUrl = () => {
    return apiUrl + '/student-api/sendWithdrawalOtp';
}

export const addWithdrawalUrl = () => {
    return apiUrl + '/student-api/addWithdrawal';
}

export const updateWithdrawalUrl = () => {
    return apiUrl + '/student-api/updateWithdrawal';
}

export const addAppliedEventUrl = () => {
    return apiUrl + '/applied-event/add';
}

export const appliedEventListUrl = () => {
    return apiUrl + '/applied-event/list'
}

