import {default as React, useEffect, useState, useRef} from "react";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card, Col,
  Form, Input,
  notification, Row, Table
} from 'antd'
// import {courseCategoryList } from "../../Masters/courseCategory/action";
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllUniversities } from '../../university/actions/university'
import { courseCategoryList, listAllCourse } from '../../course/actions/courseAction'
import { InputBox } from '../../../components/_utils/appUtils'
import { AddTemplateFxn } from './action/template'
import { push } from 'connected-react-router'
// import {getRelatedUniversityListFxn} from "../../Course/action";
// import {loadCountryList} from "../../Masters/state/action";


const AddTemplate = (props) => {
  // const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const {getFieldDecorator} = props.form;
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [countryId, setCountryData] = useState("");
  const [universityId, setUniversityId] = useState("");
  const [originalDocument, setOriginalDocument] = useState({});
  let dispatch = useDispatch();

  const handleTemplateChange = (e) => {
    const file = e.target.files[0];
    setOriginalDocument(file);
  };

  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {

        let fd = new FormData();
        fd.append("obj", JSON.stringify(valData));

        if (originalDocument && originalDocument.name) {
          fd.append("originalDocument", originalDocument);
        }
        let x = await dispatch(AddTemplateFxn(fd));

        if (!x.error) {

          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }

          props.form.setFieldsValue({
            countryId: "",
            universityId: "",
            courseId: "",
            templateName: "",
            headerDesign: "",
            footerDesign: "",
            categoryId: ""
          });
          // setOriginalDocument({})

          dispatch(push('/template-list'))
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  const loadCountryFxn = async () => {
    let x = await dispatch(listAllCountries());
    setCountryList(x.data);
  };


  useEffect(() => {
    loadCountryFxn();
    handleCourseCategory();
  }, []);
  const apiRequest = (id) => {
    return new Promise(async (resolve) => {
      let params = {}
      if (id) {
        params.universityCountry = id
      }
      params.showUniversity = [true, false]
      let respData = await dispatch(listAllUniversities({
        ...params,

        regExFilters: ['universityType', 'universityName']
      }))
      console.log(respData, "eeeeeeeeeeeeeee")
      setUniversityList(respData.data)
      resolve(respData)

    })
  }
  const getRelatedCourse = async (id) => {
    return new Promise(async (resolve) => {
      let params = {}
      if (id) {
        params.courseUniversity = id
      }

      let respData = await dispatch(listAllCourse({
        ...params,
        regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
      }))
      console.log(respData, "qqqqqqqqqqqqq")
      setCourseList(respData.data)


    })
  };
  const handleCourseCategory = async () => {
    let x = await courseCategoryList();
    setCategoryList(x.data.data);
  };

  let inputTypes = {
    fields: [
      {
        key: "countryId",
        label: "Country",
        required: true,
        placeholder: "Country",
        type: "select",
        span: 12,
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryId: x,
          });
          apiRequest(x);
        },
      },

      {
        key: "universityId",
        label: "University",
        required: true,
        placeholder: "University",
        type: "select",
        span: 12,
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.universityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityId: x,
          });
          getRelatedCourse(x);
        },
      },

      {
        key: "courseId",
        label: "Course",
        required: true,
        placeholder: "Course",
        type: "select",
        span: 12,
        options: courseList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.courseName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            courseId: x,
          });
        },
      },

      {
        key: "templateName",
        label: "Template Name ",
        required: true,
        span: 12,
        placeholder: "Template Name",
        type: "text",

      },

      {
        key: 'headerDesign',
        label: 'Header Design',
        type: 'ckeditor4',
        span: 12,
        onChange: (x) => {
          props.form.setFieldsValue({
            headerDesign: x
          })
        }
      },
      {
        key: 'footerDesign',
        span: 12,
        label: 'Footer Design',
        type: 'ckeditor4',
        onChange: (x) => {
          props.form.setFieldsValue({
            footerDesign: x
          })
        }
      },

      // {
      //   key: "originalDocument",
      //   label: "Original Document",
      //   placeholder: "Original Document",
      //   type: "file",
      //   span: 12,
      //   fileName: originalDocument,
      //   // onChange: handleTemplateChange,
      // },
      {
        key: 'originalDocument',
        span: 12,
        customField: (
          <InputBox title={'Original Document'} className={'logoCls'}>
            <Input
              type={'file'}
              className={'form-control mt-2'}
              ref={fileInputRef}
              name={'originalDocument'}
              id={'logo'}
              onChangeCapture={handleTemplateChange}
            />
          </InputBox>
        )
      },
      {
        key: "categoryId",
        label: "Category",
        span: 12,
        // required: true,
        placeholder: "Category",
        type: "select",
        options: categoryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            categoryId: x,
          });
        },

      },
    ],
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={handleSubmit}>
            <div className='form-box mt-4'>

              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Add New SOP Template</h5>
              </div>

              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox1 ? { paddingBottom: 1 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox1}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}

                  {/* {agents && agents.length ? <Col span={24} style={{ marginBottom: 20 }}>
                    <Card title={'Agents'} size={'small'}>
                      <div className={'guestEmailBox'}>
                        <ul>
                          {agents.map((item, index) => {
                            return (
                              <li className={'emailTag'} key={index}>
                                {item.name} ({item.companyName})
                                <Icon type={'close'}
                                      onClick={() => this.agentEvents.removeAgent(index)}
                                      className={'emailIcon'} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Card>
                  </Col> : null}*/}
                </Row>



                <Form.Item>
                  <Button type='primary' htmlType='submit'  className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>

        </div>
      </div>
    </>
  );
};

export default Form.create()(AddTemplate);
