import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addUniversityUrl,
  exportUniversityListUrl, listAllUniversitiesAccTemplateUrl,
  getSingleUniversityDataUrl,
  listCourseUrl,
  listUniversitiesUrl,
  listUniversityUrl,
  loadAllUniversityNameUrl,
  quickSearchUniversity,
  starUniversityUrl,
  universityIsFeaturedUrl,
  updateTopUniversityUrl, updateUniversityUrl,
  userWiseUniversityListUrl, addAboutUniUrl, getAllUniUrl, deleteAllUniUrl
} from '../api/university'
import {
  addCourseStructureUrl,
  courseStructureListUrl,
  deleteTemplateCourseCategoryUrl
} from '../../course/api/courseApi'

export const addUniversity = valData => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let { data } = await axios.post(addUniversityUrl(), valData, getToken())
  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listAllUniversities = filters => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listUniversitiesUrl(), config)
  // console.log('data from backend is all', data)
  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  dispatch({ type: 'LIST_ALL_UNIVERSITY', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const userWiseUniversityList = filter => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let config = {
    params: filter,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(userWiseUniversityListUrl(), config)
  // console.log('data from backend is all', data)

  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  dispatch({ type: 'LIST_ALL_UNIVERSITY', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getUniversity = id => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listUniversityUrl(id), config)
  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_UNIVERSITY', payload: data })
  }
  return data.data
}

export const updateUniversity = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  // dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let { data } = await axios.put(listUniversityUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const listQuickSearchUniversities = filters => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(quickSearchUniversity(), config)
  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  dispatch({ type: 'LIST_QUICK_SEARCH_UNIVERSITY', payload: data })
  return data
}

export const loadAllUniversityNameList = () => async dispatch => {
  let { data } = await axios.post(loadAllUniversityNameUrl(), {}, getToken())
  return data
}

export const UniversityIsFeatured = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    universityIsFeaturedUrl(),
    valdata,
    getToken()
  )
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const starUniversity = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(starUniversityUrl(), valdata, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const deleteUniversity = id => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(listUniversityUrl(id), getToken())
  if (data && data.success) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const exportUniversityList = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportUniversityListUrl(),
    valData,
    getToken()
  )
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const listAllCourse = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateTopUniversityFxn = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateTopUniversityUrl(), valdata, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  dispatch(hidePageLoad())
  return data
}
export const listAllUniversitiesAccTemplate = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listAllUniversitiesAccTemplateUrl(), config)

  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateUniversityFxn = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  valData.universityId = id
  console.log(valData,"valData")
  let { data } = await axios.post(updateUniversityUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const getSingleUniversityFxn = id => async dispatch => {
  dispatch({ type: 'UNIVERSITY_SHOW_LOADER' })
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    params:{universityId : id}
  }
  let { data } = await axios.get(getSingleUniversityDataUrl(id), config)
  dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_UNIVERSITY', payload: data })
  }
  return data.data
}

export const addAboutUniFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addAboutUniUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getAllUniFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: valData,
    ...await getToken()
  }
  let { data } = await axios.get(getAllUniUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const deleteAboutUniFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteAllUniUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
