import TxnList from "./containers/reeudo/views/transactionList";
import EditTxnStudent from "./containers/reeudo/views/editTxnStudent";
import WrapExchangeComponent from "./containers/reeudo/views";
import UpdateAgentMarginForm from "./containers/reeudo/views/updateAgentMargin";
import {adminRightUser} from "./components/_utils/appUtils";

let menu = [
    {
        path: '/forex-exchange',
        title: 'Forex Exchange',
        name: 'Forex Exchange',
        icon: 'dashboard',
        key: 'exchangeComponent',
        dontShowOnMenu: false,
        component: WrapExchangeComponent,
        authority: [...adminRightUser, , 'agent', 'reeudoManager'],
        newLink: true,
        // restrict: true,
    },
    {
        path: '/forex-transactions',
        title: 'Forex Transactions',
        name: 'Forex Transactions',
        icon: 'dashboard',
        key: 'txnList',
        component: TxnList,
        authority: [...adminRightUser, 'agent', 'reeudoManager'],
        // restrict: true,
        newLink: true,
    },
    {
        path: '/editTxnStudent',
        title: 'Edit Student',
        name: 'Edit Student',
        icon: 'dashboard',
        key: 'editTxnStudent',
        component: EditTxnStudent,
        dontShowOnMenu: true,
        authority: [...adminRightUser, 'agent', 'reeudoManager'],
        // restrict: true,
    },
    {
        path: '/update-agent-margin',
        title: 'Update Agent Margin',
        name: 'Update Agent Margin',
        key: 'updateAgentMargin',
        dontShowOnMenu: true,
        component: UpdateAgentMarginForm,
        authority: [...adminRightUser, 'agent'],
        // restrict: true,
    },
]

export default menu
