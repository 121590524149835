import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Col, Icon, Progress, Row, notification } from 'antd'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Single from '../../assets/singleroom.jpg'
import { InputBox } from '../../components/_utils/appUtils'
import {
  countryNationality
} from '../../components/_utils/countryUtil'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import {
  singleRoomBookingPeriodFxn,
  singleRoomFxn,
  submitOnNoGuaranterFxn
} from '../actions'
import {
  singleBookingFxn,
  submitEnquiriesFxn,
  submitFinalCheckoutFxn,
  updateBookingFxn
} from '../actions/payment'
import './checkout.css'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'

const yearOfStudyArr = [
  { name: 'Foundation', value: 'Foundation' },
  { name: 'Undergraduate - First year', value: 'Undergraduate - First year' },
  { name: 'Undergraduate - Second year', value: 'Undergraduate - Second year' },
  { name: 'Undergraduate - Third year', value: 'Undergraduate - Third year' },
  { name: 'Undergraduate - Placement year', value: 'Undergraduate - Placement year' },
  { name: 'Postgraduate - Masters', value: 'Postgraduate - Masters' },
  { name: 'Postgraduate - PhD', value: 'Postgraduate - PhD' },
  { name: 'Other', value: 'Other' }
]
let dateFormat = 'DD-MM-YYYY'

let $ = window.$

let initState = {
  title: '',
  first_name: '',
  last_name: '',
  university: '',
  year_of_study: '',
  course_title: '',
  gender: '',
  nationality: '',
  dayOfBirth: '',
  monthOfBirth: '',
  yearOfBirth: '',
  email: '',
  contact_number: '',
  first_line: '',
  second_line: '',
  postcode: '',
  city: '',
  country: '',
  additionalMessage: '',
  supportingFirstName: '',
  supportingLastName: '',
  supportingContactNumber: '',
  supportingEmail: '',
  supportingRelationship: '',
  supportingAddressLine1: '',
  supportingAddressLine2: '',
  supportingPostcode: '',
  supportingDayOfBirth: '',
  supportingMonthOfBirth: '',
  supportingYearOfBirth: '',
  supportingCity: '',
  supportingCountry: '',
  paymentOption: 'oneInstalment',
  hasUKBasedGuarantor: 'Yes',
  usingUnizHand: false
}

/*let initState = {
    title: '',
    first_name: 'd',
    last_name: 'd',
    university: 'd',
    year_of_study: '',
    course_title: '',
    gender: 'male',
    nationality: 'IN',
    dayOfBirth: '11',
    monthOfBirth: '10',
    yearOfBirth: '1991',
    email: 'd@gmail.com',
    contact_number: '+916562656562',
    first_line: 'a',
    second_line: '',
    postcode: '132001',
    city: 'karnal',
    country: 'IN',
    additionalMessage: 'd',
    supportingFirstName: 'd',
    supportingLastName: 'v',
    supportingContactNumber: '+916562656562',
    supportingEmail: 'aa@gmail.com',
    supportingRelationship: 'uncle',
    supportingAddressLine1: 'd',
    supportingAddressLine2: '',
    supportingPostcode: '132001',
    supportingDayOfBirth: '11',
    supportingMonthOfBirth: '10',
    supportingYearOfBirth: '1989',
    supportingCity: 'knaral',
    supportingCountry: 'IN',
    paymentOption: 'oneInstalment',
    hasUKBasedGuarantor: 'Yes',
    usingUnizHand: false,
}*/

const useOptions = () => {
  let fontSize = '16px'
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          },
          boxShadow: 'none'
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    [fontSize]
  )
  return options
}
const BookingSummary = ({ bookingPeriodList, roomData }) => {
  if (!bookingPeriodList || bookingPeriodList.length === 0) {
    return <div>No booking data available.</div>
  }
  const bookingPeriod = bookingPeriodList[0]

  return (
    <div className={'col-md-4 booking-card'}>
      <h5 style={{ fontWeight: '600', margin: '20px 10px' }}>
        Booking summary
      </h5>
      <div className={'row'}>
        <div className={'col-md-4'} style={{ margin: '20px 10px' }}>
          <img
            src={Single}
            alt='Booking Summary'
            style={{
              maxWidth: '100%',
              maxHeight: '150px',
              objectFit: 'cover'
            }}
            className='img-fluid'
          />
        </div>
        <div className={'col-md-7'}>
          <h6 style={{ fontWeight: '500', marginTop: '20px' }}>
            <b>
              {roomData && roomData.property && roomData.property.name
                ? roomData.property.name
                : ''}
            </b>
          </h6>
          <p>{roomData && roomData.name ? roomData.name : ''}</p>
        </div>
      </div>
      <h6 style={{ fontWeight: '500', margin: '20px 10px' }}>
        <b>Booking Period</b>
      </h6>
      <div className='booking-period'>
        <h6>
          <strong>{`${bookingPeriod.start_date} - ${
            bookingPeriod.end_date
          }`}</strong>
        </h6>
        <h6>{`${bookingPeriod.price_per_week} /week  
      
        `}</h6>
      </div>
      <div style={{ margin: '10px 10px' }}>
        <p>You will be able to move in later or move out sooner.</p>
        <p>
          Don’t lose it! You only need to pay a holding deposit today to secure
          your room
        </p>
      </div>
      <div
        className={'row'}
        style={{
          margin: '30px 10px',
          backgroundColor: '#37D67A',
          borderRadius: '10px'
        }}>
        <div className={'col-md-8'} style={{ marginTop: '10px' }}>
          <h6>
            <b>Due today</b>
          </h6>
          <p>Deposit</p>
          {/* <p>
                        This is refundable{' '}
                        <span style={{textDecoration: 'underline'}}>Learn more.</span>
                    </p>*/}
        </div>
        <div className={'col-md-4'} style={{ margin: '40px 0px 10px' }}>
          <h6>
            <b>{bookingPeriod.holding_deposit}</b>
          </h6>
        </div>
      </div>
    </div>
  )
}
const CheckoutComponent = props => {
  let { stripeClientInfo, refreshBooking } = props
  const history = useHistory()

  const options = useOptions()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  let { visible, title, onClose, roomDetails, buildingInfo, room } = props
  // console.log(roomDetails, room, 'roomDetailsroomDetails')
  let [step, setStep] = useState(1)
  const [state, setState] = useState(initState)
  let [bookingPeriodList, setBookingPeriodList] = useState([])
  let [roomData, setRoomData] = useState({})
  useEffect(() => {
    events.checkRoomIdFxn()
  }, [])
  let events = {
    updateState: data => {
      // console.log(data, state.hasUKBasedGuarantor)
      setState(prevData => {
        return {
          ...prevData,
          ...data
        }
      })
    },
    goBackFxn: () => {
      let cloneStep = _.clone(step)
      if (cloneStep > 1) {
        cloneStep = cloneStep - 1
      }
      setStep(cloneStep)
    },
    submitOnNoGuaranter: async () => {
      let { pathname, search } = window.location
      const queryParams = new URLSearchParams(search)

      let bookingPeriodId = queryParams.get('booking_period_id')
      let operatorId = queryParams.get('operator_id')
      let roomId = queryParams.get('room_id')
      let property_id = queryParams.get('property_id')

      /* console.log('Booking Period ID:', bookingPeriodId)
       console.log('Operator ID:', operatorId)
       console.log('Room ID:', roomId)*/
      let obj = {
        email: state.email,
        room_id: roomId,
        property_id: property_id,
        operator_id: operatorId,
        last_name: state.last_name,
        first_name: state.first_name,
        message: state.additionalMessage,
        contact_number: state.contact_number
      }
      dispatch(showPageLoad())
      let resp = await submitOnNoGuaranterFxn(obj)
      // console.log(resp, 'respppppppppp')
      dispatch(hidePageLoad())

      if (resp.success && resp.data) {
      }
    },
    submitQuery: async e => {
      e.preventDefault()
      if (step == 1) {
        baseInfoFxn()
      }
      if (step == 2) {
        rendDetailsFxn()
      }

      if (step == 3) {
        events.stripPaymentSubmit().then(resp => {
          dispatch(push(`/pay/${resp.bookingId}`))
        })
      }

      function baseInfoFxn() {
        if (!state.first_name) {
          notification.warning({ message: 'Please enter your first name.' })
          return
        }

        if (!state.last_name) {
          notification.warning({ message: 'Please enter your last name.' })
          return
        }

        if (!state.university) {
          notification.warning({ message: 'Please enter your university.' })
          return
        }

        if (!state.year_of_study) {
          notification.warning({
            message: 'Please select your year of study.'
          })
          return
        }

        if (!state.course_title) {
          notification.warning({ message: 'Please enter your course title.' })
          return
        }

        if (!state.gender) {
          notification.warning({ message: 'Please select your gender.' })
          return
        }
        if (!state.nationality) {
          notification.warning({ message: 'Please select your nationality.' })
          return
        }

        if (!state.dayOfBirth || !state.monthOfBirth || !state.yearOfBirth) {
          notification.warning({
            message: 'Please enter your full date of birth.'
          })
          return
        }
        let dateB = `${state.dayOfBirth}-${state.monthOfBirth}-${state.yearOfBirth}`
        if (!moment(dateB, dateFormat, true).isValid()) {
          notification.warning({
            message: 'Please enter valid date of birth.'
          })
          return
        }


        if (!state.email) {
          notification.warning({ message: 'Please enter your email.' })
          return
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (!emailRegex.test(state.email)) {
          notification.warning({
            message: 'Please enter a valid email address.'
          })
          return
        }

        if (!state.contact_number) {
          notification.warning({
            message: 'Please enter your contact number.'
          })
          return
        }
        if (!isValidPhoneNumber(state.contact_number)) {
          notification.warning({
            message: 'Please valid contact number.'
          })
          return
        }


        // const isValidPhoneNumber = phoneNumberValidation.isValidPhoneNumber(
        //   state.contact_number
        // )

        // if (!isValidPhoneNumber) {
        //   notification.warning({
        //     message: 'Please enter a valid phone number.'
        //   })
        //   return
        // }

        if (!state.first_line) {
          notification.warning({
            message: 'Please enter your address line 1.'
          })
          return
        }

        if (!state.postcode) {
          notification.warning({ message: 'Please enter your postcode.' })
          return
        }

        if (!state.city) {
          notification.warning({ message: 'Please enter your city.' })
          return
        }

        if (!state.country) {
          notification.warning({ message: 'Please enter your country.' })
          return
        }

        setStep(2)
        $('html, body').animate({ scrollTop: 0 }, 'slow')
      }

      function rendDetailsFxn() {
        if (!state.supportingFirstName) {
          notification.warning({
            message: 'Please provide the supporting first name.'
          })
          return
        }

        if (!state.supportingLastName) {
          notification.warning({
            message: 'Please provide the supporting last name.'
          })
          return
        }

        if (!state.supportingContactNumber) {
          notification.warning({
            message: 'Please provide the supporting contact number.'
          })
          return
        }
        if (!isValidPhoneNumber(state.supportingContactNumber)) {
          notification.warning({
            message: 'Please valid contact number.'
          })
          return
        }
        const contactNumberRegex = /^\d{10}$/

        /*  if (!contactNumberRegex.test(state.supportingContactNumber)) {
            notification.warning({
              message: 'Please enter a valid 10-digit SupportingContact number.'
            })
            return
          }*/
        if (!state.supportingEmail) {
          notification.warning({
            message: 'Please provide the supporting email.'
          })
          return
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (!emailRegex.test(state.supportingEmail)) {
          notification.warning({
            message: 'Please enter a valid Supporting Email address.'
          })
          return
        }

        if (!state.supportingRelationship) {
          notification.warning({
            message: 'Please provide the supporting relationship.'
          })
          return
        }

        if (
          !state.supportingDayOfBirth ||
          !state.supportingMonthOfBirth ||
          !state.supportingYearOfBirth
        ) {
          notification.warning({
            message: 'Please provide the complete supporting date of birth.'
          })
          return
        }
        let dateB = `${state.supportingDayOfBirth}-${state.supportingMonthOfBirth}-${state.supportingYearOfBirth}`
        if (!moment(dateB, dateFormat, true).isValid()) {
          notification.warning({
            message: 'Please enter valid date of birth.'
          })
          return
        }

        if (!state.supportingAddressLine1) {
          notification.warning({
            message: 'Please provide the supporting address line 1.'
          })
          return
        }

        if (!state.supportingPostcode) {
          notification.warning({
            message: 'Please provide the supporting postcode.'
          })
          return
        }

        if (!state.supportingCity) {
          notification.warning({
            message: 'Please provide the supporting city.'
          })
          return
        }

        if (!state.supportingCountry) {
          notification.warning({
            message: 'Please provide the supporting country.'
          })
          return
        }

        setStep(3)
        $('html, body').animate({ scrollTop: 0 }, 'slow')
      }
    },
    submitEnquiry: async e => {
      e.preventDefault()
      // let property_id = queryParams.get('property_id')
      let countryCode = ''
      if (state.countryCode) {
        countryCode = state.countryCode.split('_').pop()
      }
      const finalObject = {
        session_token: stripeClientInfo.session_token,
        email: state.email,
        room_id: stripeClientInfo.room_id,
        property_id: stripeClientInfo.property.id,
        operator_id: stripeClientInfo.operator_id,
        last_name: state.last_name,
        first_name: state.first_name,
        // message: state.additionalMessage,
        contact_number: countryCode + state.contact_number,
        message: 'This is the enquiry message about property'
      }
      let finalResp = await dispatch(
        submitEnquiriesFxn({
          enquiry: finalObject,
          bookingId: stripeClientInfo._id
        })
      )
      if (finalResp && finalResp.success) {
        alert('Enquiry submitted successfully.')
        history.push('/unizhome')
      }
    },
    stripPaymentSubmit: () => {
      return new Promise(async resolve => {
        let { paymentStatus } = stripeClientInfo
        if (paymentStatus && paymentStatus == 'Success') {
          dispatch(showPageLoad())
          submitFxn()
        } else {
          const cardElement = elements.getElement(CardNumberElement)
          dispatch(showPageLoad())
          const confirmPayment = await stripe.confirmCardPayment(
            stripeClientInfo.client_secret,
            {
              payment_method: { card: cardElement }
            }
          )

          let obj = {
            stripeResponse: confirmPayment,
            bookingId: stripeClientInfo._id,
            paymentStatus: confirmPayment.error ? 'Fail' : 'Success'
          }
          if (confirmPayment && confirmPayment.error) {
            obj = {
              ...obj,
              ...events.getFinalObj()
            }
          }
          let resp = await updateBookingFxn(obj)
          if (confirmPayment && confirmPayment.error) {
            notification.warning({ message: confirmPayment.error.message })
            dispatch(hidePageLoad())
          } else {
            submitFxn()
          }
        }

        async function submitFxn() {
          let finalObject = events.getFinalObj()
          let finalResp = await submitFinalCheckoutFxn({
            ...finalObject,
            room_id: stripeClientInfo.room_id
          })
          dispatch(hidePageLoad())
          resolve(finalResp)
        }
      })
    },
    renderPaymentScreen: () => {
      switch (state.paymentOption) {
        case 'oneInstalment':
          return <></>
        case 'multipleInstalments':
          return (
            <>
              <p
                style={{
                  backgroundColor: '#f3f4f6',
                  margin: '20px 0',
                  padding: '11px',
                  lineHeight: '2rem',
                  borderRadius: 5
                }}>
                <Icon type='exclamation' /> &nbsp; The accommodation provider
                for this building requires that you have a guarantor for this
                option.{' '}
                <strong>
                  <u>What is a guarantor?</u>
                </strong>
                <br />
                Your guarantor has to be based in the UK 🇬🇧.
                <br />
                If you don’t have a UK 🇬🇧 based guarantor, select “No” and we
                can help arrange one for you
              </p>
              <div>
                <div className={'title-head'}>
                  DO YOU HAVE A UK BASED GUARANTOR?
                </div>
                <label className='guarantor'>
                  <input
                    type='radio'
                    name='hasUKGuarantor'
                    value='Yes'
                    checked={state.hasUKBasedGuarantor === 'Yes'}
                    onChange={() =>
                      events.updateState({ hasUKBasedGuarantor: 'Yes' })
                    }
                    // onChange={() => handleUKGuarantorChange('Yes')}
                  />
                  Yes
                </label>
                <label className='guarantor'>
                  <input
                    type='radio'
                    name='hasUKGuarantor'
                    value='No'
                    checked={state.hasUKBasedGuarantor === 'No'}
                    onChange={() =>
                      events.updateState({ hasUKBasedGuarantor: 'No' })
                    }
                  />
                  No
                </label>
              </div>
              {state.hasUKBasedGuarantor === 'Yes' && (
                <>
                  <div
                    className={'title-head'}
                    style={{ margin: '10px 10px 10px 0px' }}>
                    ADD GUARANTOR DETAILS BELOW
                  </div>
                  <p>
                    The accommodation provider may contact the guarantor to
                    confirm these details.
                  </p>

                  <label style={{ textAlign: 'left' }}>
                    <input
                      type='checkbox'
                      name='usingUnizHand'
                      checked={state.usingUnizHand}
                      onChange={() =>
                        setState(prevState => ({
                          ...prevState,
                          usingUnizHand: !prevState.usingUnizHand
                        }))
                      }
                    />
                    I am using Uniz Hand as my guarantor
                  </label>
                  {state.usingUnizHand && (
                    <>
                      <p>
                        IF YOU'RE USING UNIZ HAND, WE REQUIRE SOME EMERGENCY
                        CONTACT DETAILS.
                        <br />
                        The accommodation provider may contact the emergency
                        contact to confirm these details.
                      </p>
                    </>
                  )}
                </>
              )}
              {state.hasUKBasedGuarantor === 'No' && (
                <>
                  <p>
                    Don’t worry! We can help. Submit the details you have
                    already entered, and a member of our team will contact you
                    to help arrange a UK based guarantor for you with our
                    partners at Uniz Hand who help 1000s of students every year
                    as the leading student-first guarantor service.
                  </p>
                </>
              )}
            </>
          )

        default:
          return null
      }
    },
    checkRoomIdFxn: async () => {
      let { search } = window.location
      const queryParams = new URLSearchParams(search)
      let roomId = queryParams.get('room_id')
      // console.log(roomId, 'rommmmmmmmiririririrri')
      if (roomId) {
        events.loadRoomData(roomId)
        events.getBookPeriod(roomId)
      }
    },
    loadRoomData: async roomId => {
      if (roomId) {
        let { data, success } = await singleRoomFxn(roomId)
        // console.log(data, 'singlerooomeiieeieieieiei')

        if (success && data) {
          setRoomData(data.item)
        }
      }
    },
    getBookPeriod: async roomId => {
      if (roomId) {
        let { data, success } = await singleRoomBookingPeriodFxn(roomId)
        if (success && data && data.items && data.items.length) {
          // console.log(data, 'singleroombooking')
          setBookingPeriodList(data.items)
        }
      }
    },
    consoleFxn: () => {
      const formattedDateOfBirth = `${state.dayOfBirth}-${
        state.monthOfBirth
      }-${state.yearOfBirth}`
      const supportingDateOfBirth = `${state.supportingDayOfBirth}-${
        state.supportingMonthOfBirth
      }-${state.supportingYearOfBirth}`

      const finalObject = {
        session_token: stripeClientInfo.session_token,
        paying_in_instalments:
          state.paymentOption === 'multipleInstalments' ? true : false,
        has_uk_based_guarantor:
          state.hasUKBasedGuarantor == 'Yes' ? true : false,
        is_using_uniz_hand: state.usingUnizHand,
        is_using_housing_hand: state.usingUnizHand,
        resident_details: {
          city: state.city,
          country: state.country,
          nationality: state.nationality,
          postcode: state.postcode,
          first_line: state.first_line,
          first_name: state.first_name,
          last_name: state.last_name,
          gender: state.gender,
          email: state.email,
          date_of_birth: formattedDateOfBirth,
          contact_number: state.contact_number
        },
        supporting_contact_details: {
          city: state.supportingCity,
          // region: state.region,
          country: state.supportingCountry,
          postcode: state.supportingPostcode,
          first_line: state.supportingAddressLine1,
          second_line: state.supportingAddressLine2,
          first_name: state.supportingFirstName,
          last_name: state.supportingLastName,
          email: state.supportingEmail,
          date_of_birth: supportingDateOfBirth,
          contact_number: state.supportingContactNumber,
          relationship: state.supportingRelationship
        },
        course_details: {
          university: state.university,
          year_of_study: state.year_of_study,
          course_title: state.course_title
        },
        bookingId: stripeClientInfo._id
      }

      console.log(finalObject, 'finalObject')
    },
    getFinalObj: () => {
      const formattedDateOfBirth = `${state.dayOfBirth}-${
        state.monthOfBirth
      }-${state.yearOfBirth}`
      const supportingDateOfBirth = `${state.supportingDayOfBirth}-${
        state.supportingMonthOfBirth
      }-${state.supportingYearOfBirth}`
      const finalObject = {
        session_token: stripeClientInfo.session_token,
        paying_in_instalments:
          state.paymentOption === 'multipleInstalments' ? true : false,
        has_uk_based_guarantor:
          state.hasUKBasedGuarantor == 'Yes' ? true : false,
        is_using_uniz_hand: state.usingUnizHand,
        is_using_housing_hand: state.usingUnizHand,
        resident_details: {
          city: state.city,
          country: state.country.toLowerCase(),
          nationality: state.nationality.toLowerCase(),
          postcode: state.postcode,
          first_line: state.first_line,
          first_name: state.first_name,
          last_name: state.last_name,
          gender: state.gender,
          email: state.email,
          date_of_birth: formattedDateOfBirth,
          contact_number: state.contact_number
        },
        supporting_contact_details: {
          city: state.supportingCity,
          // region: state.region,
          country: state.supportingCountry.toLowerCase(),
          postcode: state.supportingPostcode,
          first_line: state.supportingAddressLine1,
          second_line: state.supportingAddressLine2,
          first_name: state.supportingFirstName,
          last_name: state.supportingLastName,
          email: state.supportingEmail,
          date_of_birth: supportingDateOfBirth,
          contact_number: state.supportingContactNumber,
          relationship: state.supportingRelationship
        },
        course_details: {
          university: state.university,
          year_of_study: state.year_of_study,
          course_title: state.course_title
        },
        bookingId: stripeClientInfo._id
      }
      return finalObject
    }
  }

  const residentDetails = (
    <>
      <div className='col-md-12'>
        <div className={'title-head'}>Resident Details</div>
        <p>
          Please fill out the details of the student who will be living at the
          property, whether it is yourself or someone you are booking on behalf
          of.
        </p>
        <Row gutter={16}>
          <Col span={12}>
            <InputBox title={'First Name *'}>
              <input
                value={state.first_name}
                type='text'
                name='firstName'
                className='form-control'
                placeholder='First Name'
                onChange={e => {
                  events.updateState({ first_name: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Last Name *'}>
              <input
                value={state.last_name}
                type='text'
                name='lastName'
                className='form-control'
                placeholder='Last Name'
                onChange={e => {
                  events.updateState({ last_name: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'University *'}>
              <input
                value={state.university}
                type='text'
                name='university'
                className='form-control'
                placeholder='University'
                onChange={e => {
                  events.updateState({ university: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Year of Study *'}>
              <select
                value={state.year_of_study}
                className='form-control'
                name='yearOfStudy'
                onChange={e => {
                  events.updateState({ year_of_study: e.target.value })
                }}>
                <option value=''>Select Year of Study</option>
                {yearOfStudyArr.map((item) => {
                  return (
                    <option value={item.value} key={item.name}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Course Title *'}>
              <input
                value={state.course_title}
                type='text'
                name='courseTitle'
                className='form-control'
                placeholder='Course Title'
                onChange={e => {
                  events.updateState({ course_title: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Gender *'}>
              <select
                value={state.gender}
                className='form-control'
                name='gender'
                onChange={e => {
                  events.updateState({ gender: e.target.value })
                }}>
                <option value=''>Select Gender</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
                <option value='preferNotToSay'>Prefer Not to Say</option>
                <option value='others'>Others</option>
              </select>
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Your Nationality *'}>
              <select
                value={state.nationality}
                className='form-control'
                name='communicationMethod'
                onChange={val => {
                  events.updateState({ nationality: val.target.value })
                }}>
                <option value=''>Select Nationality</option>
                {countryNationality.map((item, key) => {
                  return (
                    <option value={item.alpha_2_code} key={item.id}>
                      {item.nationality}
                    </option>
                  )
                })}
              </select>
            </InputBox>
          </Col>
        </Row>
        <div className={'mt20'}>
          <div className={'title-label'}>Date of Birth</div>
          <Row gutter={16}>
            <Col span={8}>
              <InputBox title={'Day *'}>
                <input
                  value={state.dayOfBirth}
                  type='number'
                  name='dayOfBirth'
                  className='form-control'
                  placeholder='e.g. 31'
                  onChange={e => {
                    events.updateState({ dayOfBirth: e.target.value })
                  }}
                />
              </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Month *'}>
                <input
                  value={state.monthOfBirth}
                  type='number'
                  name='monthOfBirth'
                  className='form-control'
                  placeholder='e.g. 12'
                  onChange={e => {
                    events.updateState({ monthOfBirth: e.target.value })
                  }}
                />
              </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Year *'}>
                <input
                  value={state.yearOfBirth}
                  type='number'
                  name='yearOfBirth'
                  className='form-control'
                  placeholder='e.g. 1999'
                  onChange={e => {
                    events.updateState({ yearOfBirth: e.target.value })
                  }}
                />
              </InputBox>
            </Col>
          </Row>
        </div>
      </div>

      <hr />

      <div className={'mt20'}>
        <div className={'title-head'}>Contact Information</div>
        <p>Please provide your contact information</p>

        <Row gutter={16}>
          <Col span={12}>
            <InputBox title={'Email *'}>
              <input
                value={state.email}
                type='email'
                name='email'
                className='form-control'
                placeholder='e.g. stuart@gmail.com'
                onChange={e => {
                  events.updateState({ email: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'Contact Number *'}>
              <PhoneInput
                defaultCountry='IN'
                placeholder='e.g. 098988 98985'
                value={state.contact_number}
                onChange={(val) => {
                  events.updateState({ contact_number: val })
                }} />
            </InputBox>
          </Col>
        </Row>
        <div className={'title-label mt20'}>Contact Address</div>
        <Row gutter={16}>
          <Col span={24}>
            <InputBox title={'Address Line 1 *'}>
              <input
                value={state.first_line}
                type='text'
                name='addressLine1'
                className='form-control'
                placeholder='e.g. 1 Oxford Road'
                onChange={e => {
                  events.updateState({ first_line: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={24}>
            <InputBox title={'Address Line 2'}>
              <input
                value={state.second_line}
                type='text'
                name='addressLine2'
                className='form-control'
                placeholder='e.g. Park Lane Avenue'
                onChange={e => {
                  events.updateState({ second_line: e.target.value })
                }}
              />
            </InputBox>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <InputBox title={'Postcode *'}>
              <input
                value={state.postcode}
                type='text'
                name='postcode'
                className='form-control'
                placeholder='e.g. M1 5AN'
                onChange={e => {
                  events.updateState({ postcode: e.target.value })
                }}
              />
            </InputBox>
          </Col>
          <Col span={12}>
            <InputBox title={'City *'}>
              <input
                value={state.city}
                type='text'
                name='city'
                className='form-control'
                placeholder='e.g. Manchester'
                onChange={e => {
                  events.updateState({ city: e.target.value })
                }}
              />
            </InputBox>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col span={4}>
            {' '}
            <InputBox title="Country Code" span={6}>
              <div style={{ marginTop: -13 }}>
                <CountryCodeWithFlag
                  countryCode={state.countryCode}
                  chooseCode={val => {
                    setCountryCode(val)
                  }}

                />
              </div>
            </InputBox>
          </Col> */}

          <Col span={12}>
            <InputBox title={'Country *'}>
              <select
                value={state.country}
                className='form-control'
                name='country'
                placeholder='e.g. United Kingdom'
                onChange={val => {
                  events.updateState({ country: val.target.value })
                }}>
                <option value=''>Select Country</option>
                {countryNationality.map((item, key) => {
                  return (
                    <option value={item.alpha_2_code} key={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </InputBox>
          </Col>
        </Row>
      </div>
      <hr />

      <div className={'mt20'}>
        <div className={'title-head'}>Additional Information</div>

        <p>
          Let your accommodation provider know about any requirements you have,
          such as a disability, single-gender sharing preference, or other
          members in a group booking.
        </p>
        <Row gutter={16}>
          <Col span={24}>
            <InputBox title={'Additional Message'}>
              <textarea
                value={state.additionalMessage}
                name='additionalMessage'
                className='form-control textareaField'
                style={{ minHeight: 150 }}
                rows='4'
                placeholder='e.g. I have a disability and require a ground floor room.'
                onChange={e => {
                  events.updateState({
                    additionalMessage: e.target.value
                  })
                }}
              />
            </InputBox>
          </Col>
        </Row>
      </div>
    </>
  )
  const supportingDetails = (
    <>
      <div>
        <div className={'title-head'}>How are you planning to pay rent?</div>
        <div>
          <label
            className={`install-wrapper ${
              state.paymentOption === 'oneInstalment' ? 'selected' : ''
            }`}>
            <input
              type='radio'
              name='paymentOption'
              value='oneInstalment'
              checked={state.paymentOption === 'oneInstalment'}
              onChange={() =>
                events.updateState({ paymentOption: 'oneInstalment' })
              }
              style={{
                width: 20,
                height: 14,
                marginRight: 20,
                marginLeft: 10
              }}
            />
            <div>
              <h6>
                <b>One Installment</b>
              </h6>
              <p>Pay your rent in one installment.</p>
            </div>
          </label>
        </div>

        <label
          className={`install-wrapper ${
            state.paymentOption === 'multipleInstalments' ? 'selected' : ''
          }`}>
          <input
            type='radio'
            name='paymentOption'
            value='multipleInstalments'
            checked={state.paymentOption === 'multipleInstalments'}
            onChange={() =>
              events.updateState({ paymentOption: 'multipleInstalments' })
            }
            style={{
              width: 20,
              height: 14,
              marginRight: 20,
              marginLeft: 10
            }}
          />
          <div>
            <h6>
              <b>Multiple Installments</b>
            </h6>
            <p>
              It is required in the UK that you have a UK guarantor for this
              option. You can still select this option if you don’t have one,
              and we can help arrange one for you.
            </p>
          </div>
        </label>

        {events.renderPaymentScreen()}
      </div>
      {state.hasUKBasedGuarantor === 'No' ? (
        ''
      ) : (
        <div>
          {' '}
          {state.paymentOption === 'multipleInstalments' ? (
            ''
          ) : (
            <>
              {' '}
              <div className={'title-head'}>ADD EMERGENCY DETAILS BELOW</div>
              <p>
                The accommodation provider may contact the emergency contact to
                confirm these details.
              </p>
            </>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <InputBox title={'First Name *'}>
                <input
                  value={state.supportingFirstName}
                  type='text'
                  name='supportingFirstName'
                  className='form-control'
                  placeholder='e.g. John'
                  onChange={e => {
                    events.updateState({
                      supportingFirstName: e.target.value
                    })
                  }}
                />
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Last Name *'}>
                <input
                  value={state.supportingLastName}
                  type='text'
                  name='supportingLastName'
                  className='form-control'
                  placeholder='e.g. Doe'
                  onChange={e => {
                    events.updateState({
                      supportingLastName: e.target.value
                    })
                  }}
                />
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Contact Number *'}>
                <PhoneInput
                  defaultCountry='IN'
                  placeholder='e.g. 098988 98985'
                  value={state.supportingContactNumber}
                  onChange={(val) => {
                    events.updateState({ supportingContactNumber: val })
                  }} />
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Email *'}>
                <input
                  value={state.supportingEmail}
                  type='email'
                  name='supportingEmail'
                  className='form-control'
                  placeholder='e.g. demi2@gmail.com'
                  onChange={e => {
                    events.updateState({ supportingEmail: e.target.value })
                  }}
                />
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Relationship to You *'}>
                <input
                  value={state.supportingRelationship}
                  type='text'
                  name='supportingRelationship'
                  className='form-control'
                  placeholder='e.g. Friend'
                  onChange={e => {
                    events.updateState({
                      supportingRelationship: e.target.value
                    })
                  }}
                />
              </InputBox>
            </Col>
          </Row>
          <div className={'mt20'}>
            <div className={'title-label'}>Date of Birth</div>
            <Row gutter={16}>
              <Col span={8}>
                <InputBox title={'Day *'}>
                  <input
                    value={state.supportingDayOfBirth}
                    type='number'
                    name='supportingDayOfBirth'
                    className='form-control'
                    placeholder='e.g. 11'
                    onChange={e => {
                      events.updateState({
                        supportingDayOfBirth: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Month *'}>
                  <input
                    value={state.supportingMonthOfBirth}
                    type='number'
                    name='supportingMonthOfBirth'
                    className='form-control'
                    placeholder='e.g. 10'
                    onChange={e => {
                      events.updateState({
                        supportingMonthOfBirth: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Year *'}>
                  <input
                    value={state.supportingYearOfBirth}
                    type='number'
                    name='supportingYearOfBirth'
                    className='form-control'
                    placeholder='e.g. 1992'
                    onChange={e => {
                      events.updateState({
                        supportingYearOfBirth: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
            </Row>
          </div>
          <hr />
          <div className={'mt20'}>
            <div className={'title-head'}>EMERGENCY CONTACT ADDRESS</div>
            <Row gutter={16}>
              <Col span={24}>
                <InputBox title={'Address Line 1 *'}>
                  <input
                    value={state.supportingAddressLine1}
                    type='text'
                    name='supportingAddressLine1'
                    className='form-control'
                    placeholder='e.g. asd'
                    onChange={e => {
                      events.updateState({
                        supportingAddressLine1: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={24}>
                <InputBox title={'Address Line 2'}>
                  <input
                    value={state.supportingAddressLine2}
                    type='text'
                    name='supportingAddressLine2'
                    className='form-control'
                    placeholder='e.g. asd'
                    onChange={e => {
                      events.updateState({
                        supportingAddressLine2: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'Postcode *'}>
                  <input
                    value={state.supportingPostcode}
                    type='text'
                    name='supportingPostcode'
                    className='form-control'
                    placeholder='e.g. 1992232'
                    onChange={e => {
                      events.updateState({
                        supportingPostcode: e.target.value
                      })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'City *'}>
                  <input
                    value={state.supportingCity}
                    type='text'
                    name='supportingCity'
                    className='form-control'
                    placeholder='e.g. dasd'
                    onChange={e => {
                      events.updateState({ supportingCity: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'Country *'}>
                  <select
                    value={state.supportingCountry}
                    className='form-control'
                    name='supportingCountry'
                    placeholder='e.g. United Kingdom'
                    onChange={val => {
                      events.updateState({
                        supportingCountry: val.target.value
                      })
                    }}>
                    <option value=''>Select Country</option>
                    {countryNationality.map((item, key) => {
                      return (
                        <option value={item.alpha_2_code} key={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </InputBox>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
  const bankDetails = (
    <>
      <div className={'title-head'}>Payment details</div>
      <p>
        Please fill out the details of the bank Account to make transactions
      </p>

      <div className={'row'}>
        <div className={'col-md-12'}>
          <InputBox title={'Card Number'}>
            <CardNumberElement options={options} />
          </InputBox>
        </div>
        <div className={'col-md-6'}>
          <InputBox title={'Expiry'}>
            <CardExpiryElement options={options} />
          </InputBox>
        </div>
        <div className={'col-md-6'}>
          <InputBox title={'CVC'}>
            <CardCvcElement options={options} />
          </InputBox>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/checkoutStyle.css' />
      </Helmet>

      <div className={'check-box'}>
        <div className='sub-banner'>
          <div className='overlay'>
            <div className='container'>
              <div className='breadcrumb-area'>
                <h1>Properties Listing</h1>
                <ul className='breadcrumbs'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li className='active'>Student Accommodation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='properties-section-body content-area'>
          <div className='container'>
            <div className={'row'}>
              <div className={'col-md-8'}>
                <div className={'progressBox'}>
                  <Progress strokeLinecap='square' percent={step * 33.33} />
                  <div className={'title-label alignCenter mt10'}>
                    Step {step}/3 &nbsp;
                    {step == 1 ? 'Resident Details' : ''}
                    {step == 2 ? 'Rent Details' : ''}
                    {step == 3 ? 'Payment Details' : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-8'}>
                <div className='main-title-4 detailsForm'>
                  {step == 1 && residentDetails}

                  {step == 2 && supportingDetails}

                  {step == 3 && bankDetails}

                  <div className='mt20 mb20 alignRight'>
                    {step > 1 ? (
                      <a
                        onClick={events.goBackFxn}
                        className='btn white-btn btn-lg mr10'>
                        Back
                      </a>
                    ) : null}

                    {step == 1 ||
                    (step == 2 && state.hasUKBasedGuarantor == 'Yes') ||
                    step == 3 ? (
                      <button
                        onClick={events.submitQuery}
                        type='submit'
                        className='btn btn-success btn-lg'>
                        {step === 3 ? (
                          <>
                            Confirm and pay{' '}
                            {stripeClientInfo.booking_periods.holding_deposit}
                          </>
                        ) : (
                          'Continue'
                        )}
                      </button>
                    ) : null}

                    {step === 2 && state.hasUKBasedGuarantor == 'No' ? (
                      <button
                        onClick={events.submitEnquiry}
                        type='submit'
                        className='btn btn-success btn-lg'>
                        Submit details
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>

              <BookingSummary
                bookingPeriodList={bookingPeriodList}
                roomData={roomData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PaymentForm = () => {
  let [stripeClientInfo, setStripeClientInfo] = useState({})
  let [stripePromise, setStripePromise] = useState('')
  let dispatch = useDispatch()
  useEffect(
    () => {
      loadSingleBooking()
    },
    [window.location]
  )

  let loadSingleBooking = async () => {
    let { pathname, search } = window.location
    let bookingId = pathname.split('/').pop()

    let resp = await singleBookingFxn({ bookingId })
    if (resp && resp.success) {
      let { data } = resp
      setStripeClientInfo(data)
      if (data.public_key) {
        let stripePromiseValue = loadStripe(data.public_key)
        setStripePromise(stripePromiseValue)
      }
    }
  }

  return (
    <>
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <CheckoutComponent
            stripeClientInfo={stripeClientInfo}
            refreshBooking={loadSingleBooking}
          />
        </Elements>
      ) : null}
    </>
  )
}

class PaymentFormCls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stripeClientInfo: {},
      stripePromise: ''
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps.UnizHomeReducers)
    if (
      nextProps &&
      nextProps.UnizHomeReducers &&
      nextProps.UnizHomeReducers.session_token &&
      nextProps.UnizHomeReducers.allowSetPublicKey
    ) {
      this.setState(
        {
          stripeClientInfo: nextProps.UnizHomeReducers
        },
        () => {
          this.generateStripePromise()
        }
      )
      this.props.dispatch({ type: 'SET_PUBLIC_KEY', value: false })
    }
  }

  generateStripePromise = () => {
    let { stripeClientInfo } = this.state
    if (stripeClientInfo && stripeClientInfo.public_key) {
      let stripePromiseValue = loadStripe(stripeClientInfo.public_key)
      this.setState({ stripePromise: stripePromiseValue })
    }
  }

  render() {
    let { stripePromise, stripeClientInfo } = this.state
    return (
      <>
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <CheckoutComponent stripeClientInfo={stripeClientInfo} />
          </Elements>
        ) : null}
      </>
    )
  }
}

const PaymentCard = () => {
  const options = useOptions()
  const stripe = useStripe()
  const elements = useElements()
  let clientKey = 'pi_3OYNw6KmgdLsSPkz1OPlVXSj_secret_k3cYRsOuUm7i8VZzA7BcUAY4u'

  const submitFxn = async () => {
    const cardElement = elements.getElement(CardNumberElement)

    const confirmPayment = await stripe.confirmCardPayment(clientKey, {
      payment_method: { card: cardElement }
    })
  }
  return (
    <>
      <div className={'check-box'}>
        <div className='sub-banner'>
          <div className='overlay'>
            <div className='container'>
              <div className='breadcrumb-area'>
                <h1>Properties Listing</h1>
                <ul className='breadcrumbs'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li className='active'>Student Accommodation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='properties-section-body content-area'>
          <div className='container'>
            <div className={'row'}>
              {/*<div className={'col-md-12'}>
                                <CardElement options={options}/>
                            </div>
                            <br/>
                            <br/>
                            <br/>*/}

              <div className={'col-md-12'}>
                <InputBox title={'Card Number'}>
                  <CardNumberElement options={options} />
                </InputBox>
              </div>
              <div className={'col-md-6'}>
                <InputBox title={'Expiry'}>
                  <CardExpiryElement options={options} />
                </InputBox>
              </div>
              <div className={'col-md-6'}>
                <InputBox title={'CVC'}>
                  <CardCvcElement options={options} />
                </InputBox>
              </div>
            </div>

            <a className={'btn btn-success'} onClick={submitFxn}>
              Testing
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const TestingPayment = () => {
  let publicKey =
    'pk_test_51Ds8btKmgdLsSPkzZ5vCP0T4iwmQKY7FgzuatmpyocV49S3zKt3lZlNqkpFNsZEcXHfX9nYFr3s2sUQ5rFAqx9Hh00UcSF32kD'
  let stripePromiseValue = loadStripe(publicKey)

  return (
    <Elements stripe={stripePromiseValue}>
      <PaymentCard />
    </Elements>
  )
}

export default PaymentForm

/*

const mapStateToProps = ({UnizHomeReducers}) => ({
    UnizHomeReducers: UnizHomeReducers
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentForm)
*/
