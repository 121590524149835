import React from "react"
import {Button, Form, Input, Modal, notification} from "antd";
import {hideLoader, hidePageLoad, showPageLoad} from "../../modules/actions";
import {push} from "connected-react-router";
import Request from "../../request";
import moment from "moment";
import {Socket} from "../../socket";
import {showUpdatePasswordFxn} from "../dashboard/actions/dashboardAction";
import {LazyLoadImage} from "react-lazy-load-image-component";

class LoginModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isUser: false,
            dontAskOtp: false,
            last2Digit: '',
            email: '',
        }
        props.dispatch(hidePageLoad())
        props.dispatch(hideLoader())
        this.checkIsLoggedIn()
    }

    checkIsLoggedIn() {
        let resp = localStorage.getItem('token')
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        if (resp && user && user._id) {
            let {dispatch} = this.props
            dispatch(push('/dashboard'))
        }
    }

    openForgotPassword = () => {
        let {dispatch} = this.props
        dispatch(push('/forgot-password'))
    }

    handleSubmit = (e) => {
        const {dispatch} = this.props
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                dispatch(showPageLoad())
                let x = await Request.loginSendOtp(values)
                dispatch(hidePageLoad())
                if (!x.error) {

                    this.setState({
                        isUser: true,
                        email: values.email,
                        last2Digit: x.last2Digit
                    })
                } else {
                    notification.error({
                        message: 'Invalid Login',
                        description: x.message
                    })

                }
            }
        })
    }

    handleOtpSubmit = (e) => {
        const {dispatch} = this.props
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                dispatch(showPageLoad())
                let x = await Request.loginWithOtp({...values, email: this.state.email})
                if (!x.error) {
                    localStorage.setItem('token', x.token)
                    localStorage.setItem('user', JSON.stringify(x.user))

                    dispatch({
                        type: 'SET_CURRENT_USER',
                        user: x.user
                    })

                    dispatch({
                        type: 'REFRESH_MENU',
                        value: moment()
                    })
                    setTimeout(() => {
                        dispatch({
                            type: 'START_LOAD_CURRENT_USER'
                        })
                    }, 300)
                    if (!Socket.connected) {
                        Socket.connect()
                    }

                    dispatch(hidePageLoad())
                    setTimeout(() => {
                        this.props.onSubmit()
                    }, 1000)

                } else {
                    notification.error({
                        message: 'Invalid Login',
                        description: x.message
                    })
                    dispatch(hidePageLoad())
                }
            }
        })
    }


    async resendOtp() {
        const {dispatch} = this.props
        dispatch(showPageLoad())
        let x = await Request.resendOtp({email: this.state.email})
        if (!x.error) {
            notification.success({
                message: x.message
            })
            dispatch(hidePageLoad())

        } else {
            notification.error({
                message: x.message
            })
            dispatch(hidePageLoad())

        }
    }


    submitFinal = (e) => {
        this.handleSubmit(e)
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {loading, dispatch, visible, onClose} = this.props
        const {isUser} = this.state

        return (
            <Modal visible={visible} footer={false} onCancel={onClose} width={'35%'} title={'Login to your account'}>
                <div id={'loginForm'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mr-auto">
                                <div className="login-box w-100">
                                    {!isUser ? <div>
                                        <div className={'p-2'}>
                                            <Form onSubmit={this.submitFinal} autocomplete="off">
                                                <div className="form-group">
                                                    <Form.Item>
                                                        {getFieldDecorator('email', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please input your email address!'
                                                            }]
                                                        })(
                                                            <Input placeholder="Email address" type={'email'}
                                                                   id={'new-email'} name={'new-email'}
                                                                   autoComplete="new-email"/>
                                                        )}
                                                    </Form.Item>
                                                    <LazyLoadImage src="dist/img/user-login.png" alt=""/>
                                                </div>

                                                <div className="form-group">
                                                    <Form.Item>
                                                        {getFieldDecorator('password', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please input your Password!'
                                                            }]
                                                        })(
                                                            <Input placeholder="Password" type="password"
                                                                   id={'new-password'} name={'new-password'}
                                                                   autoComplete="new-password"/>
                                                        )}
                                                    </Form.Item>
                                                    <LazyLoadImage src="dist/img/key.png" alt=""/>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <a onClick={() => this.openForgotPassword()}>forgot password?</a>
                                                    <Button className="btn ml-auto" type="button" loading={loading}
                                                            htmlType="submit">login</Button>
                                                </div>

                                            </Form>
                                        </div>
                                    </div> : null}

                                    {isUser && <div>
                                        <h6> Welcome, {this.state.email}</h6>
                                        <div className={'p-2'}>
                                            {this.state.last2Digit ?
                                                <h6 style={{textAlign: 'center', marginBottom: 10}}> A text message with
                                                    a
                                                    4-digit verification
                                                    code has been
                                                    sent to your phone <br/> number ending in **{this.state.last2Digit}.
                                                </h6> :
                                                <h6 style={{textAlign: 'center', marginBottom: 10}}>
                                                    A text message with a 4-digit verification code has been
                                                    sent to your email.
                                                </h6>}
                                            <Form onSubmit={this.handleOtpSubmit}>
                                                <div>
                                                    <div className="form-group">

                                                        <Form.Item>
                                                            {getFieldDecorator('otp', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input your 4 digit otp!'
                                                                }]
                                                            })(
                                                                <Input
                                                                    type="password"
                                                                    minLength={4} maxLength={4}
                                                                    autoComplete="otp"
                                                                    placeholder="OTP – Check your mobile phone"
                                                                />
                                                            )}
                                                        </Form.Item>

                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <Button className="btn" type="button"
                                                                htmlType="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const WrappedLoginModal = Form.create()(LoginModal)
export default WrappedLoginModal;
