import React, {useRef, useState} from "react"
import {Card, Drawer, Icon, Popconfirm, Tooltip} from "antd";
import TableComp from "../../../components/_utils/table";
import AddAboutUniversity from "../drawers/addAboutUniversity"
import {useDispatch} from "react-redux";
import renderHTML from "react-render-html";
import { deleteAboutUniFxn, getAllUniFxn } from '../actions/university'

const SingleUniversity = (props) => {
  let {data} = props;
  let [hideV, setHideV] = useState(true);

  return (
    <>
      <div className={hideV ? 'structureDotsReplace' : ''} style={{width: "100%", textWrap: "wrap"}}>
        {data ? renderHTML(data) : ''}
      </div>
      {data && data.length > 50 ? (
        <div className={'alignRight'}>
          {hideV ? (
            <a className={'moreBtn'}
               onClick={() => {
                 setHideV(false)
               }}>
              Show More
            </a>
          ) : (
            <a className={'moreBtn'}
               onClick={() => {
                 setHideV(true)
               }}>
              Show Less
            </a>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}


const AboutUniversity = (props) => {
  let tableRef = useRef()
  let dispatch = useDispatch()
  let {countryId, universityId, visible, onClose, courseName} = props;
  let [total, setTotal] = useState(0);
  let [addVisible, setAddVisible] = useState(false)
  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params = {...params}
      let resp = await dispatch(getAllUniFxn({
        ...params, universityId,

      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const deleteAboutUniv = async (universityId) => {
    let resp = await dispatch(deleteAboutUniFxn({universityId}));
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }


  let columns = [
    {
      title: "#",
      key: "_id",
      dataIndex: "_id",
      render: (item, record, index) => {
        return index + 1;
      }
    },
    {
      title: "Content",
      key: "content",
      dataIndex: "content",
      render: (item) => {
        return (
          item ? <SingleUniversity data={item}/> : null
        )
      }
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (item, record) => {
        return (
          <>
            <Tooltip title='Delete'>
              <Popconfirm title={'Are your sure, you want to delete this university content?'}
                          onConfirm={() => {
                            deleteAboutUniv(record._id)
                          }}
                          okText='Yes' cancelText='No'>
                <button
                  className={'btn'}
                  style={{marginRight: 6}}>
                  <Icon type={'delete'}/>
                </button>
              </Popconfirm>
            </Tooltip>

          </>
        )
      }
    }
  ]

  return (
    <>
      <Drawer
        visible={visible} onClose={onClose}
        title={(
          <>
            {courseName} structure (Total : {total})
            <a className={'btn btn-primary btn-sm ml20'} onClick={() => {
              setAddVisible(true)
            }}>Add About University</a>
          </>
        )}
        width={"80%"}>
        <div className="form-box mt-4">
          <div className="card unizportal">
            <TableComp
              bordered={true}
              apiRequest={apiRequest}
              ref={tableRef}
              columns={columns}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: ['20', '50', '100'],
                position: "bottom"
              }}/>
          </div>
        </div>
      </Drawer>

      {addVisible ?
        <AddAboutUniversity
          countryId={countryId}
          universityId={universityId}
          onClose={() => {
            setAddVisible(false)
          }}
          onSubmit={() => {
            setAddVisible(false)
            tableRef.current.reload()
          }}
          visible={addVisible}/>
        : null}

    </>
  )
}
export default AboutUniversity
