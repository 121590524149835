import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { singleBookingFxn } from '../actions/payment'
import { Helmet } from 'react-helmet'
import { RowTable } from '../../components/_utils/RowTable'

const PaymentResponse = () => {
  let [isLoading, setIsLoading] = useState(false)
  let [responseObj, setResponseObj] = useState({})
  let [noOfWeek, setNoOfWeek] = useState(1)
  useEffect(() => {
    loadPayment()
  }, [])

  let loadPayment = async () => {
    let { pathname } = window.location
    let bookingId = pathname.split('/').pop()
    setIsLoading(true)
    let resp = await singleBookingFxn({ bookingId })
    if (resp && resp.success) {
      setResponseObj(resp.data)
      setIsLoading(false)
      if (resp.data && resp.data.booking_periods && resp.data.booking_periods.start_date) {
        const startDate = moment(
          resp.data.booking_periods.start_date,
          'DD-MM-YYYY'
        )
        const endDate = moment(
          resp.data.booking_periods.end_date,
          'DD-MM-YYYY'
        )
        const durationInWeeks = endDate.diff(
          startDate,
          'weeks',
          true
        )
        setNoOfWeek(parseFloat(durationInWeeks))
      }


    } else {
      // window.location.href = '/'
    }
  }

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/propertyList.css' />
      </Helmet>
      <div className={'check-box'}>
        <div className='sub-banner'>
          <div className='overlay'>
            <div className='container'>
              <div className='breadcrumb-area'>
                <h1>Uniz Home</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='properties-section-body content-area'>
          {responseObj && responseObj._id ?
            <div className='container' style={{ paddingBottom: 50 }}>
              <div className={'card custom-card'}>

                <div className={'card-body p1'}>
                  <div className={'statusBox'}>
                    {responseObj.status == 'Success' ? <>
                        <img src={'../newImages/correct.png'} />
                        <h4 className={'alert-sm success mt20'}>Payment Successful!</h4>
                      </> :
                      <>
                        <img src={'../newImages/failed.png'} />
                        <h4 className={'alert-sm danger mt20'}>Payment Failed</h4>

                        {responseObj.finalResponse && responseObj.finalResponse.message ? <>
                          <h6 className={'mt10'}>{responseObj.finalResponse.message}</h6>
                        </> : null}
                      </>}
                  </div>

                  <div className={'tableBox striped custom-table'}>
                    <RowTable title={'Student Name'} value={
                      <div className={'textCap'}>
                        {responseObj.resident_details && responseObj.resident_details.first_name ? responseObj.resident_details.first_name : ''}
                        &nbsp;  {responseObj.resident_details && responseObj.resident_details.last_name ? responseObj.resident_details.last_name : ''}
                      </div>
                    } />
                    <RowTable title={'Email'} value={
                      <div className={'textCap'}>
                        {responseObj.resident_details && responseObj.resident_details.email ? responseObj.resident_details.email : ''}
                      </div>
                    } />
                    <RowTable title={'Status'} value={
                      <div className={'capText'}>
                        {responseObj.status == 'Success' ? 'Payment Successful!' : 'Payment Failed'}
                      </div>
                    } />
                    <RowTable title={'Paid Today'} value={
                      <div className={'capText'}>
                        {responseObj.booking_periods && responseObj.booking_periods.holding_deposit ? responseObj.booking_periods.holding_deposit : ''}
                      </div>
                    } />
                    <RowTable title={'No of Weeks'} value={
                      <div className={'capText'}>
                        {noOfWeek} weeks
                      </div>
                    } />
                    <RowTable title={'Per Week'} value={
                      <div className={'capText'}>
                        {responseObj.booking_periods && responseObj.booking_periods.price_per_week && noOfWeek ? responseObj.booking_periods.price_per_week : ''}
                      </div>
                    } />
                    <RowTable title={'Transaction Date Time '} value={
                      <div className={'capText'}>
                        {responseObj.createdAt ? moment(responseObj.createdAt).format('YYYY-MM-DD hh:mm:ss A') : ''}

                      </div>
                    } />
                  </div>

                </div>

              </div>

              <div className={'alignCenter mt20'}>
                <a className={'backBtn'} href={'/'}>
                  Back to Home
                </a>
              </div>
              <br />
              <br />
              <br />
            </div> : null}
        </div>
      </div>

    </>
  )
}

export default PaymentResponse
