import { Col, DatePicker, Modal, Row, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../components/_utils/appUtils'
import {
  CountryCodeWithFlag,
  CountryJson,
  CountryList
} from '../../components/_utils/countryUtil'
import { getUser } from '../../containers/users/actions/user'
import { addEnquiryFxn, universityListFxn } from '../actions'

let initState = {
  title: '',
  firstName: '',
  lastName: '',
  nationality: '',
  email: '',
  phoneNumber: '',
  communicationMethod: '',
  dateOfBirth: '',
  university: '',
  roomDetails: '',
  additionalMessage: '',
  offerCode: '',
  dailCode: '',
  findUs: '',
  checkBox: '',
  isStudent: false
}
let prevInItState = {
  title: '',
  firstName: '',
  lastName: '',
  nationality: '',
  email: '',
  phoneNumber: '',
  communicationMethod: '',
  dateOfBirth: '',
  university: '',
  roomDetails: '',
  additionalMessage: '',
  offerCode: '',
  dailCode: '',
  findUs: '',
  checkBox: ''
}
const EnquiryModal = props => {
  const dispatch = useDispatch()
  let { visible, title, onClose, roomDetails, buildingInfo, room } = props
  const [state, setState] = useState(initState)
  const [prevState, setPrevState] = useState(prevInItState)
  const [checkBox, setCheckbox] = useState(false)
  const [university, setUniversity] = useState([])
  useEffect(() => {
    loadUniversityList()
  }, [])
  let loadUniversityList = async () => {
    let data = await universityListFxn()
    setUniversity(data.data)
  }

  useEffect(() => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    if (user && user.userType === 'student') {
      setStudentData(user._id)
    }
  }, [])

  const setStudentData = async studentId => {
    let resp = await dispatch(getUser(studentId))
    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        isStudent: true,
        firstName: resp.firstName,
        lastName: resp.lastName,
        dateOfBirth: resp.dateOfBirth ? moment(resp.dateOfBirth) : null,
        nationality: resp.nationality,
        email: resp.email,
        dailCode: resp.countryCode,
        university: resp.university,
        phoneNumber: resp.mobile
      }))
      setPrevState(prevState => ({
        ...prevState,
        isStudent: true,
        firstName: resp.firstName,
        lastName: resp.lastName,
        dateOfBirth: resp.dateOfBirth ? moment(resp.dateOfBirth) : null,
        nationality: resp.nationality,
        email: resp.email,
        dailCode: resp.countryCode,
        university: resp.university,
        phoneNumber: resp.mobile
      }))
    }, 600)
  }

  let events = {
    updateState: data => {
      setState(prevData => {
        return {
          ...prevData,
          ...data
        }
      })
    },
    submitQuery: async e => {
      e.preventDefault()

      if (!state.title) {
        notification.warning({ message: 'Please choose title.' })
        return
      }
      if (!state.firstName) {
        notification.warning({ message: 'Please enter first name.' })
        return
      }
      if (!state.lastName) {
        notification.warning({ message: 'Please enter last name.' })
        return
      }
      if (!state.nationality) {
        notification.warning({ message: 'Please choose your nationality.' })
        return
      }
      if (!state.email) {
        notification.warning({ message: 'Please enter your email.' })
        return
      }
      if (!state.dailCode) {
        notification.warning({ message: 'Please choose country code.' })
        return
      }
      if (!state.phoneNumber) {
        notification.warning({ message: 'Please enter your phone number.' })
        return
      }
      if (!state.communicationMethod) {
        notification.warning({
          message: 'Please choose your preferred communication method.'
        })
        return
      }
      if (!state.dateOfBirth) {
        notification.warning({ message: 'Please choose your date of birth.' })
        return
      }
      if (!state.university) {
        notification.warning({ message: 'Please enter your university.' })
        return
      }
      if (!state.additionalMessage) {
        notification.warning({
          message: 'Please enter your additional message.'
        })
        return
      }

      if (!state.findUs) {
        notification.warning({ message: 'Please choose your find us.' })
        return
      }
      if (checkBox == false) {
        notification.warning({ message: 'Agree to terms and conditions' })
        return
      }
      let user =
        localStorage.getItem('user') != 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null

      let roomObj = {
        allocated: room.allocated,
        area_size: room.area_size,
        availability: room.availability,
        bed_num: room.bed_num,
        build_id: room.build_id,
        deposit: room.deposit,
        deposit_value: room.deposit_value,
        deposit_weeks_number: room.deposit_weeks_number,
        flatmates_num: room.flatmates_num,
        price: room.price,
        room_currency: room.room_currency,
        room_floor: room.room_floor,
        room_id: room.room_id,
        room_name: room.room_name,
        room_num: room.room_num,
        room_offers: room.room_offers,
        room_price: room.room_price,
        room_type: room.room_type,
        shortTerm: room.shortTerm
      }

      let property = {
        ...buildingInfo,
        room: roomObj
      }
      let newStateObj = {
        ...state,
        roomDetails,
        property
      }
      if (user && user._id) {
        newStateObj.userId = user._id
      }
      let resp = await dispatch(addEnquiryFxn({ ...newStateObj }))
      if (resp && resp.success) {
        notification.success({ message: resp.message })
        setCheckbox(false)
        events.updateState({ ...initState })
        onClose()
      } else {
        notification.error({ message: resp.message })
      }
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        title={title}
        footer={false}
        onCancel={onClose}
        width={'50%'}>
        <div className='container'>
          <div className='main-title-4 enquiryForm'>
            <Row gutter={16}>
              <Col span={4}>
                <InputBox title={'Title'}>
                  <select
                    value={state.title}
                    className='form-control'
                    name='title'
                    onChange={e => {
                      events.updateState({ title: e.target.value })
                    }}>
                    <option value=''>Title</option>
                    <option value='Mr'>Mr</option>
                    <option value='Mrs'>Mrs</option>
                    <option value='Miss'>Miss</option>
                  </select>
                </InputBox>
              </Col>

              <Col span={8}>
                <InputBox title={'First Name'}>
                  <input
                    value={state.firstName}
                    type='text'
                    name='firstName'
                    disabled={prevState && prevState.firstName ? true : false}
                    className='form-control'
                    placeholder='First Name'
                    onChange={e => {
                      events.updateState({ firstName: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>

              <Col span={12}>
                <InputBox title={'Last Name'}>
                  <input
                    value={state.lastName}
                    type='text'
                    name='lastName'
                    disabled={prevState && prevState.lastName ? true : false}
                    className='form-control'
                    placeholder='Last Name'
                    onChange={e => {
                      events.updateState({ lastName: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <InputBox title={'Your Nationality'}>
                  <select
                    value={state.nationality}
                    className='form-control'
                    disabled={prevState && prevState.nationality ? true : false}
                    name='communicationMethod'
                    onChange={val => {
                      events.updateState({ nationality: val.target.value })
                    }}>
                    <option value=''>Select Nationality</option>
                    {CountryJson.map((item, key) => {
                      return (
                        <option value={item.name} key={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </InputBox>
              </Col>

              <Col span={12}>
                <InputBox title={'Email Address'}>
                  <input
                    value={state.email}
                    type='text'
                    name='email'
                    disabled={prevState && prevState.email ? true : false}
                    className='form-control'
                    placeholder='example@example.com'
                    onChange={e => {
                      events.updateState({ email: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <CountryCodeWithFlag
                  customStyle={{ marginTop: 0 }}
                  countryCode={state.dailCode}
                  disabled={prevState && prevState.dailCode ? true : false}
                  chooseCode={val => {
                    events.updateState({ dailCode: val })
                  }}
                />
              </Col>
              <Col span={8}>
                <InputBox title={'Your Phone Number'}>
                  <input
                    value={state.phoneNumber}
                    type='text'
                    name='phoneNumber'
                    className='form-control'
                    disabled={prevState && prevState.phoneNumber ? true : false}
                    placeholder='Your Phone Number'
                    onChange={e => {
                      events.updateState({ phoneNumber: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'Preferred Communication Methods'}>
                  <select
                    value={state.communicationMethod}
                    className='form-control'
                    name='communicationMethod'
                    onChange={e => {
                      events.updateState({
                        communicationMethod: e.target.value
                      })
                    }}>
                    <option value=''>Select your Preferred Methods</option>
                    <option value='Email'>Email</option>
                    <option value='Phone Call'>Phone Call</option>
                    <option value='Watsapp'>Watsapp</option>
                    <option value='Line'>Line</option>
                  </select>
                </InputBox>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <InputBox title={'Date of Birth'}>
                  <DatePicker
                    value={state.dateOfBirth}
                    type='date'
                    name='dateOfBirth'
                    disabled={prevState && prevState.dateOfBirth ? true : false}
                    className='form-control'
                    placeholder='Date of Birth'
                    onChange={e => {
                      events.updateState({ dateOfBirth: e })
                    }}
                  />
                </InputBox>
              </Col>
              {/* <Col span={12}>
                <InputBox title={'Your University'}>
                  <select
                    value={state.university}
                    className="form-control"
                    name="university"
                    onChange={e => {
                      events.updateState({ university: e.target.value })
                    }}>
                    <option value={''}>Choose University</option>
                    {university.map(item => {
                      return (
                        <>
                          <option value={item._id}>
                            {item.universityName}
                          </option>
                        </>
                      )
                    })}
                  </select>
                </InputBox>
              </Col> */}
              <Col span={12}>
                <InputBox title={'University'}>
                  <input
                    value={state.university}
                    type='text'
                    name='university'
                    className='form-control'
                    disabled={prevState && prevState.university ? true : false}
                    placeholder='University'
                    onChange={e => {
                      events.updateState({ university: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <InputBox title={'Room Details'}>
                  <div className={'roomDetails'}>
                    <div className={'flexBox'}>
                      Start: {roomDetails.start_date}
                    </div>
                    <div className={'flexBox'}>End: {roomDetails.end_date}</div>
                  </div>
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'Additional Message'}>
                  <textarea
                    value={state.additionalMessage}
                    name='additionalMessage'
                    className='form-control textareaField'
                    rows='2'
                    cols='15'
                    onChange={e => {
                      events.updateState({ additionalMessage: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <InputBox title={'Offer Code'}>
                  <input
                    value={state.offerCode}
                    type='text'
                    name='offerCode'
                    className='form-control'
                    id='inputname1'
                    placeholder='Enter Offer Code'
                    onChange={e => {
                      events.updateState({ offerCode: e.target.value })
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'How Did You Find Us?'}>
                  <select
                    value={state.findUs}
                    className='form-control'
                    name='findus'
                    onChange={e => {
                      events.updateState({ findUs: e.target.value })
                    }}>
                    <option value='' disabled selected>
                      Select Your Answer
                    </option>
                    <option value='Website'>Website</option>
                    <option value='Facebook'>Facebook</option>
                    <option value='Google'>Google</option>
                  </select>
                </InputBox>
              </Col>
              <Col span={24}>
                <div style={{ paddingLeft: 40 }}>
                  <input
                    type='checkbox'
                    value={checkBox}
                    className={'checkbox'}
                    checked={checkBox}
                    onChange={e => {
                      setCheckbox(!checkBox)
                    }}
                  />
                  <span>
                  I consent to receiving digital and telephone communications
                  from Unizportal regarding Unizportal’s services. I understand I may
                  change my preferences or opt- out of communications with
                  Unizportal at any time using the unsubscribe link provided on
                  Unizportal’s email communications.{' '}
                </span>
                </div>
              </Col>
            </Row>

            <div className='mt20'>
              <button
                onClick={events.submitQuery}
                type='submit'
                className='btn btn-primary btn-lg btn-block'>
                Send
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default EnquiryModal
