import React from 'react'
import { useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'
import { apiUrl2 } from '../../../settings'
import { useRef } from 'react'
import { Button, Col, Drawer, Form, Icon, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useEffect } from 'react'
import moment from 'moment'

import { templateListFxn, updateTemplateActiveStatusFxn } from './action/template'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { push } from 'connected-react-router'
// let initalState = ({
//     topBanner: {},
//     registrationBanner: {},
//     chiefGuestProfile:{}
// })

let initalState = {
  topBanner: '',
  registrationBanner: '',
  chiefGuestProfile: ''
}

const TemplateList = (props) => {

  const { form: { getFieldDecorator, getFieldValue }, user } = props
  const [addEventVisible, setAddEventVisible] = useState(false)
  const [editEventVisible, seteditEventVisible] = useState(false)
  const [state, setState] = useState(initalState)
  const [viewVisible, setViewVisible] = useState(false)
  const [editEventId, seteditEventId] = useState('')
  const [editEvent, seteditEvent] = useState([])
  const [galleryState, setGalleryState] = useState({
    visible: false,
    eventId: '',
    title: ''
  })
  const [leadState, setLeadState] = useState({
    visible: false,
    eventId: '',
    name: ''
  })


  const dispatch = useDispatch()
  const tableRef = useRef()

  const apiRequest = (params) => {
    // let params = {
    //   active : tur
    // }
    return new Promise(async (resolve) => {
      let {data} = await templateListFxn({
        ...params,

      });
      resolve(data);
    });
  };
  const updateActiveStatus = async (templateId) => {
    let resp = await dispatch(updateTemplateActiveStatusFxn({templateId}));
    if (resp) {
      tableRef.current.reload()
    }
  }


  useEffect(() => {
    apiRequest()
  },[])

  let editTemplate = (id) => {
    dispatch(push(`/template-view?id=${id}`))
  };
  const columns = [
    {
      title: "Country",
      dataIndex: "countryId",
      key: "countryId",
      searchText: "countryId",
      render: (item, record) => {
        return item && item.countryName ? item.countryName : null;
      },
    },
    {
      title: "University Name",
      dataIndex: "universityId",
      key: "universityId",
      render: (item, record) => {
        return item && item.universityName ? item.universityName : null;
      },
    },
    {
      title: "Course Name",
      dataIndex: "courseId",
      key: "courseId",
      render: (item, record) => {
        return item && item.courseName ? item.courseName : null;
      },
    },
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
      searchTextName: "templateName",
      filterRegex: true,
    },
    {
      title: "Sections",
      dataIndex: "sections",
      key: "sections",
      render: (item) => {
        return item && item.length ? (
          <a className={"btn btn-info btn-xs"}>{item.length}</a>
        ) : null;
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (item, record) => {
        return (
          <>
            <Popconfirm
              title={'Are your sure, you want to update status?'}
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                updateActiveStatus(record._id)
              }}
            >
              {item ?
                <span className="active">Active</span>
                : <span className="inactive">Un-Active</span>
              }
            </Popconfirm>
          </>
        )
      },
    },

    {
      title: "Action",
      dataIndex: "sections",
      key: "sections",
      render: (val, record) => {
        let {originalDocument} = record;

        return (
        <div className={'btn_group'}>
          <a className={"btn btn-primary btn-xs"} onClick={() => {
            editTemplate(record._id)
          }}>
            View Info
          </a>

          <a className={"btn btn-default btn-xs"}
             href={`${apiUrl2}/template/${record._id}`}>
            Preview
          </a>
          {originalDocument  ?
            <a className={"btn btn-default btn-xs"} href={originalDocument.path} target={'_blank'}>
              Docs
            </a> : null}
        </div>
        );
      },
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Template List: </h5>
              <CheckMyRights rightList={['admin', 'userManager']}>
                <div className='sort-box-table mark-btn row mark-btn70'>
                  <div>
                    <a className='btn' onClick={() => dispatch(push('/add-template'))}>
                      <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Template</a>
                  </div>

                </div>
              </CheckMyRights>


            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}



              />
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Form.create()(TemplateList)
