import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Form, Modal, Input, Checkbox, Row, Col, Icon, notification} from 'antd';
import {
    createTransactionFxn,
    singleQuotationFxn,
    getFinalPaymentChangesFxn,
    createPaymentFxn,
    paymentInstructionFxn
} from "../actions";
import './styles.css';
import UnizLogo from '../../../assets/Unizportal-Logo.png';
import {displayDate} from "../../../components/_utils/appUtils";

const TransactionDetailsModal = (props) => {
    const {
        onClose,
        visible,
        form: {getFieldDecorator, setFieldsValue},
        quotationId
    } = props;

    let [quotationData, setQuotationData] = useState(null);
    let [isCounselorBonusChecked, setIsCounselorBonusChecked] = useState(false);
    const [chargeComponent, setChargeComponent] = useState({})
    let [counselorName, setCounselorName] = useState('');
    let [counselorDesignation, setCounselorDesignation] = useState('');
    let dispatch = useDispatch();
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null

    useEffect(() => {
        if (quotationId) {
            events.apiRequest();
        }
    }, [quotationId]);

    const events = {

        apiRequest: () => {
            return new Promise(async (resolve) => {
                let resp = await dispatch(singleQuotationFxn({quotationId}));
                const response = resp.quotation;
                console.log(response, "resppppp");

                if (response) {
                    setQuotationData(response);

                    setFieldsValue({
                        currency: response.fromCurrency,
                        amount: response.amount,
                        fxRate: response.fxRate,
                        name: response.name,
                        emailAddress: response.emailId,
                        phoneNumber: response.phoneNo
                    });
                }
                resolve({data: response});
            });
        },
    };


    const handleSubmit = async e => {
        e.preventDefault();
        const { form } = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                const transactionData = {
                    ...valData,
                    name: quotationData.name,
                    emailAddress: quotationData.emailId,
                    phoneNumber: quotationData.phoneNo,
                    quotationId: quotationData.quotationId,
                    fxRate: quotationData.fxRate,
                    amount: quotationData.amount,
                    currency: quotationData.fromCurrency,
                    sourceId: quotationData.sourceId,
                    agentId: user._id,
                    counselorBonusApplied: isCounselorBonusChecked
                };

                if (isCounselorBonusChecked) {
                    transactionData.counselorName = counselorName;
                    transactionData.counselorDesignation = counselorDesignation;
                }

                let resp = await dispatch(createTransactionFxn(transactionData));

                if (resp.success && resp.data) {
                    notification.success({
                        message: 'Transaction Created Successfully'
                    });

                    const finalChangesObj = {
                        currency: quotationData.fromCurrency,
                        amount: quotationData.amount,
                        fxRate: quotationData.fxRate,
                        ibrRate: quotationData.ibrRate,
                        appId: resp.data
                    };
                    const appId = resp.data;
                    await getFinalChangesFxn(finalChangesObj, appId);

                    form.resetFields();
                    onClose();
                } else {
                    notification.error({
                        message: resp.message
                    });
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                });
            }
        });
    };


    const getFinalChangesFxn = async (obj, appId) => {
        console.log(appId, "apppp")
        const resp = await dispatch(getFinalPaymentChangesFxn(obj));
        if (resp.success) {
            setChargeComponent(resp.data.charges);
            confirmPayment(appId, resp.data.charges)
            console.log(resp, "Final Charges Data");
        }

    }

    const confirmPayment = async (appId, chargeComponent) => {
        console.log(appId, "aoooo")
        let resp = await dispatch(createPaymentFxn(appId, {chargeComponent: chargeComponent}))
        console.log(resp, "resspp")
        if (resp.success) {
            notification.success({message:  resp.message})
        } else {
            notification.error({message: resp.message})
        }
        downloadPayment(appId)
    }

    const downloadPayment = async (appId) => {
        let resp = await dispatch(paymentInstructionFxn({applicationId: appId}))
        console.log(resp, "diiii")
        if (resp) {
            // window.open(resp.file.path, '_blank');
        } else {
            notification.error({ message: "Failed to retrieve payment instruction." });
        }
    }


    const handleCheckboxChange = (e) => {
        setIsCounselorBonusChecked(e.target.checked);
    };


    return (
        <Modal
            visible={visible}
            title={"Book Transaction Details"}
            onCancel={onClose}
            width={"45%"}
            footer={null}
        >
            {quotationData && (
                <>
                    <div className="unizportal">
                        <div className={'jcsb'}>
                            <div>
                                <img src={UnizLogo} alt={'logo'} style={{height: 50}}/>
                            </div>
                            <div>
                                <div>Date : <b>{displayDate(quotationData.createdAt)}</b></div>
                                <div>Quotation Id : <b>#{quotationData.quotationId}</b></div>
                            </div>
                        </div>
                        <div className={'description-section mt10'}>
                            <table className="table table-bordered">
                                <tr>
                                    <th colSpan={2}>Description</th>
                                </tr>
                                <tbody>
                                <tr>
                                    <td>Currency Type</td>
                                    <td>{quotationData.fromCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Foreign Currency Amount</td>
                                    <td>{quotationData.amount}</td>
                                </tr>
                                <tr>
                                    <td>Rate</td>
                                    <td>{quotationData.fxRate}</td>
                                </tr>
                                <tr>
                                    <td>Gross Amount</td>
                                    <td>{quotationData.grossAmount}</td>
                                </tr>
                                <tr>
                                    <td>GST 18% (Foreign Conversion)</td>
                                    <td>{quotationData.gst}</td>
                                </tr>
                                <tr>
                                    <td>Bank Processing Fee</td>
                                    <td>{quotationData.bankFees}</td>
                                </tr>
                                <tr>
                                    <td>Nostro Charges</td>
                                    <td>{quotationData.nostroCharge}</td>
                                </tr>
                                <tr>
                                    <td>TCS Charges</td>
                                    <td>{quotationData.tcsCharge}</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td className="total">{quotationData.totalAmount}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr className={'hr1'}/>
                    <div className=" row">
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Contact Details</b></div>
                            <div>{quotationData.agentId.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotationData.agentId.mobile}
                                </div>
                                {quotationData.agentId.mobile && quotationData.agentId.email ?
                                    <div>&nbsp; | &nbsp;</div> : null}
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotationData.agentId.email}
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Student Details</b></div>
                            <div>{quotationData.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotationData.phoneNo} </div>
                                &nbsp; | &nbsp;
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotationData.emailId}</div>
                            </div>
                        </div>

                    </div>
                    <hr className={'hr1'}/>
                    <div>
                        <Checkbox onChange={handleCheckboxChange}>
                            Do you want to add counsellor bonus?
                        </Checkbox>
                        {isCounselorBonusChecked && (
                            <Form layout="vertical" className={'mt20'}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Counselor Name">
                                            <Input
                                                value={counselorName}
                                                className={'form-control'}
                                                onChange={(e) => setCounselorName(e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Counselor Designation">
                                            <Input
                                                value={counselorDesignation}
                                                className={'form-control'}
                                                onChange={(e) => setCounselorDesignation(e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </div>
                    <div>
                        <Button htmlType="submit" className={"roundBtn"} onClick={handleSubmit}>
                            Confirm & Book Transaction
                        </Button> &nbsp;
                        <Button className={"roundBtn"}>
                            Skip
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default Form.create()(TransactionDetailsModal);
