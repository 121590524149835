import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Pagination,
  Row,
  Slider,
  Typography
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  LazyLoadImage as Image,
  LazyLoadImage
} from 'react-lazy-load-image-component'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { roomsListFxn } from '../actions'

const $ = window.$
const { Text } = Typography
const marks = {
  0: '£0',
  200: '£200',
  400: '£400',
  600: '£600',
  800: '£800',
  1000: '£1000'
}

const checkedImg = require('../../localImage/checked.png')
const unCheckedImg = require('../../localImage/unchecked.png')
const { MonthPicker } = DatePicker

const SingleBuilding = ({ item }) => {
  const maxFacilities = 4
  const toggleShowAll = () => setShowAll(!showAll)
  const [showAll, setShowAll] = useState(false)
  let [amenities, setAmenities] = useState([])
  let openPage = () => {
    let roomUrl = item.id.split('/').pop()
    window.open(`/accommodation/${roomUrl}`, '_blank')
  }

  let loadAmenities = () => {
    let findD = _.filter(item.amenities.general, eachAm => {
      return eachAm.status
    })
    if (findD && findD.length) {
      setAmenities(findD)
    }
  }
  useEffect(
    () => {
      loadAmenities()
    },
    [item.amenities]
  )
  return (
    <div
      className='property row g-0 fp2 clearfix wow fadeInUp delay-03s'
      key={item.id.toString()}>
      <div className='col-lg-5 col-md-5 col-sm-12' style={{ padding: 0 }}>
        <div className='property-img'>
          <div className='property-price'>
            {' '}
            {item.price_range && item.price_range.min ? (
              <p>{`${item.price_range.min}/week`}</p>
            ) : (
              <p>Sold out</p>
            )}
          </div>
          <LazyLoadImage
            src={item.images[0].medium}
            alt='fp-list'
            className='img-responsive hp-1'
          />
          <div className='property-overlay'>
            <a
              onClick={() => {
                openPage()
              }}
              className='overlay-link'>
              <i className='fa fa-link' />
            </a>
          </div>
        </div>
      </div>
      <div className='col-lg-7 col-md-7 col-sm-12 property-content'>
        <div className='info' style={{ paddingTop: 5 }}>
          <h1 className='title'>
            <a
              onClick={() => {
                openPage()
              }}>
              {item.name}
            </a>
          </h1>
          <h3 className='property-address'>
            <a
              onClick={() => {
                openPage()
              }}>
              <i className='fa fa-map-marker' style={{ marginRight: 5 }} />
              {item.address.first_line} {item.address.city}{' '}
              {item.address.country}
            </a>
          </h3>
          <ul className='facilities-list facilities-list2 clearfix'>
            {amenities && amenities.length
              ? amenities
                .slice(0, showAll ? amenities.length : maxFacilities)
                .map((eachFac, key) => {
                  return (
                    <li key={key} style={{ marginTop: 2 }}>
                      <span style={{ fontSize: 12.8 }}>{eachFac.name}</span>
                    </li>
                  )
                })
              : null}
          </ul>
          <br />

          <div className={'alignRight'}>
            {amenities && amenities.length > maxFacilities ? (
              <a onClick={toggleShowAll} className={'showMoreBtn'}>
                {showAll
                  ? 'Less...'
                  : `+${amenities.length - maxFacilities} more...`}
              </a>
            ) : null}
          </div>

          <p className='circle'>Free cancellation &nbsp; Offers available</p>

          {/* <span style={{marginTop: 11, fontSize: 16}} className="fa fa-map-marker">
            {item.distance}
          </span> */}
        </div>
        <div className='property-footer jcsb'>
          <span className='left'>
            <strong>
              {/* <i className="fa fa-user" /> */}
              {item.price_range && item.price_range.min ? (
                <p>{`${item.price_range.min}/week`}</p>
              ) : (
                ''
              )}
            </strong>
          </span>
          <span className='right'>
            {/* <a>{item.rating} Rating</a> */}
            <Button
              type='primary'
              onClick={() => {
                openPage()
              }}>
              View Room
            </Button>
          </span>
        </div>
      </div>
    </div>
  )
}
const CheckboxGroup = props => {
  let { options, name, filterName, handleChange, data } = props

  const maxCheckBoxes = 6
  const toggleShowAll = () => setShowAll(!showAll)
  const [showAll, setShowAll] = useState(false)

  let checkIsExists = value => {
    let find = _.find(data, item => {
      return item == value
    })
    return find ? find : false
  }

  let checkIsExistsNew = value => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][value] === true) {
        return true
      }
    }
    return false
  }
  const handleCheckboxChange = value => {
    handleChange(name, value, options)
  }

  return (
    <div className={'custom-checkbox'}>
      <h6 className={'mt20'}>{filterName}</h6>

      <ul key={JSON.stringify(data)} style={{ paddingLeft: 0, marginLeft: 0 }}>
        {options && options.length
          ? options
            .slice(0, showAll ? options.length : maxCheckBoxes)
            .map((item, key) => {
              return (
                <li key={item.label}>
                  <a
                    onClick={() => {
                      handleCheckboxChange(item.value)
                    }}>
                      <span className={'checkbox'}>
                        {checkIsExists(item.value) ||
                        checkIsExistsNew(item.value) ? (
                          <Image src={checkedImg} height={25} />
                        ) : (
                          <Image src={unCheckedImg} height={25} />
                        )}
                      </span>
                    &nbsp; {item.label}
                  </a>
                </li>
              )
            })
          : null}
      </ul>
      <div className={'alignRight'}>
        {options && options.length > maxCheckBoxes ? (
          <a onClick={toggleShowAll} className={'showMoreBtn'}>
            {showAll ? 'Less...' : `+${options.length - maxCheckBoxes} more...`}
          </a>
        ) : null}
      </div>
    </div>
  )
}

const RoomsList = props => {
  let dispatch = useDispatch()
  const history = useHistory()

  let [filterData, setFilterData] = useState({})
  const [city, setCityinfo] = useState('')
  let [building, setBuilding] = useState([])
  let [data, setData] = useState([])
  let [pagination, setPagination] = useState({
    count: 20,
    per_page: 20,
    current_page: 1,
    total_pages: 1,
    total: 0
  })
  let [page, setPage] = useState(1)
  const [sliderValues, setSliderValues] = useState({ min: 0, max: 1000 })
  const [moveInDate, setMoveInDate] = useState(null)
  const dateFormat = 'MM/YYYY'
  const disabledDate = current => {
    return current && current.valueOf()
  }
  const handleDateChange = value => {
    setMoveInDate(value)
  }
  let loadRoomList = async () => {
    const pathname = window.location.pathname
    let city = pathname.split('/').pop()
    if (city) {
      setCityinfo(city)
    }
    let resp = getQueryFilter()

    try {

      let { data } = await dispatch(roomsListFxn({
        city,
        page,
        ...resp,
        per_page: 20
      }))

      setPagination((prevData) => {
        return {
          ...prevData,
          ...data.meta
        }
      })

      setData(data.items)
    } catch (e) {
      setData([])
    }

  }

  useEffect(() => {
    updateFilterCheckboxState()
    const searchParams = new URLSearchParams(window.location.search)
    const amenitiesFilter = searchParams.getAll('amenities')

    if (amenitiesFilter.length > 0) {
      const amenities = amenitiesFilter.flatMap(amenity => amenity.split(','))
      setFilterCheckboxState({ facilities: amenities })
    }
    const moveInDateParam = searchParams.get('date')

    if (moveInDateParam) {
      const parsedDate = moment(moveInDateParam, 'MM/YYYY')
      if (parsedDate.isValid()) {
        setMoveInDate(parsedDate.toDate())
      }
    }

    const minPriceParam = searchParams.get('min_price')
    const maxPriceParam = searchParams.get('max_price')
    if (minPriceParam || maxPriceParam) {
      setSliderValues({
        min: minPriceParam ? parseInt(minPriceParam, 10) : 0,
        max: maxPriceParam ? parseInt(maxPriceParam, 10) : 1000
      })
    }
  }, [])

  const updateFilterCheckboxState = () => {
    setFilterCheckboxState(prevState => {
      return {
        ...prevState,
        accommodationType: filterData.provider_type_id
          ? filterData.provider_type_id
          : [],
        roomType: filterData['room-types']
          ? filterData['room-types'].split('|')
          : [],
        facilities: filterData.facilities
          ? filterData['facilities'].split('|')
          : []
      }
    })
  }
  const [filterCheckboxState, setFilterCheckboxState] = useState({
    facilities: [],
    accommodationType: [],
    roomType: [],
    cancellationPolicy: []
  })

  const handleTypeChange = (name, value) => {
    let oldData = filterCheckboxState[name]
    if (oldData.includes(value)) {
      oldData = _.reject(oldData, item => {
        return item == value
      })
    } else {
      oldData.push(value)
    }
    setFilterCheckboxState(prevData => {
      return {
        ...prevData,
        [name]: oldData
      }
    })
  }

  const Facilities = [
    { value: 'DBD', label: 'Double Bed' },
    { value: 'DSK', label: 'Desk & Chair' },
    { value: 'MRW', label: 'Microwave' },
    { value: 'PIN', label: 'Security Access' },
    { value: 'PBA', label: 'Private Bathroom' },
    { value: 'WIF', label: 'Wifi' }
  ]

  const handleClearFilters = () => {
    setMoveInDate(null)
    setSliderValues({ min: 0, max: 1000 })
    setFilterCheckboxState({ facilities: [] })
    history.push(`/rooms-list/${city}`)
    setTimeout(() => {
      loadRoomList()
      scriptFxn.closeMenu()
    }, 200)
    // dispatch(getUrlPushWrapperHome('RoomsList', { city: city }))
  }

  const handleApplyFilters = () => {
    const queryParams = {}

    if (sliderValues.min !== 0 || sliderValues.max !== 1000) {
      queryParams.min_price = sliderValues.min
      queryParams.max_price = sliderValues.max
    }

    if (filterCheckboxState.facilities.length > 0) {
      queryParams.amenities = filterCheckboxState.facilities.map(amenity =>
        encodeURIComponent(amenity)
      )
    }

    if (moveInDate) {
      queryParams['date'] = moment(moveInDate).format('MM-YYYY')
    }
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')
    history.push(`/rooms-list/${city}?${queryString}`)
    setTimeout(() => {
      loadRoomList()
      scriptFxn.closeMenu()
    }, 200)
  }

  const getQueryFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const obj = {}

    const amenitiesFilter = searchParams.getAll('amenities')
    if (amenitiesFilter.length > 0) {
      obj.amenities = amenitiesFilter.flatMap(amenity => amenity.split(','))
    }

    const dateFilter = searchParams.get('date')
    if (dateFilter) {
      obj.date = dateFilter
    }

    const minPriceFilter = searchParams.get('min_price')
    if (minPriceFilter) {
      obj.minPrice = parseInt(minPriceFilter, 10)
    }

    const maxPriceFilter = searchParams.get('max_price')
    if (maxPriceFilter) {
      obj.maxPrice = parseInt(maxPriceFilter, 10)
    }

    return obj
  }

  useEffect(
    () => {
      loadRoomList()
    },
    [page]
  )
  const handleSliderChange = values => {
    setSliderValues({ min: values[0], max: values[1] })
  }
  const handleChange = data => {
    setSliderValues(prevValues => {
      return {
        ...prevValues,
        ...data
      }
    })
  }
  const handleMinInputChange = value => {
    const newMinValue = value > sliderValues.max ? sliderValues.max : value
    setSliderValues({ ...sliderValues, min: newMinValue })
  }

  const handleMaxInputChange = value => {
    const newMaxValue = value > 1000 ? 1000 : value
    setSliderValues({ ...sliderValues, max: newMaxValue })
  }
  const step = 1

  const scriptFxn = {
    openMenu: () => {
      $('.mobile_menu').addClass('open')
    },
    closeMenu: () => {
      $('.mobile_menu').removeClass('open')
    }
  }

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/propertyList.css' />
      </Helmet>
      <div className='sub-banner'>
        <div className='overlay'>
          <div className='container'>
            <div className='breadcrumb-area'>
              <h1>Rooms Listing</h1>
              <ul className='breadcrumbs'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='active'>Student Accommodation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className='properties-section-body content-area'
        style={{ paddingTop: 40 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12 col-xs-12'>
              <div className='option-bar'>
                <div className={'justifyContent'}>
                  <h4>
                    <span className='title'>Student Accommodation</span>
                  </h4>
                  <div onClick={scriptFxn.openMenu} className={'menu_btn'}>
                    <a className={'btn btn-link'}>
                      <i className='fa fa-filter' /> Filters
                    </a>
                  </div>
                </div>
              </div>
              <div key={data}>
                {data && data.length
                  ? data.map((item, key) => {
                    return <SingleBuilding item={item} />
                  })
                  : null}
              </div>

              {pagination && pagination.total ? (
                <Pagination
                  defaultCurrent={pagination.current_page}
                  defaultPageSize={pagination.count}
                  onChange={page => {
                    setPage(page)
                  }}
                  total={pagination.total}
                />
              ) : null}
            </div>
            <div className='col-lg-4 col-md-12 col-xs-12 mobile_menu'>
              <div className='sidebar-widget advanced-search'>
                <form method='GET'>
                  <div className='form-group'>
                    <div className={'close_btn'} onClick={scriptFxn.closeMenu}>
                      <img src='../dist/img/cross.svg' style={{ height: 20 }} />
                    </div>
                    <div className='main-title-4'>
                      <h6>Select price</h6>
                    </div>

                    <Slider
                      className='custom-slider'
                      range
                      marks={marks}
                      step={step}
                      min={0}
                      max={1000}
                      value={[sliderValues.min, sliderValues.max]}
                      onChange={handleSliderChange}
                    />
                    <Row gutter={16} className={'mt20'}>
                      <Col span={12}>
                        <Text style={{ display: 'block' }}>Min per week</Text>
                        <InputNumber
                          min={0}
                          max={sliderValues.max}
                          value={sliderValues.min}
                          onChange={handleMinInputChange}
                        />
                      </Col>
                      <Col span={12}>
                        <Text style={{ display: 'block' }}>Max per week</Text>
                        <InputNumber
                          min={sliderValues.min}
                          max={1000}
                          value={sliderValues.max}
                          onChange={handleMaxInputChange}
                        />
                      </Col>
                      <Col span={24}>
                        <h6 className={'mt20'}> Move-In Date</h6>
                        <MonthPicker
                          value={moveInDate ? moment(moveInDate) : null}
                          format={dateFormat}
                          // disabledDate={disabledDate}
                          placeholder='Select month'
                          // mode={['month', 'year']}
                          onChange={handleDateChange}
                        />
                      </Col>
                      <Col span={24}>
                        <CheckboxGroup
                          options={Facilities}
                          name={'facilities'}
                          filterName={'Select features'}
                          data={filterCheckboxState.facilities}
                          setFilterCheckboxState={setFilterCheckboxState}
                          handleChange={handleTypeChange}
                        />
                      </Col>
                      <Col span={24}>
                        <Row gutter={16} style={{ marginTop: '10px' }}>
                          <Col span={6}>
                            <Button onClick={handleClearFilters}>Clear</Button>
                          </Col>
                          <Col span={6}>
                            <Button type='primary' onClick={handleApplyFilters}>
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RoomsList
