import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'

const PhoneValidationCheck = () => {
  let [phoneNo, setPhoneNo] = useState('')
  let submitFxn = () => {
    console.log(isValidPhoneNumber(phoneNo))
    let dateB = '10-11-1991'
    console.log(moment(dateB, 'DD-MM-YYYY', true).isValid())
    if (isValidPhoneNumber(phoneNo)) {

    }

  }
  return (
    <>
      <div className={'check-box'}>
        <div className='sub-banner'>
          <div className='overlay'>
            <div className='container'>
              <div className='breadcrumb-area'>
                <h1>Properties Listing</h1>
                <ul className='breadcrumbs'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li className='active'>Student Accommodation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='properties-section-body content-area'>
          <div className='container'>
            <PhoneInput
              placeholder='Enter phone number'
              value={phoneNo}
              onChange={(val) => {
                console.log(val)
                setPhoneNo(val)
              }} />
            <br />
            <a className={'btn btn-success'} onClick={submitFxn}>
              Submit
            </a>

          </div>

        </div>
      </div>
    </>
  )
}
export default PhoneValidationCheck
