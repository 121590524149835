import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Card} from "antd";
import { updateTemplateSectionFxn } from '../action/template'
import GetEachFormFields from '../../../../components/_utils/appFormUtils'
import moment from "moment";
import {useDispatch} from "react-redux";

const TemplateSectionEdit = (props) => {
  let dispatch = useDispatch()
  let {visible, onClose, sectionData} = props;
  const {getFieldDecorator} = props.form;
  let [editorKey, setEditorKey] = useState(moment())


  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.templateSectionId = sectionData._id;
        let x = await dispatch(updateTemplateSectionFxn(valData));
        if(onClose){
          onClose()
        }
        props.form.setFieldsValue({
          title: "",
          content: "",

        });
      }
    });
  };


  const getRelatedState = () => {
    setTimeout(() => {
      props.form.setFieldsValue({
        title: sectionData.title,
        content: sectionData.content
      });
      setEditorKey(moment())

    }, 500)
  }
  useEffect(() => {
    getRelatedState()
  }, [sectionData]);

  let inputTypes = {
    fields: [
      {
        key: "title",
        label: "Section Title",
        // required: true,
        placeholder: "Section Title",
        type: "text",

        span: 8,
      },

      {
        key: "content",
        label: "Section Content",
        type: "ckeditor",
        required: true,
        editorKey: editorKey,
        onChange: (x) => {
          props.form.setFieldsValue({
            content: x,
          });
        },
      },
    ],
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width="50%" title={'Edit Section'}>
      <Form onSubmit={handleSubmit}>
        <div className='card unizportal'>
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return (
              <div className={"col-md-12 pt-3"} key={key}>
                <GetEachFormFields
                  {...props.form}
                  item={item}
                  formItemLayout={formItemLayout}
                />
              </div>
            );
          })}
          <Form.Item>
            <Button type='primary' htmlType='submit'  className={'btn'}>
              Submit
            </Button>
          </Form.Item>
        </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default Form.create()(TemplateSectionEdit);
