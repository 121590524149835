import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Drawer,
    Card,
    Form,
    Table,
    notification,
    Col,
    Row,
    Modal
} from 'antd';
import _ from 'lodash';
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import {createTransactionFxn, singleQuotationFxn} from "../actions";

const AddTxnStudent = (props) => {
    const {
        form: {getFieldDecorator, setFieldsValue},
        onClose,
        visible,
        onSubmit,
        currencyList,
        quotationId,
        referenceUserList
    } = props;

    let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({});
    let [quotationData, setQuotationData] = useState(null);
    let dispatch = useDispatch();
    let tableRef = useRef();
    useEffect(() => {
        events.apiRequest();
        events.setDefaultValue()
    }, []);

    const events = {
        setDefaultValue: () => {
            setTimeout(() => {
                setFieldsValue({referencedBy: "NA"})
            }, 500)
        },
        chooseCurrency: (currency) => {
            let findDoc = _.find(currencyList, (item) => {
                return item.currency == currency;
            });
            if (findDoc) {
                setSelectedCurrencyObj(findDoc);
                console.log(selectedCurrencyObj, "00000")
                // setTimeout(() => {
                //     setFieldsValue({
                //         customerRate: findDoc.yourRate ? parseFloat(findDoc.yourRate) : "",
                //     });
                // }, 100);
            }
        },
        apiRequest: () => {
            return new Promise(async (resolve) => {
                let resp = await dispatch(singleQuotationFxn({quotationId}));
                const response = resp.quotation;
                console.log(response, "resppppp");

                if (response) {
                    setQuotationData(response);

                    setFieldsValue({
                        currency: response.fromCurrency,
                        amount: response.amount,
                        fxRate: response.fxRate,
                    });
                }
                resolve({data: response});
            });
        },
    };


    const inputTypes = {
        fields: [
            {
                key: "studentName",
                label: "Student Name",
                required: true,
                placeholder: "Student Name",
                type: "text",
            },
            {
                key: "phoneNumber",
                label: "Student Mobile Number",
                required: true,
                placeholder: "Student Mobile Number",
                type: "text",
            },
            {
                key: "emailAddress",
                label: "Student Email Id",
                required: true,
                placeholder: "Student Email Id",
                type: "text",
            },
            {
                key: "panNumber",
                label: "Pan Number",
                // required: true,
                placeholder: "Pan Number",
                type: "text",
            },
            {
                key: 'referencedBy',
                label: 'Reference By',
                placeholder: 'Reference By', type: 'select',
                options: referenceUserList,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    console.log(x);
                    setFieldsValue({referencedBy: x})
                }
            },
        ],
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    return (
        <Modal
            visible={visible}
            title={"Add Quotation"}
            onCancel={onClose}
            width={"35%"}
            footer={null}
        >
            <>
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={24}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24}
                                         xs={24}
                                         key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            className={'form-control'}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                                );
                            })}
                        </Row>
                        <div>
                            <Button htmlType="submit" className={"roundBtn"}>
                                Submit
                            </Button> &nbsp;

                        </div>

                    </Form>
                </div>
            </>
        </Modal>
    );
};

export default Form.create()(AddTxnStudent);
