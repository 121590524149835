import { customAxios as axios, getToken } from '../../request'

import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { getTokenn } from '../../settings'
import {
  accommodationListUrl,
  addEnquiryUrl,
  cityListUrl,
  finalCheckOutUrl,
  getBookingPeriodsUrl,
  getRoomDataUrl,
  propertiesListUrl,
  roomsListUrl,
  searchDataUrl,
  singleCountryUrl,
  singleRoomBookingPeriodUrl,
  singleRoomListUrl,
  singleRoomUrl,
  startCheckOutUrl,
  submitOnNoGuaranterUrl,
  universityListUrl
} from '../apis'

export const getHeader = () => {
  return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Credentials': true,
      'X-Requested-With': 'XMLHttpRequest',
      crossdomain: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
  }
}

export const singleCountryFxn = async () => {
  let resp = await axios.get(singleCountryUrl())
  return resp
}
export const propertiesListFxn = async valData => {
  let config = {
    params: { ...valData },
    ...(await getTokenn())
  }
  let { data } = await axios.get(propertiesListUrl(valData), config)
  return data
}
export const accomodationListFxn = async valData => {
  let config = {
    params: { ...valData },
    ...(await getTokenn())
  }
  let { data } = await axios.get(accommodationListUrl(valData), config)

  return data
}
export const universityListFxn = async () => {
  let data = await axios.get(universityListUrl())
  return data.data
}
export const addEnquiryFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addEnquiryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const checkOutFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addEnquiryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const searchDataFxn = async () => {
  let resp = await axios.get(searchDataUrl())
  return resp
}
export const singleRoomBookingPeriodFxn = async roomId => {
  let config = {
    params: {},
    ...(await getTokenn())
  }
  let { data } = await axios.get(singleRoomBookingPeriodUrl(roomId), config)
  return data
}

export const startCheckOutFxn = async valData => {
  console.log(valData)
  let url = startCheckOutUrl(valData.roomId)
  console.log(url)
  let { data } = await axios.post(url, valData, getTokenn())
  console.log('-------------------session')
  console.log('-------------------', data)
  console.log('-------------------session')
  return data
}
export const submitOnNoGuaranterFxn = async valData => {
  let { data } = await axios.post(
    submitOnNoGuaranterUrl(),
    valData,
    getTokenn()
  )
  return data
}
export const finalCheckOutFxnOld = async valData => {
  let { data } = await axios.post(
    finalCheckOutUrl(valData.roomId),
    valData,
    getTokenn()
  )
  return data
}
export let finalCheckOutFxn = async (valData, roomId) => {
  let postUrl = finalCheckOutUrl(roomId)
  try {
    let { data } = await axios.post(postUrl, { ...valData }, getTokenn())
    return data
  } catch (e) {
    return e
  }
}
export const roomsListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: {},
    ...(await getTokenn())
  }
  console.log(roomsListUrl(valData), 'roomsListUrl(valData)')
  let { data } = await axios.get(roomsListUrl(valData), config)
  dispatch(hidePageLoad())
  return data
}
export const singleRoomFxn = async valData => {
  let config = {
    params: { ...valData },
    ...(await getTokenn())
  }
  let { data } = await axios.get(singleRoomUrl(valData), config)

  return data
}

export const searchCityListFxn = async valData => {
  let config = {
    params: {},
    ...(await getTokenn())
  }
  let { data } = await axios.get(cityListUrl(), config)
  return data
}

export const getRoomData = async roomId => {
  let { data } = await axios.get(getRoomDataUrl(roomId), getToken())
  return data
}
