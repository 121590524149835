import React, {useEffect} from 'react'
import {Helmet} from 'react-helmet'
import {Opportunity} from './opportunityComponent'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const $ = window.$

const Gallery = (props) => {
    const filterSelectionInner = (value) => {
        window.filterSelectionFxn(value)
    }

    return (
        <div>
            <Helmet>
                <script src="../assets/js/gallery.js" type="text/javascript"></script>
            </Helmet>
            <section className="banner blog-banner gallery-banner">

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="content text-left">
                                <h1>Life Stories</h1>
                                <p>Take a virtual tour down our picture gallery which gives the most vivid account of
                                    our success
                                    narrative and affords you a detailed view of our engagements with students and our
                                    education and
                                    immigration partners. </p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="img">
                                <LazyLoadImage src="/assets/image/gallery-banner.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="launch-line">
                <div className="container">

                    <p>Navigate through the snapshots and videos capturing the highlights of <br/>our regular education
                        and
                        immigration seminars; student testimonials narrating <br/>their success stories and why they
                        find our
                        assistance and guidance services valuable; <br/>our immigration partners expressing how
                        connecting with us
                        has been lucrative<br/> and, much more. Contact us and be a part of our roll of honours!</p>

                </div>
            </section>

            <section className="gallery-box">

                <div className="container">
                    <div id="myBtnContainer">
                        {/*  <button className="btn active" onClick={() => filterSelectionInner('seminar')}> Success
                            Stories
                        </button>*/}
                        <button className="btn active" onClick={() => filterSelectionInner('uwtsd')}> UWTSD Agent's Meet
                        </button>
                        <button className="btn" onClick={() => filterSelectionInner('campus')}> Webinars</button>
                        {/* <button className="btn" onClick={() => filterSelectionInner('teams')}> Our Team</button>
                        <button className="btn" onClick={() => filterSelectionInner('clients')}> Our Partners</button>*/}
                    </div>

                    <div className="row">

                        <div id="gallery">

                            {/*   <LazyLoadImage src="../gallery/successStories/july/1.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/2.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/3.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/4.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/5.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/6.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/7.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/8.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/9.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/10.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/11.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/12.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/13.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/14.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/15.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/july/17.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>



                            <LazyLoadImage src="../gallery/successStories/2.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/3.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/4.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/5.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/6.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/7.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/8.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/9.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/10.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/11.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/12.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/13.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/14.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/15.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/16.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/17.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/18.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/19.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/20.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/21.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/22.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/23.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/24.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/25.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/26.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/27.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/28.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/29.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/30.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/31.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/1.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/2.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/3.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/4.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/5.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/6.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/7.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/8.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/9.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/10.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/11.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/12.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/13.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/14.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>
                            <LazyLoadImage src="../gallery/successStories/apr/15.jpeg" alt="seminar"
                                           className="column seminar img-responsive"/>*/}


                            <LazyLoadImage src="../gallery/uwtsd/u1.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u2.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u3.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u4.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u5.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u6.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u7.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u8.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u9.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u10.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u11.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u12.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u13.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u14.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u15.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u16.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u17.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u18.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u19.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u20.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u21.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u22.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u23.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u24.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u25.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u26.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u27.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u28.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u29.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u30.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u31.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u32.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>
                            <LazyLoadImage src="../gallery/uwtsd/u33.jpeg" alt="uwtsd"
                                           className="column uwtsd img-responsive"/>


                            <LazyLoadImage src='../gallery/webinar/1.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/2.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/3.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/4.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/5.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/6.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/7.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/8.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/9.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/10.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/11.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/12.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/13.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/14.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/15.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/16.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/17.jpeg' alt='campus'
                                           className='column campus img-responsive'/>
                            <LazyLoadImage src='../gallery/webinar/18.jpeg' alt='campus'
                                           className='column campus img-responsive'/>


                            <LazyLoadImage src='../gallery/teams/1.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/2.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/3.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/4.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/5.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/6.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/7.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/8.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/9.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/10.jpeg' alt='teams'
                                           className='column teams img-responsive'/>
                            <LazyLoadImage src='../gallery/teams/11.jpeg' alt='teams'
                                           className='column teams img-responsive'/>


                            <LazyLoadImage src='../gallery/partners/1.jpeg' alt='clients'
                                           className='column clients img-responsive'/>
                            <LazyLoadImage src='../gallery/partners/2.jpeg' alt='clients'
                                           className='column clients img-responsive'/>
                            <LazyLoadImage src='../gallery/partners/3.jpeg' alt='clients'
                                           className='column clients img-responsive'/>
                            <LazyLoadImage src='../gallery/partners/4.jpeg' alt='clients'
                                           className='column clients img-responsive'/>
                            <LazyLoadImage src='../gallery/partners/5.jpeg' alt='clients'
                                           className='column clients img-responsive'/>
                            <LazyLoadImage src='../gallery/partners/6.jpeg' alt='clients'
                                           className='column clients img-responsive'/>

                        </div>

                    </div>
                </div>

            </section>

            <Opportunity/>

            <Helmet>
                <script src="../assets/js/gallery.js" type="text/javascript" async defer/>

            </Helmet>


        </div>
    )
}
export default Gallery
