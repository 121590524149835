import { notification } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { connect, useDispatch } from 'react-redux'
import renderHTML from 'react-render-html'
import { useHistory } from 'react-router-dom'
import { facilityImages } from '../../components/_utils/homeUtil'
import LoginModal from '../../containers/login/loginModal'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import {
  accomodationListFxn,
  finalCheckOutFxn,
  finalCheckOutFxnOld,
  singleRoomBookingPeriodFxn,
  singleRoomFxn,
  startCheckOutFxn
} from '../actions'
import { addBookingFxn } from '../actions/payment'
import SliderComponent from './sliderComponent'

let periodDateFormat = date => {
  return date ? moment(date, 'DD-MM-YYYY').format('DD MMM YYYY') : ''
}

const SingleRoomComponent = props => {
  let { roomId, name, operator_id, dispatch } = props
  let [bookingPeriodList, setBookingPeriodList] = useState([])
  let [bookingPeriodId, setBookingPeriodId] = useState('')
  let [periodObj, setPeriodObj] = useState({})
  useEffect(
    () => {
      loadPeriod()
    },
    [roomId]
  )

  let loadPeriod = async () => {
    if (roomId) {
      let { data, success } = await singleRoomBookingPeriodFxn(roomId)
      if (success && data && data.items && data.items.length) {
        console.log(data, 'singleroombooking')
        setBookingPeriodList(data.items)
      }
    }
  }

  const events = {
    choosePeriod: bookingPeriodId => {
      setBookingPeriodId(bookingPeriodId)

      let findDoc = _.find(bookingPeriodList, item => {
        return item.id == bookingPeriodId
      })
      if (findDoc && findDoc.id) {
        setPeriodObj(findDoc)
      } else {
        setPeriodObj({})
      }
    },
    checkOutFxn: async () => {
      const pathname = window.location.pathname
      const room_slug = pathname.split('/').pop()
      if (!bookingPeriodId) {
        notification.warning({ message: 'Choose Booking period.' })
        return
      }
      let obj = {
        booking_period_id: bookingPeriodId,
        operator_id: operator_id,
        room_id: roomId,
        roomId: roomId
      }
      dispatch(showPageLoad())
      let resp = await finalCheckOutFxn(obj)
      console.log(resp, 'resp-start-checkout')
      dispatch(hidePageLoad())

      if (resp.success && resp.data) {
        let { data } = resp
        let newObj = {
          ...obj,
          client_secret: data.stripe.client_secret,
          public_key: data.stripe.public_key,
          session_token: data.session_token,
          amount: periodObj.price_per_week
        }
        let { data: bookingResp, success, message } = await addBookingFxn(
          newObj
        )
        console.log(
          bookingResp,
          success,
          message,
          'bookingResp, success, message'
        )
        if (success) {
          obj.room_id = room_slug
          const queryParams = new URLSearchParams(obj)
          console.log(obj, 'onjjdfjfjfjfj')
          //   window.open(`/checkout/${bookingResp._id}`)
          window.open(`/checkout/${bookingResp._id}?${queryParams}`)
        } else {
          notification.warning({ message: message })
        }
      }
    }
  }

  return (
    <div id={roomId}>
      <div className='property row g-0 fp2 clearfix wow fadeInUp delay-03s'>
        <div className='col-lg-5 col-md-5 col-sm-12 property-content'>
          <div className='info custom-info'>
            <h1 className='title'>{name}</h1>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 col-sm-12'>
          <select
            className={'form-control'}
            onChange={({ target }) => {
              events.choosePeriod(target.value)
            }}>
            <option value={''}>Choose Booking Period</option>
            {bookingPeriodList && bookingPeriodList.length
              ? bookingPeriodList.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {periodDateFormat(item.start_date)} -{' '}
                    {periodDateFormat(item.end_date)}
                  </option>
                )
              })
              : null}
          </select>
          {periodObj && periodObj.id ? (
            <div className={'mt5 '}>
              <h6>{periodObj.price_per_week} / Week</h6>
            </div>
          ) : null}
        </div>
        <div className='col-lg-4 col-md-4 col-sm-12 alignRight'>
          <a className={'btn btn-primary mr10'} onClick={events.checkOutFxn}>
            Checkout Now
          </a>
        </div>
      </div>
    </div>
  )
}

const StudentAcomodation = props => {
  let [room_slug, setRoom_slug] = useState('')
  let [roomId, setRoomId] = useState('')
  let { name, operator_id, dispatch } = props
  let [bookingPeriodList, setBookingPeriodList] = useState([])
  let [bookingPeriodId, setBookingPeriodId] = useState('')
  let [periodObj, setPeriodObj] = useState({})
  let [operatorId, setOperatorId] = useState('')
  let [propertyId, setPropertyId] = useState('')
  let [loginVisible, setLoginVisible] = useState(false)
  const history = useHistory()
  useEffect(
    () => {
      loadPeriod()
    },
    [roomId]
  )

  let loadPeriod = async () => {
    if (roomId) {
      let { data, success } = await singleRoomBookingPeriodFxn(roomId)
      if (success && data && data.items && data.items.length) {

        setBookingPeriodList(data.items)
      }
    }
  }

  const [accommodation, setAccomodation] = useState({})
  const [showMore, setShowMore] = useState(false)
  const [displayedAmenities, setDisplayedAmenities] = useState(7)

  const handleLoadMore = () => {
    setDisplayedAmenities(prevCount => prevCount + 5)
  }
  const getSingleRoomData = async () => {
    const pathname = window.location.pathname
    const room_slug = pathname.split('/').pop()
    const params = new URLSearchParams(window.location.search)
    let room_Id = parseInt(params.get('roomId'))
    if (room_Id) {
      setRoomId(room_slug)
    }
    setRoom_slug(room_slug)
    let resp = await singleRoomFxn({ room_slug })
    if (resp && resp.data) {
      setAccomodation(resp.data)
      const roomData = resp.data.data
      const { operator_id, booking_periods, property } = resp.data.item

      // Assuming you want the first booking period, adjust if needed
      const booking_period_id =
        booking_periods && booking_periods.length > 0
          ? booking_periods[0].id
          : ''
      const property_id = property ? property.id : ''
      // Set the state variables
      setOperatorId(operator_id)
      setPropertyId(property_id)
      setBookingPeriodId(booking_period_id)
    }
  }
  useEffect(() => {
    getSingleRoomData()
    dispatch({
      type: 'SET_START_PAYMENT_RESPONSE',
      client_secret: '',
      public_key: '',
      session_token: '',
      room_id: '',
      booking_period_id: '',
      operator_id: '',
      amount: 0
    })
    dispatch({ type: 'SET_PUBLIC_KEY', value: false })
  }, [])

  const events = {
    choosePeriod: bookingPeriodId => {
      setBookingPeriodId(bookingPeriodId)

      let findDoc = _.find(bookingPeriodList, item => {
        return item.id == bookingPeriodId
      })
      if (findDoc && findDoc.id) {
        setPeriodObj(findDoc)
      } else {
        setPeriodObj({})
      }
    },
    // checkOutFxn: async () => {
    //     const pathname = window.location.pathname
    //     const room_slug = pathname.split('/').pop()

    //     console.log(room_slug, 'DDDD')
    //     // if (!bookingPeriodId) {
    //     //     notification.warning({message: 'Choose Booking period.'})
    //     //     return
    //     // }
    //     let obj = {
    //         booking_period_id: bookingPeriodId,
    //         operator_id: operatorId,
    //         room_id: room_slug,
    //         roomId: room_slug,
    //         property_id: propertyId,
    //     }
    //     dispatch(showPageLoad())
    //     let resp = await startCheckOutFxn(obj)
    //     console.log(resp, "resp-start-checkout")
    //     dispatch(hidePageLoad())

    //     if (resp.success && resp.data) {
    //         let {data} = resp
    //         let newObj = {
    //             ...obj,
    //             client_secret: data.stripe.client_secret,
    //             public_key: data.stripe.public_key,
    //             session_token: data.session_token,
    //             amount: periodObj.price_per_week
    //         }
    //         // let {data: bookingResp, success, message} = await addBookingFxn(
    //         //     newObj
    //         // )
    //         // console.log(
    //         //     bookingResp,
    //         //     success,
    //         //     message,
    //         //     'bookingResp, success, message'
    //         // )

    //             obj.room_id = room_slug
    //             const queryParams = new URLSearchParams(obj)
    //             console.log(obj, 'onjjdfjfjfjfj')
    //               history.push(`/checkoutRoom/${roomId}`)
    //         //    history.push('/checkout')
    //     }
    // }

    checkOutFxn: async () => {
      let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
      if (user && user._id) {
        events.checkOutInnerFxn()
      } else {
        events.showLoginModal()
      }
    },
    showLoginModal: () => {
      setLoginVisible(true)
    },
    hideLoginModal: () => {
      setLoginVisible(false)
    },
    submitLoginModal: () => {
      setLoginVisible(false)
      setTimeout(() => {
        events.checkOutInnerFxn()
      }, 1000)
    },
    checkOutInnerFxn: async () => {
      const pathname = window.location.pathname
      const room_slug = pathname.split('/').pop()
      let { item } = accommodation
      if (!bookingPeriodId) {
        notification.warning({ message: 'Choose Booking period.' })
        return
      }
      let uniObj = {
        booking_period_id: bookingPeriodId,
        operator_id: operatorId,
        roomId: room_slug
      }

      let obj = {
        ...uniObj,
        room_id: room_slug,
        booking_periods: item.booking_periods && item.booking_periods.length ? item.booking_periods[0] : item.booking_periods,
        address: item.address,
        name: item.name,
        price: item.price,
        property: item.property
      }
      dispatch(showPageLoad())
      let resp = await startCheckOutFxn(uniObj)
      dispatch(hidePageLoad())

      if (resp.success && resp.data) {
        let { data } = resp
        let newObj = {
          ...obj,
          client_secret: data.stripe.client_secret,
          public_key: data.stripe.public_key,
          session_token: data.session_token,
          amount: periodObj.price_per_week
        }
        let { data: bookingResp, success, message } = await addBookingFxn(
          newObj
        )

        if (success) {
          const queryParams = new URLSearchParams(obj)
          history.push(`/checkout/${bookingResp._id}?${queryParams}`)

          // window.open(`/checkout/${bookingResp._id}?${queryParams}`);

        } else {
          notification.warning({ message: message })
        }
      }
    }
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='../../unizHome/css/singleProperty.css' />
        <link rel='stylesheet' href='../../unizHome/css/slider.css' />
        <script src='../../unizHome/scripts/slick.min.js' async />
        <script src='../../unizHome/scripts/stickHeader.js' async />
      </Helmet>
      <div className='sub-banner sb-two'>
        <div className='overlay'>
          <div className='container'>
            <div className='breadcrumb-area'>
              <h1>Room Details</h1>
              <ul className='breadcrumbs'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='active'>Room Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className='properties-section-body content-area'
        style={{ paddingTop: 40 }}>
        <div className='container'>
          {accommodation && accommodation.item ? (
            <div className='content-area  properties-details-page'>
              <div className='container-room'>
                <div className='row'>
                  <div className='col-md-12'>
                    <SliderComponent
                      accommodation={accommodation.item.images}
                    />
                  </div>
                  <div className='col-md-8 mt20'>
                    <div className='heading-properties clearfix sw2 '>
                      <div className='pull-left'>
                        <h3 style={{ fontSize: '40px' }}>
                          {accommodation.item.name}
                        </h3>
                        <div>
                          <i className='fa fa-map-marker' />
                          &nbsp;
                          {accommodation.item.address.first_line}&nbsp;
                          {accommodation.item.address.city} &nbsp;
                          {accommodation.item.address.country}&nbsp;
                          {accommodation.item.address.postcode}&nbsp;
                        </div>
                      </div>
                    </div>

                    <div
                      className='properties-description mb-30'
                      style={{ marginTop: '20px' }}>
                      <div className='main-title-4'>
                        <h1>
                          <span>About the room</span>
                        </h1>
                      </div>
                      <div className='description'>
                        {renderHTML(accommodation.item.description)}
                        {accommodation.item.description &&
                        accommodation.item.description.length > 10 && (
                          <>
                            <br />
                            <span
                              className='read-more'
                              onClick={() => setShowMore(!showMore)}>
                                {showMore ? 'Read less' : 'Read more'}
                              </span>
                            <p
                              className={`more-content ${
                                showMore ? 'show' : 'hide'
                              }`}>
                              {accommodation.item.description}
                            </p>
                          </>
                        )}
                      </div>
                      <br />
                    </div>

                    <div className='properties-condition'>
                      <div className='main-title-4'>
                        <h1>
                          <span>Amenities</span>
                        </h1>
                      </div>
                      {/* <ul className="condition row">
                                    {accommodation.item.amenities.general &&
                                    accommodation.item.amenities.general.length
                                      ? accommodation.item.amenities.general.map((eachFac, key) => {
                                          if (key < displayedAmenities || window.innerWidth >= 768) {
                                            let abc;
                                            if (eachFac.status === true) {
                                              abc = '✔';
                                            } else {
                                              abc = '✘';
                                            }
                                            return (
                                              <li key={key} className={'col-md-4 space'}>
                                                {facilityImages[eachFac.class] ? (
                                                  <LazyLoadImage
                                                    src={facilityImages[eachFac.class]}
                                                    height={20}
                                                    width={20}
                                                    style={{ marginRight: 10 }}
                                                  />
                                                ) : null}
                                                <span style={{ lineHeight: '2rem', color: 'black' }}>
                                                  {' '}
                                                  <strong>{eachFac.name}</strong>&nbsp;
                                                  {abc}
                                                </span>
                                              </li>
                                            );
                                          }
                                          return null;
                                        })
                                      : null}
                                    {accommodation.item.amenities.general &&
                                    accommodation.item.amenities.general.length > displayedAmenities && (
                                      <li className="load-more-li col-md-4 space">
                                        <button className="load-more-button" onClick={handleLoadMore}>
                                          Load More
                                        </button>
                                      </li>
                                    )}
                                  </ul> */}
                      <ul className='condition row'>
                        {accommodation.item.amenities.general &&
                        accommodation.item.amenities.general.length
                          ? accommodation.item.amenities.general.map(
                            (eachFac, key) => {
                              if (
                                key < displayedAmenities ||
                                window.innerWidth >= 768
                              ) {
                                let abc
                                if (eachFac.status === true) {
                                  // abc = '✔'
                                  return (
                                    <li key={key} className={'col-md-4 space'}
                                        style={{ paddingLeft: 0 }}>
                                      {/* {facilityImages[eachFac.class] ? (
                                                                                <LazyLoadImage
                                                                                    src={facilityImages[eachFac.class]}
                                                                                    height={20}
                                                                                    width={20}
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                            ) : null}*/}
                                      <span
                                        style={{
                                          lineHeight: '2rem',
                                          color: 'black'
                                        }}>
                                                                                {eachFac.name}
                                        </span>
                                    </li>
                                  )
                                }
                                return null
                              }
                              return null
                            }
                          )
                          : null}
                        {/*   {accommodation.item.amenities.general &&
                                                accommodation.item.amenities.general.length >
                                                displayedAmenities && (
                                                    <li className="load-more-li col-md-4 space">
                                                        <button
                                                            className="load-more-button"
                                                            onClick={handleLoadMore}>
                                                            Load More
                                                        </button>
                                                    </li>
                                                )}*/}
                      </ul>
                    </div>
                    <br />
                  </div>
                  <div className={'col-md-4'}>
                    <div
                      className='pull-right responsive-box mt40'
                      style={{
                        border: '1px solid #fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '10px',
                        width: '395px',
                        padding: '50px 20px'
                      }}>
                      <h6 style={{ margin: '20px' }}>
                        From{' '}
                        <span style={{ fontSize: '26px', fontWeight: 'bold' }}>
                          {accommodation.item.price_range.min}
                        </span>
                        /Week
                      </h6>

                      <p style={{ margin: '20px' }}>
                        <b>AVAILABLE BOOKING PERIODS</b>
                      </p>
                      <div
                        style={{
                          border: '1px solid gray',
                          borderRadius: '10px',
                          margin: '20px 10px',
                          padding: '16px'
                        }}>
                        <div>
                          {accommodation &&
                          accommodation.item &&
                          accommodation.item.booking_periods &&
                          accommodation.item.booking_periods.length &&
                          accommodation.item.booking_periods.map(
                            (bookingPeriod, index) => {
                              const startDate = moment(
                                bookingPeriod.start_date,
                                'DD-MM-YYYY'
                              )
                              const endDate = moment(
                                bookingPeriod.end_date,
                                'DD-MM-YYYY'
                              )
                              const durationInWeeks = endDate.diff(
                                startDate,
                                'weeks',
                                true
                              )

                              return (
                                <div key={index}>
                                  <h3 style={{ color: 'gray' }}>
                                    <strong>{`${durationInWeeks} weeks`}</strong>
                                  </h3>

                                  <p>{`${bookingPeriod.start_date} - ${
                                    bookingPeriod.end_date
                                  }`}</p>
                                </div>
                              )
                            }
                          )}
                        </div>

                        <p>
                          <strong>
                            {accommodation.item.price_range.min}/ week
                          </strong>
                        </p>
                        <div className='tabs-container'>
                          <div
                            className='btn btn-block white-btn'
                            id='hostedCheckoutTab'
                            onClick={events.checkOutFxn}>
                            <div>CHECKOUT</div>
                          </div>
                        </div>
                      </div>

                      {/* <h5>{accommodation.item.price_range.max}</h5> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {accommodation &&
          accommodation.item &&
          accommodation.item.rooms &&
          accommodation.item.rooms.length ? (
            <>
              <div className='container'>
                <div className='main-title-4'>
                  <div className='property-description tabbing tabbing-box tb2'>
                    {accommodation.item.rooms.map(item => {
                      return (
                        <SingleRoomComponent
                          roomId={item.id}
                          operator_id={accommodation.item.operator_id}
                          name={item.name}
                          dispatch={dispatch}
                          {...props}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {loginVisible ? (
        <LoginModal
          visible={loginVisible}
          onClose={events.hideLoginModal}
          onSubmit={events.submitLoginModal}
          dispatch={dispatch}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAcomodation)
