import {casitaUrl, partnerCode, apiUrl, apiUrl3} from '../../settings'

export const singleCountryUrl = () => {
  return apiUrl3 + `/unizhome/allCountries`
}
export const universityListUrl = () => {
  return apiUrl3 + '/unizhome/universityList'
}
export const accommodationListUrl = (data) => {
  return (
    apiUrl3 + `/unizhome/single-property`
  )
}
export const addEnquiryUrl = () => {
  return apiUrl3 + '/unizhome/add-enquiry'
}
export const searchDataUrl = () => {
  return apiUrl3 + '/unizhome/search-data'

}

export const propertiesListUrl = (data) => {
  console.log(data, "ddddddddddayayyyaya")
  let {
    page,
    provider_type_id,
    city,
    roomType,
    cancellationPolicy,
    facilities,
    covid,
    novisa,
    noplace
  } = data

  let baseUrl = `/unizhome/property-list?page=${page}&cityName=${city}`
  if (provider_type_id) {
    baseUrl += `&provider_type_id=${provider_type_id}`
  }

  if (roomType) {
    baseUrl += `&room-types=${roomType}`
  }

  if (facilities) {
    baseUrl += `&facilities=${facilities}`
  }

  if (covid || novisa || noplace) {
    baseUrl += `&covid=${covid}&no-visa=${novisa}&no-place=${noplace}`
  }
  return apiUrl3 + baseUrl
}
