import {  notification } from 'antd'
import {customAxios as axios, getToken} from '../../../../request'
import {
  addTemplateCategoryUrl,
  addTemplateUrl,
  getSingleTemplateUrl,
  getTemplateCategoryListUrl,
  getTemplatetCategoryListUrl,
  addTemplateSectionUrl,
  templateListUrl,
  templateEditUrl,
  removeTemplateUrl,
  templateSOPUrl,
  GenerateSOPUrl,
  sopListUrl,
  updateTemplateUrl,
  updateTemplateActiveStatusUrl
} from '../api/template'
import { hideLoader,showLoader } from '../../../../modules/actions'
import generateSOP from '../../generateSOP'

export const AddTemplateCategory = async (valData) => {

  let {data} = await axios.post(
    addTemplateCategoryUrl(),
    valData,
   await getToken()
  );
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const AddTemplateFxn = (valData) => async (dispatch) => {

  dispatch(showLoader());

  let {data} = await axios.post(addTemplateUrl(), valData, await getToken());
  console.log(data);
  dispatch(hideLoader());

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data;
};
export const templateListFxn = async (valData) => {
  let config = {
    params: {...valData},
    ...(await getToken()),
  };
  let data = await axios.get(templateListUrl(), config);
  return data.data;
};

export const getSingleTemplateFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let data = await axios.get(getSingleTemplateUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const getTemplateCategoryListFxn = async (params) => {
  let config = {
    params,
    ...(await getToken()),
  };
  let { data } = await axios.get(getTemplateCategoryListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const AddTemplateSectionFxn = async (valData) => {
  let { data } = await axios.post(addTemplateSectionUrl(), valData, await getToken());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};

export const updateTemplateSectionFxn = (valData) => async (dispatch) => {
  dispatch(showLoader())
  let {data} = await axios.post(templateEditUrl(), valData,await getToken());
  dispatch(hideLoader())
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const RemoveSectionFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(removeTemplateUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const templateSopFxn = async (valData) => {
  // dispatch(showLoader())
  let { data } = await axios.post(templateSOPUrl(), valData, await getToken());
  // dispatch(hideLoader())
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const GenerateSOPFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  console.log(valData, "wertyutrew")
  let { data } = await axios.post(GenerateSOPUrl(), valData, await getToken());

  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  // else {
  //   notification.success({
  //     message: data.message || "Success",
  //   });
  // }
  return data;
};
export const sopListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(sopListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const updateTemplateFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(updateTemplateUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const updateTemplateActiveStatusFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(
    updateTemplateActiveStatusUrl(),
    valData,
   await getToken()
  );
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
