import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Drawer,
    Card,
    Form,
    notification,
    Col,
    Row
} from 'antd';
import _ from 'lodash';
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import {createTransactionFxn, singleQuotationFxn} from "../actions";

const NewTransaction = (props) => {
    const {
        form: {getFieldDecorator, setFieldsValue},
        onClose,
        visible,
        currencyList,
        quotationId,
        selectedCurrency
    } = props;

    let [quotationData, setQuotationData] = useState(null);
    let dispatch = useDispatch();
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    useEffect(() => {
        events.apiRequest();
    }, []);
    console.log(selectedCurrency, "selelc")
    const events = {
        apiRequest: async () => {
            let resp = await dispatch(singleQuotationFxn({quotationId}));
            const response = resp.quotation;

            if (quotationId && response) {
                setQuotationData(response);
                setFieldsValue({
                    currency: response.fromCurrency,
                    amount: response.amount,
                    fxRate: response.fxRate,
                    name: response.name,
                    emailAddress: response.emailId,
                    phoneNumber: response.phoneNo
                });
            } else if (!quotationId && selectedCurrency) {
                setFieldsValue({
                    currency: selectedCurrency.fromCurrency,
                    amount: selectedCurrency.fromCurrencyAmount,
                    fxRate: selectedCurrency.fxRate,
                    name: "",
                    emailAddress: "",
                    phoneNumber: ""
                });
            }
        },
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                const transactionData = {
                    ...valData,
                    quotationId: quotationData ? quotationData.quotationId : null,
                    fxRate: quotationData ? quotationData.fxRate : selectedCurrency.fxRate,
                    sourceId: quotationData ? quotationData.sourceId : 1,
                    agentId: user._id,
                    amount: quotationData ? valData.amount : selectedCurrency.fromCurrencyAmount
                };

                let resp = await dispatch(createTransactionFxn(transactionData));
                if (resp.success) {
                    notification.success({
                        message: 'Transaction Created Successfully'
                    });
                    form.resetFields();
                    onClose();
                } else {
                    notification.error({
                        message: resp.message
                    });
                }

            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                });
            }
        });
    };

    const inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "phoneNumber",
                label: "Phone",
                required: true,
                placeholder: "Phone",
                type: "text",
                minLength: 10,
                maxLength: 10
            },
            {
                key: "emailAddress",
                label: "Email",
                required: true,
                placeholder: "Email",
                type: "text",
            },
            {
                key: 'currency',
                label: 'Currency',
                type: 'select',
                required: true,
                options: currencyList,
                keyAccessor: x => x.currencyCode,
                valueAccessor: (x) => (
                    <>
                        <img src={x.imgPath} style={{height: 25}} alt="currency"/>
                        <span className={'ml20 font-weight-600'}>{x.currencyCode} - {x.currencyName}</span>
                    </>
                ),
                disabled: true,
            },
            {
                key: "fxRate",
                label: "Customer Rate",
                required: true,
                placeholder: "Customer Rate",
                type: "text",
                disabled: true,
            },
            {
                key: "amount",
                label: "Amount",
                required: true,
                placeholder: "Amount",
                type: "text",
                disabled: true,
            },
        ],
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    };

    return (
        <Drawer
            visible={visible}
            title={"New Transaction"}
            onClose={onClose}
            width={"50%"}>
            <>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        <Card shadow={false} padding={false}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24} xs={24}
                                         key={key} className={'mb10'}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                                );
                            })}
                        </Card>
                    </Row>

                    <Button type={"primary"} htmlType="submit" className={"mt-4 ml-4"}>
                        Submit
                    </Button>
                </Form>
            </>
        </Drawer>
    );
};

export default Form.create()(NewTransaction);
