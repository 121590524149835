import {Icon, Menu} from 'antd'
import classNames from 'classnames'
import React, {PureComponent} from 'react'
import {Link} from 'react-router-dom'
import {
    departmentObj,
    cambieEvents,
    hitanshuId,
    countryIds,
    accountantEmail,
    cambieOnshoreUserList, bloomsburyShowToUser, adminRightUser, ryanAgentId, kasaBranchUserEmail
} from '../_utils/appUtils'
import {urlToList} from '../_utils/pathTools'
import {isUrl} from '../_utils/utils'
import {getMenuMatches} from './SiderMenuUtils'
import styles from './index.less'
import {taskRights} from '../_utils/rightsUtils'
// import Menu from "rc-menu"
const {SubMenu} = Menu

const getIcon = icon => {
    if (typeof icon === 'string' && isUrl(icon)) {
        return <img src={icon} alt='icon' className={styles.icon}/>
    }
    if (typeof icon === 'string') {
        return <Icon type={icon}/>
        // return <Icon type={icon} component={() => <div>
        //   {/* <Icon type="mail" /> */}
        //   <div style={{ width: '100px',height:'20px', overflow: 'hidden' }}> <img src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png" alt="Flowers in Chania"></img></div>
        //   <span>neha</span>
        // </div>} />
    }
    return icon
}

export default class BaseMenu extends PureComponent {
    /**
     * @memberof SiderMenu
     */
    getNavMenuItems = (menusData, parent) => {
        const {
            user,
            currentUser,
            currentUserRights: {
                userType,
                allowAddingUser,
                showAgent,
                department,
                branchManagerType,
                countryRight,
                assignIntakesRight,
                approveFCMTLoa,
                approveOxfordDraft,
                showAllUniversities,
                showAllFcmtList,
                approveCambieLoa,
                showAllCambieList,
                approveRoyalArtsLoa,
                showAllRoyalArtsList,
                showOnShoreAustralia,
                approvedAgent,
                showApprovedCommission,
                showAllLesterList,
                showCommissionStructure,
                countryId,
                showCanadaLoa,
                showUnizHomeEnquiry,
                oxfordInterviewDepartment,
                showAssignIntake,
                enableUploadTt,
                enableFcmtTtUpload,
                enableCiraTtUpload,
                enableCambieTtUpload,
                enableCornerStoneTtUpload,
                showIcef,
                showPendingVisa,
                showPendingGcKey,
                showOnShoreCambie,
                showOnShoreFcmt,
                dealCloseRight = false,
                referCodeForInstitute = '',
                referByUser = '',
                showContract = false,
                showCanadaFcmtVisa = false,
                approveRoyalSchoolLoa,
                showAllRoyalSchoolList,
                defaultCurrency
            }
        } = this.props

        let {allowApproveCambieAgent} = this.props.currentUserRights

        /*   console.log('uer  uer ue ru r this.propsthis.props', this.props)
               console.log(this.props.currentUserRights, this.props.user,'++')*/
        if (!menusData) {
            return []
        }
        return menusData
            .filter(item => item.name && !item.hideInMenu)
            .map(item => {
                let condition = true

                if (item.authority) {
                    if (!item.authority.includes(userType)) {
                        condition = false
                    }
                }

                if (userType == 'agent' && item.key == 'subAgent' && !allowAddingUser) {
                    condition = false
                }
                if (item.key == 'assessmentDepartment') {
                    if (department && department == departmentObj.assDepartment) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'searchCourse' && userType == 'branchUser') {
                    if (department && department == departmentObj.interviewer) {
                        condition = false
                    }
                }
                if (item.key == 'allAgents' || item.key == 'allSubAgents') {
                    if (!showAgent) {
                        condition = false
                    }
                }
                if (item.key == 'agentCommission' || item.key == 'mainWithdrawalList') {
                    condition = false
                    if (
                        userType == 'admin' ||
                        (currentUser && currentUser.email == 'accounts@ryanconsultants.com')
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'commission') {
                    condition = false
                    // (userType == 'branchManager' && !branchManagerType && branchManagerType !== null) ||
                    if (
                        userType == 'admin' ||
                        (userType == 'branchManager' &&
                            !branchManagerType &&
                            branchManagerType !== null) ||
                        (currentUser && currentUser.email == 'accounts@ryanconsultants.com')
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'onshoreCommission' ||
                    item.key == 'onShoreUniversityCommission' ||
                    item.key == 'onShoreReceivedCommissionList' ||
                    item.key == 'onShoreAgentCommissionList') {
                    condition = false
                    if (
                        userType == 'admin' || userType == 'canadaOnshoreAdmin' || userType == 'onshoreAccountant' ||
                        (currentUser && currentUser.email && currentUser.email == accountantEmail)
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'allStudent') {
                    if (
                        department &&
                        (department == departmentObj.interviewer ||
                            department == departmentObj.warningDepartment)
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'searchCourse') {
                    if (department && department == departmentObj.warningDepartment) {
                        condition = false
                    }
                }

                if (
                    item.key == 'assessmentsWarnings' ||
                    item.key == 'waitingToApplyWarnings'
                ) {
                    if (department && department == departmentObj.warningDepartment) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'fcmtPendingApplicationsList') {
                    if (approveFCMTLoa && approveFCMTLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'oxfordPendingApplicationsList') {
                    if (approveOxfordDraft && approveOxfordDraft == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'cambiePendingApplicationsList') {
                    if (approveCambieLoa && approveCambieLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'royalArtsPendingApplicationsList') {
                    if (approveRoyalArtsLoa && approveRoyalArtsLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'australiaOnShoreList') {
                    if (showOnShoreAustralia) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allFcmtApplicationsList') {
                    if (showAllFcmtList || userType == 'itTeam') {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'allLesterApplicationsList') {
                    if (showAllLesterList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allCambieApplicationsList') {
                    if (showAllCambieList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'allRoyalArtsApplicationsList') {
                    if (showAllRoyalArtsList || adminRightUser.includes(userType)) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (item.key == 'allRoyalHighSchoolApplicationsList') {
                    if (showAllRoyalSchoolList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allUniversitiesForRyanAccounts') {
                    if (showAllUniversities && showAllUniversities == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'user') {
                    condition = false
                    if (adminRightUser.includes(userType) || (userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    } else {
                        if (showAgent) {
                            condition = true
                        }
                    }
                }
                if (item.key == 'masters') {
                    if (!(adminRightUser.includes(userType) || userType == 'master' || userType == 'userManager' || (userType == 'branchManager' && !branchManagerType))) {
                        condition = false
                    }
                }
                if (item.key == 'allCountries') {
                    if (
                        !(
                            userType == 'admin' ||
                            (userType == 'master' && countryRight == 'All')
                        )
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'allApplicationsList') {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType == 'marketingManager'
                    ) {
                        condition = false
                    }
                }
                if (
                    item.key == 'searchCourse' ||
                    item.key == 'searchCourse' ||
                    item.key == 'addStudent' ||
                    item.key == 'user'
                ) {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType &&
                        branchManagerType == 'universityWise'
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'sendMailToAgent' || item.key == 'eventList') {
                    if (
                        userType == 'branchManager' &&
                        branchManagerType &&
                        branchManagerType == 'universityWise'
                    ) {
                        condition = false
                    } else if (
                        userType == 'branchUser' &&
                        department !== departmentObj.marketing
                    ) {
                        condition = false
                    }
                }
                if (item.key == 'marketingAgent') {
                    condition = false
                    if (branchManagerType) {
                        if (branchManagerType == 'agentWise') {
                            condition = true
                        }
                    }
                }
                if (item.key == 'marketingUserAssignAgentList') {
                    condition = false
                    if (department == departmentObj.marketing) {
                        condition = true
                    }
                }

                if (item.key == 'allAgentForMarketingUsers') {
                    condition = false
                    if (branchManagerType) {
                        if (branchManagerType == 'allAgentForMarketingUsers') {
                            condition = true
                        }
                    } else if (department == departmentObj.marketing || userType == 'marketingManager') {
                        condition = true
                    }
                }

                if (item.key === 'assignIntake') {
                    condition = false
                    if (assignIntakesRight) {
                        condition = true
                    }
                }
                if (item.key == 'canadaApplicationList') {
                    condition = false
                    if (showCanadaLoa) {
                        condition = true
                    }
                }

                /*
                                if (item.key == 'allStudent') {
                                    condition = true
                                    if (userType == 'marketingManager') {
                                        condition = false
                                    }
                                }*/

                if (item.key == 'allAgentCommissionList' || item.key == 'gicCommissionWithdrawal') {
                    condition = false
                    if (showApprovedCommission) {
                        condition = true
                    }
                }

                if (item.key == 'approvedWithdrawals' || item.key == 'gicApprovedWithdrawals') {
                    condition = false
                    if ((currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                        condition = true
                    }
                }

                if (item.key == 'commissionStructure') {
                    condition = false
                    if ((userType == 'agent' && user && !cambieEvents.checkAgent(user._id)) || userType == 'admin' || (showCommissionStructure == true) || (userType == 'branchUser' && department == departmentObj.marketing)) {
                        condition = true
                        if (userType == 'branchUser') {
                            item.dontShowOnMenu = false
                        }
                    }
                }
                /*if (item.key == 'approvedAgents') {
                            condition = false
                            if (userType == 'admin' || (userType == 'branchUser' && department !== departmentObj.counselling)) {
                                // condition = true
                            }
                        }*/

                if (item.key == 'approvedAgents') {
                    condition = false
                    if (
                        userType == 'admin' ||
                        userType == 'userManager' ||
                        approvedAgent
                    ) {
                        condition = true
                    }
                }

                /*  if (item.key == 'counsellingList') {
                    condition = false
                    if (
                      userType == 'admin' ||
                      (userType == 'branchUser' &&
                        department == departmentObj.counselling)
                    ) {
                      condition = true
                    }
                  }*/
                if (item.key == 'selfReport') {
                    condition = false
                    if (
                        userType == 'branchUser' &&
                        department == departmentObj.marketing
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'dailyAgentReport') {
                    condition = false
                    if ((userType == 'branchManager' && branchManagerType && branchManagerType == 'agentWise') || userType == 'admin') {
                        condition = true
                    }
                }

                if (item.key == 'referredAgentCommission') {
                    condition = false
                    if ((userType == 'branchManager' && !branchManagerType && branchManagerType !== null)) {
                        condition = true
                    }
                }

                if (item.key == 'unizHomeEnquiry') {
                    condition = false
                    if (showUnizHomeEnquiry) {
                        condition = true
                    }
                }

                if (item.key == 'oxfordInterviewApplications') {
                    if (oxfordInterviewDepartment && oxfordInterviewDepartment == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'oxfordPendingApplicationsList' || item.key == 'oxfordPendingInterviewList') {
                    if (approveOxfordDraft && approveOxfordDraft == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'allStudent' || item.key == 'addStudent' || item.key == 'searchCourse') {
                    if (!approveFCMTLoa && !approveCambieLoa && !approveRoyalArtsLoa && !approveRoyalSchoolLoa && !showOnShoreAustralia && !oxfordInterviewDepartment && !approveOxfordDraft) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                /*        if (item.key == 'dailyExpenseListForMarketingUser' || item.key == 'addExpense') {
                          condition = false
                          if ((userType == 'branchUser' && department == departmentObj.marketing) ||
                            (userType == 'marketingManager') ||
                            (userType == 'branchManager' && !branchManagerType && branchManagerType !== null)
                          ) {
                            condition = true
                          }
                        }*/

                if (item.key == 'dailyExpenseListForMarketingUser' || item.key == 'addExpense') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing && defaultCurrency == 'INR') ||
                        (userType == 'marketingManager' && defaultCurrency == 'INR') ||
                        (userType == 'eventManager' && defaultCurrency == 'INR') ||
                        (userType == 'branchManager' && !branchManagerType && branchManagerType !== null)
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'dailyExpenseListForMarketingUserCanada' || item.key == 'addExpenseCanada') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing && defaultCurrency == 'CAD')) {
                        condition = true
                    }
                }


                if (item.key == 'expense') {
                    condition = false
                    if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                        condition = true
                    }
                }


                if (item.key == 'cambieAgentList') {
                    condition = false
                    if (userType == 'admin' || (currentUser && currentUser._id && cambieEvents.checkApprovedAgent(currentUser._id)) || allowApproveCambieAgent) {
                        condition = true
                    }
                    /*if (userType == 'admin') {
                        condition = true
                    }*/

                }
                if (item.key == 'searchCourse' || item.key == 'addStudent' || item.key == 'allStudent') {
                    condition = true
                    let userTypeList = [
                        'reception', 'student', 'onshoreCanada', 'marketingManager', 'courseMaster', 'master',
                        'cambieMarketingManager', 'cambieBranchUser', 'institute', 'eventManager', 'counsellingManager', 'unizhomeManager',
                        'gicManager', 'cornerStoneManager', 'nationalCollegeManager', 'gicMarketing', 'onshoreAccountant', 'onshoreManager',
                        'directHead', 'reeudoManager',
                        ...cambieOnshoreUserList
                    ]
                    if ((userType == 'branchUser' && department == departmentObj.counselling) || userTypeList.includes(userType)) {
                        condition = false
                    }
                }


                if (item.key == 'studentDiversity') {
                    condition = false
                    if (userType == 'admin' || (userType == 'marketingManager' && (user && user._id == hitanshuId))) {
                        condition = true
                    }
                }


                if (item.key == 'callbackRequestList') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing)) {
                        condition = true
                    }
                }

                /* if (item.key == 'canadaOpenCloseCourse' || item.key == 'ukOpenCloseCourse' || item.key == 'australiaOpenCloseCourse' || item.key == 'usaOpenCloseCourse') {
                     condition = false
                     if (userType == "admin" || userType == "userManager") {
                         condition = true
                     } else if (userType == 'branchManager' && !branchManagerType) {
                        /!* if (item.key == 'canadaOpenCloseCourse' && user.countryId == countryIds.canada) {
                             condition = true
                         }
                         if (item.key == 'ukOpenCloseCourse' && user.countryId == countryIds.uk) {
                             condition = true
                         }
                         if (item.key == 'australiaOpenCloseCourse' && user.countryId == countryIds.australia) {
                             condition = true
                         }
                         if (item.key == 'usaOpenCloseCourse' && user.countryId == countryIds.usa) {
                             condition = true
                         }*!/
                     }
                 }*/

                if (item.key == 'assignIntakeCampusWays') {
                    condition = false
                    if (userType == 'admin' || userType == 'userManager' || showAssignIntake) {
                        condition = true
                    }
                }

                /* if (item.key == 'fcmt') {
                     condition = false
                     if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableFcmtTtUpload)
                         || (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                         condition = true
                     }
                 }
                 if (item.key == 'cambie') {
                     condition = false
                     if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableCambieTtUpload) ||
                         (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                         condition = true
                     }
                 }
                 if (item.key == 'cira') {
                     condition = false
                     if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || userType == 'cambieMarketingManager' || ((userType == 'marketingManager' || department == departmentObj.marketing || department == departmentObj.offerDepartment) && enableCiraTtUpload) ||
                         (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                         condition = true
                     }
                 }*/

                if (item.key == 'fcmt' || item.key == 'fcmtFilePendingForSubmissions' || item.key == 'fcmtUploadTT' || item.key == 'fcmtPaidTT') {
                    condition = false
                    if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableFcmtTtUpload) ||
                        (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    } else {
                        if (currentUser && currentUser.email == accountantEmail && item.key == 'fcmt') {
                            condition = true
                        }
                    }
                }
                if (item.key == 'cambie' || item.key == 'cambieFilePendingForSubmissions' || item.key == 'cambieUploadTT' || item.key == 'cambiePaidTT') {
                    condition = false
                    if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableCambieTtUpload) ||
                        (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    }
                }
                if (item.key == 'cira' || item.key == 'ciraFilePendingForSubmissions' || item.key == 'ciraUploadTT' || item.key == 'ciraPaidTT') {
                    condition = false
                    if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || (enableCiraTtUpload) ||
                        (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    } else {
                        if (currentUser && currentUser.email == accountantEmail && item.key == 'cira') {
                            condition = true
                        }
                    }
                }


                if (item.key == 'fcmtFilePendingForSubmissions' || item.key == 'cambieFilePendingForSubmissions' || item.key == 'ciraFilePendingForSubmissions') {
                    condition = false
                    if (userType == 'admin' || (userType == 'marketingManager' && enableFcmtTtUpload) || (enableFcmtTtUpload && department == departmentObj.marketing) || (enableCiraTtUpload) ||
                        (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    }
                }

                if (item.key == 'cornerstone' || item.key == 'cornerstoneFilePendingForSubmissions' ||
                    item.key == 'cornerstoneUploadTT' || item.key == 'cornerstonePaidTT') {
                    condition = false
                    if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableCornerStoneTtUpload) ||
                        (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
                        condition = true
                    } else {
                        if (currentUser && currentUser.email == accountantEmail && item.key == 'cornerstone') {
                            condition = true
                        }
                    }
                }

                if (item.key == 'oldMarketingUserDashboard') {
                    condition = false
                    if (userType == 'branchUser' && department == departmentObj.marketing) {
                        condition = true
                    }
                }

                if (item.key == 'pendingOnShoreApplications') {
                    let onshoreCountryId = countryId && countryId._id ? countryId._id : ''
                    condition = false
                    if (((userType == 'branchUser' && department == departmentObj.assDepartment) || (userType == 'branchManager')) && onshoreCountryId == countryIds.canadaOnshore) {
                        condition = true
                    }
                }

                if (item.key == 'enroll' || item.key == 'addEnrolledCompany' || item.key == 'allEnrolledCompanies') {
                    condition = false
                    if (department == departmentObj.marketing || userType == 'admin') {
                        condition = true
                    }
                }


                if (item.key == 'marketingAgentsList') {
                    condition = false
                    if ((department == departmentObj.marketing && dealCloseRight) || (userType == 'marketingManager' && dealCloseRight) || userType == 'admin') {
                        condition = true
                    }
                }
                if (item.key == 'allRequestedList') {
                    condition = false
                    if ((userType == 'marketingManager' && dealCloseRight)
                        || userType == 'admin' || userType == 'agent') {
                        condition = true
                    }
                }
                if (item.key == 'allPendingForGcKeyApp') {
                    condition = false
                    if (showPendingGcKey) {
                        condition = true
                    }
                }
                if (item.key == 'allPendingForVisaApp') {
                    condition = false
                    if (showPendingVisa) {
                        condition = true
                    }
                }
                if (item.key == 'onshoreCambieAppList') {
                    condition = false
                    if (showOnShoreCambie || ['cambieBranchUser', 'cambieManager', 'cambieReception', 'cambieDirector', 'admin'].includes(userType)) {
                        condition = true
                    }
                }
                if (item.key == 'fcmtReceivedCommission' || item.key == 'ciraReceivedCommission' || item.key == 'generateDirectUniCommission') {
                    condition = false
                    if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == 'accounts@ryanconsultants.com')) {
                        condition = true
                    }
                }
                /*if (item.key == 'tasksList' || item.key == 'futureTasksList') {
                  condition = false
                  if (userType == 'admin' || userType == 'userManager' || userType == 'canadaOnshoreAdmin' || (userType == 'branchManager' && !branchManagerType)
                    || userType == 'marketingManager' || (userType == 'branchUser' && department == departmentObj.marketing)
                  ) {
                    condition = true
                  }
                }*/

                if (item.key == 'tasksList' || item.key == 'futureTasksList') {
                    condition = taskRights.userRight()
                }

                if (item.key == 'onshoreFcmtAppList' || item.key == 'fcmtAgentList') {
                    condition = false
                    if (userType == 'admin' || (userType == 'branchManager' && showOnShoreFcmt)) {
                        condition = true
                    }
                }

                if (item.key == 'institutesList') {
                    condition = false
                    if ((referCodeForInstitute)) {
                        condition = true
                    }
                }
                if (item.key == 'applyEvent' || item.key == 'pastEvents') {
                    condition = false
                    if (referByUser == 'institute') {
                        condition = true
                    }
                }

                if (item.key == 'contractList') {
                    condition = false
                    if (showContract == true) {
                        condition = true
                    }
                }
                if (item.key == 'fcmtVisaApproved') {
                    condition = false
                    if (showCanadaFcmtVisa || userType == 'admin') {
                        condition = true
                    }
                }

                if (item.key == 'ciraHighSchoolApplicationList') {
                    if (showAllRoyalSchoolList) {
                        condition = true
                    } else {
                        condition = false
                    }
                }

                if (item.key == 'royalHighSchoolPendingLoaList') {
                    if (approveRoyalSchoolLoa && approveRoyalSchoolLoa == true) {
                        condition = true
                    } else {
                        condition = false
                    }
                }
                if (currentUser && currentUser.userType && currentUser.userType == 'student') {
                    let regStuKeys = ['referralComponent', 'yourReferrals', 'studentWithdrawal', 'transactionList', 'canadaOnShoreCourseListStudent', 'canadaLocalOffice']
                    let unRegStuKeys = ['instituteEvents', 'registeredEvents', 'searchCourse', 'canadaOpenCloseCourse', 'ukOpenCloseCourse', 'usaOpenCloseCourse', 'bookCounselling', 'counsellingSessions']
                    if (regStuKeys.includes(item.key)) {
                        condition = currentUser.directRegistration
                    }
                    if (unRegStuKeys.includes(item.key)) {
                        condition = !currentUser.directRegistration
                    }
                }

                if (item.key == 'workshopsRoiList' || item.key == 'icefEventList' || item.key == 'addParticipant' || item.key == 'participantsList' || item.key == 'addIcefEvents') {
                    condition = false
                    if (showIcef) {
                        condition = true
                    }
                }

                if (item.key == 'counsellingStudentList') {
                    condition = false
                    if (department == departmentObj.counselling || userType == 'eventManager' || userType == 'institute' || referCodeForInstitute) {
                        condition = true
                    }
                }

                if (item.key == 'gic') {
                    condition = false
                    if (userType == 'admin' || userType == 'gicManager' || userType == 'gicMarketing' || userType == 'agent' ||
                        (userType == 'branchManager' && !branchManagerType && countryId && countryId._id == countryIds.canada)) {
                        condition = true
                    }
                }
                if (item.key == 'complaintList') {
                    condition = false
                    if (userType == 'admin' || userType == 'hr' || userType == 'branchUser' ||
                        (userType == 'branchManager')) {
                        condition = true
                    }
                }
                if (item.key == 'requestCallBackList') {
                    condition = false
                    if (user.email == 'gehana@unizportal.com') {
                        condition = true
                    }
                }
                if (item.key == 'uploadPal') {
                    condition = false
                    if (userType == 'admin' || userType == 'cornerStoneManager') {
                        condition = true
                    }
                }
                if (item.key == 'uploadedPalList') {
                    condition = false
                    if (userType == 'admin' || (currentUser && currentUser.email == 'khushi@unizportal.com') || userType == 'cornerStoneManager') {
                        condition = true
                    }
                }

                if (item.key == 'instituteStudentList') {
                    condition = false
                    if (userType == 'institute' || referCodeForInstitute) {
                        condition = true
                    }
                }

                if (item.key == 'followupList') {
                    condition = false
                    if (userType == 'branchUser' && department == departmentObj.marketing) {
                        condition = true
                    }
                }
                if (item.key == 'travelPlan') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing) || userType == 'admin' || userType == 'gicMarketing') {
                        condition = true
                    }
                }
                if (item.key == 'travelPlanList') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.marketing) ||
                        userType == 'admin' || userType == 'gicMarketing' || userType == 'marketingManager'
                    ) {
                        condition = true
                    }
                }
                if (item.key == 'counsellingFollowupList') {
                    condition = false
                    if ((userType == 'branchUser' && department == departmentObj.counselling) ||
                        userType == 'counsellingManager' || userType == 'eventManager') {
                        condition = true
                    }
                }

                if (item.key == 'cornerStoneVisaApprovedApps') {
                    condition = false
                    if (userType == 'admin' || (user && user.email == 'khushi@unizportal.com')) {
                        condition = true
                    }
                }
                if (item.key == 'advanceCommissionReqList') {
                    condition = false
                    if (userType == 'admin' || (user && user.email == 'shellwin@unizportal.com')) {
                        condition = true
                    }
                }

                if (item.key == 'countryWiseAllCourse') {
                    condition = false
                    if (user && user.email == 'akshay@unizportal.com') {
                        condition = true
                    }
                }

                if (item.key == 'agentListByDirectUniversity') {
                    condition = false
                    if (department == departmentObj.marketing) {
                        condition = true
                    }
                }
                if (item.key == 'universityWiseReports') {
                    condition = false
                    if (user && user.email == 'shellwin@unizportal.com') {
                        condition = true
                    }
                }
                if (item.key == 'fcmtGenerateCommission' || item.key == 'ciraGenerateCommission') {
                    condition = false
                    if (user && user.email == accountantEmail) {
                        condition = true
                    }
                }

                if (item.key == 'eventList') {
                    condition = false
                    if (user && (userType == 'admin' || userType == 'userManager' || bloomsburyShowToUser.includes(user.email))) {
                        condition = true
                    }
                }
                if (item.key == 'bloomsburyInstituteApplications') {
                    condition = false
                    if (user && (userType == 'admin' || bloomsburyShowToUser.includes(user.email))) {
                        condition = true
                        if (user.email == 'hitanshu@unizportal.com') {
                            item.dontShowOnMenu = false
                        }
                    }
                }
                if (item.key == 'universityWiseAssignedAgents') {
                    condition = false
                    if (user && (userType == 'marketingManager' || userType == 'admin' || department == departmentObj.marketing)) {
                        condition = true
                    }
                }
                if (item.key == 'liveReporting') {
                    condition = false
                    if (user && (userType == 'admin' || userType == 'marketingManager' || department == departmentObj.marketing)) {
                        condition = true
                    }
                }

                if (item.key == 'allCountriesStatusList') {
                    condition = false
                    if (user && (userType == 'admin' || user.email == "hitanshu@unizportal.com")) {
                        condition = true
                    }
                }
                if (item.key == 'exchangeComponent' || item.key == 'editTxnStudent') {
                    condition = false
                    if (user && (userType == 'admin' || (userType == 'agent'))) {
                        condition = true
                    }
                }

                if (item.key == 'txnList') {
                    condition = false
                    if (user && (userType == 'admin' || userType == 'reeudoManager' || (userType == 'agent'))) {
                        condition = true
                    }
                }
                if (item.key == 'exchangeComponent') {
                    condition = false
                    if (user && (userType == 'admin' || userType == 'reeudoManager' || (userType == 'agent'))) {
                        condition = true
                    }
                }

                if (item.key == 'universitySearchCourse') {
                    condition = false
                    if (user && ((countryId && countryId._id == countryIds.uk && userType == 'branchManager' && !branchManagerType) || userType == 'admin' ||
                        userType == 'userManager' || userType == 'itTeam')) {
                        condition = true
                    }
                }

                if (item.key == 'casaApplicationList' || item.key == 'kasaApplicationList') {
                    if (user && user.email == kasaBranchUserEmail) {
                        item.dontShowOnMenu = false
                    }
                }

                if (!item.dontShowOnMenu && condition) {
                    return this.getSubMenuOrItem(item, parent)
                }
            })
            .filter(item => item)
    }

    // Get the currently selected menu
    getSelectedMenuKeys = pathname => {
        const {flatMenuKeys} = this.props
        return urlToList(pathname).map(itemPath =>
            getMenuMatches(flatMenuKeys, itemPath).pop()
        )
    }

    /**
     * get SubMenu or Item
     */
    getSubMenuOrItem = item => {
        // doc: add hideChildrenInMenu
        if (
            item.children &&
            !item.hideChildrenInMenu &&
            item.children.some(child => child.name)
        ) {
            const {name} = item
            return (
                <SubMenu
                    title={
                        item.icon ? (
                            <>
                                <span>{name}</span>
                                {item.newLink ?
                                    <i className={styles.newLink}>New</i> : null}
                            </>
                        ) : (
                            name
                        )
                    }
                    key={item.path}>
                    {this.getNavMenuItems(item.children)}
                </SubMenu>
            )
        }
        return <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>
    }

    /**
     * Judge whether it is http link.return a or Link
     * @memberof SiderMenu
     */
    getMenuItemPath = item => {
        const {name, prefixComp} = item
        let {newLink = false} = item
        const itemPath = this.conversionPath(item.path)
        const icon = getIcon(item.icon)
        const {target} = item
        // Is it a http link
        if (/^https?:\/\//.test(itemPath)) {
            return (
                <a href={itemPath} target={target}>
                    {icon}
                    <span>{name}</span>
                </a>
            )
        }
        const {location, isMobile, onCollapse} = this.props
        return (
            <Link
                to={itemPath}
                target={target}
                replace={itemPath === location.pathname}
                onClick={
                    isMobile
                        ? () => {
                            onCollapse(true)
                        }
                        : undefined
                }>


                {/*{icon ? icon : ""}*/}
                {/* {prefixComp ?
          <React.Fragment>
            <h3>{prefixComp}</h3> <br/>
          </React.Fragment>
          : ''}*/}
                <span>{name}</span>
                {newLink ?
                    <i className={styles.newLink}>New</i> : null}
            </Link>
        )
    }

    conversionPath = path => {
        if (path && path.indexOf('http') === 0) {
            return path
        }
        return `/${path || ''}`.replace(/\/+/g, '/')
    }

    render() {
        const {
            openKeys,
            navTheme,
            mode,
            location: {pathname},
            className,
            collapsed
        } = this.props
        // if pathname can't match, use the nearest parent's key
        let selectedKeys = this.getSelectedMenuKeys(pathname)
        if (!selectedKeys.length && openKeys) {
            selectedKeys = [openKeys[openKeys.length - 1]]
        }
        let props = {}
        if (openKeys && !collapsed) {
            props = {
                openKeys: openKeys.length === 0 ? [...selectedKeys] : openKeys
            }
        }
        const {handleOpenChange, style, menuData} = this.props
        const cls = classNames(className, {
            'top-nav-menu': mode === 'horizontal'
        })

        return (
            <Menu
                key='Menu'
                mode={mode}
                theme={navTheme}
                onOpenChange={handleOpenChange}
                selectedKeys={selectedKeys}
                style={style}
                className={cls}
                {...props}>
                {' '}
                {this.getNavMenuItems(menuData)}
            </Menu>
        )
    }
}
