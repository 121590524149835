import { customAxios as axios, getToken } from '../../request'
import {
  addBookingUrl,
  singleBookingUrl,
  updateBookingUrl,
  submitFinalCheckoutUrl,
  submitEnquiriesUrl
} from '../apis/paymentApi'
import { hidePageLoad, showPageLoad } from '../../modules/actions'

export const addBookingFxn = async (valData) => {
  let { data } = await axios.post(addBookingUrl(), valData, getToken())
  return data
}
export const singleBookingFxn = async (valData) => {
  let { data } = await axios.post(singleBookingUrl(), valData, getToken())
  return data
}

export const updateBookingFxn = async (valData) => {
  let { data } = await axios.post(updateBookingUrl(), valData, getToken())
  return data
}

export const submitFinalCheckoutFxn = async (valData) => {
  let { data } = await axios.post(submitFinalCheckoutUrl(), valData, getToken())
  return data
}

export const submitEnquiriesFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(submitEnquiriesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
