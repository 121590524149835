import React, {useState} from "react"
import {Button, Card, Col, Drawer, Form, notification, Row} from "antd";
import moment from "moment";
import GetEachFormFields from "../../../components/_utils/appFormUtils";

import {useDispatch} from "react-redux";
import { addAboutUniFxn } from '../actions/university'
const AddAboutUniversity = (props) => {
  let dispatch = useDispatch()
  let {visible, onClose, onSubmit, countryId, universityId, form: {getFieldDecorator, resetFields}} = props;
  let [editorKey, setEditorKey] = useState(moment())
  let emailFields = [
    {
      key: 'content',
      label: 'Content',
      required: true,
      placeholder: 'Content',
      type: 'ckeditor4',
      editorKey: editorKey,
      onChange: (x) => {
        props.form.setFieldsValue({
          content: x
        })
      }
    }

  ]
  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 24},
      md: {span: 24}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 24},
      md: {span: 24}
    }
  }

  const handleSubmit = (e) => {
    let {form} = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.countryId = countryId;
        valData.universityId = universityId;
        let {success} = await dispatch(addAboutUniFxn(valData))
        if (success) {
          setEditorKey(moment())
          resetFields()
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  return (
    <>
      <Drawer visible={visible} onClose={onClose} title={`About University`} width={"60%"}>
        <Card bordered={false}>
          <Form className={'vertical-form'} onSubmit={handleSubmit}>
            <Row gutter={16}>
              {emailFields.map((item, key) => {
                return (
                  <Col key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}

            </Row>
            <div className={'mt20 alignRight'}>
              <Button type={'success'} htmlType="submit">Submit</Button>
            </div>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}
const WrappedAddCourseStructure = Form.create()(AddAboutUniversity)
export default WrappedAddCourseStructure
