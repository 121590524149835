import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const options = {
  autoplay: true,
  loop: true,
  nav: true,
  dots: true,
  dotClass: 'owl-dot dotNone',
  margin: 1,
  autoplayTimeout: 5000,
  // autoplaySpeed:2000,
  // navSpeed:2000,
  responsive: {
    0: {
      items: 1,
      nav: true
    },
    600: {
      items: 1,
      nav: false
    },
    1000: {
      items: 1,
      nav: true,
      loop: false
    }
  }
  // autoWidth:true,

}

const SliderComponent = (props) => {
  const { accommodation } = props
  let [images, setImages] = useState({
    large: '',
    smalls: [],
    all: []
  })
  useEffect(() => {
    loadImages()
  }, [accommodation])
  let loadImages = () => {
    let large = ''
    let smalls = []
    let all = []
    _.each(accommodation, (item, index) => {
      all.push(item.large)
      if (index < 5) {
        if (index == 0) {
          large = item.large
        } else {
          smalls.push(item.medium)
        }
      }
    })
    setImages({
      large,
      smalls,
      all
    })
  }

  return (
    <>
      <div className={'room_slider'}>
        <div className={'mobile_view'}>
          <OwlCarousel
            className='owl-theme'
            {...options}>
            {images.all && images.all.length ? images.all.map((item, key) => {
              return (
                <div key={key + 25}>
                  <LazyLoadImage
                    src={item}
                    style={{ maxHeight: 450, height: '100%', borderRadius: 10 }}
                  />
                </div>
              )
            }) : null}
          </OwlCarousel>
        </div>
        <div className={'web_view'}>
          <div className='product-slider-box cds-2 clearfix mb-2'>
            <div className='product-img-slide'>
              <div className='row img-wrapper'>
                {accommodation.length ? <>
                  {images.large ? <div className='col-md-6 col-sm-12'>
                    <img
                      src={images.large}
                      className='img-fluid w-100 first-image'
                      alt='slider-photo'
                    />
                  </div> : null}
                  <div className='col-md-6 col-sm-12'>
                    <div className={'row'}>
                      {images.smalls && images.smalls.length ? images.smalls.map((item) => {
                        return (
                          <div className={'col-md-6'} key={item}>
                            <img
                              src={item}
                              className='img-fluid w-100 second-image'
                              alt='slider-photo'
                              style={{ borderRadius: '10px', marginBottom: '15px' }}
                            />
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>

                </> : null}
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default SliderComponent
