import {customAxios as axios, getToken} from '../../../request'
import {notification} from 'antd'
import {
    getCornerStoneApplicationListUrl,
    getCornerStoneOfferListUrl,
    getCornerStoneLoaListUrl,
    cornerStoneUploadDocumentUrl,
    interestedRequestListUrl,
    interestedRequestAddUrl,
    cornerStoneAddNoteUrl,
    cornerStoneNoteListUrl,
    updateInterestedListUrl,
    cornerStoneVisaApprovedAppsUrl,
    uploadScreenshotsUrl,
    getScreenshotsUrl,
    reuploadScreenshotsUrl,
    updateScreenshotStatusUrl,
    getSingleScreenshotUrl,
    getSingleScreenshotDocUrl,
    uploadOtherScreenshotUrl, relatedUniversityVisaApprovedAgentsUrl
} from '../apis/cornerStone'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'

export const getCornerStoneApplicationListFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getCornerStoneApplicationListUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const getCornerStoneOfferListFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getCornerStoneOfferListUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const getCornerStoneLoaListFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getCornerStoneLoaListUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const uploadCornerStoneDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(cornerStoneUploadDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Uploaded Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}
export const interestedRequestAddFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(interestedRequestAddUrl(), {...valData}, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Uploaded Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}
export const interestedRequestListFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(interestedRequestListUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const cornerStoneAddNoteFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(cornerStoneAddNoteUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Note added successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const cornerStoneNoteListFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(cornerStoneNoteListUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const updateInterestedListFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInterestedListUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `status Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const cornerStoneVisaApprovedAppsFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(cornerStoneVisaApprovedAppsUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const uploadScreenshotsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadScreenshotsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const getScreenshotsFxn = filters => async dispatch => {
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getScreenshotsUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data.data
}

export const reuploadScreenshotsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(reuploadScreenshotsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const updateScreenshotStatusFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateScreenshotStatusUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}


export const getSingleScreenshotFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(getSingleScreenshotUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const getSingleScreenshotDocFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(getSingleScreenshotDocUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const uploadOtherScreenshotFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadOtherScreenshotUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const relatedUniversityVisaApprovedAgentsFxn = async (valData) => {
    let {data} = await axios.post(relatedUniversityVisaApprovedAgentsUrl(), valData, getToken())
    return data
}
