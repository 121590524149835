import {default as React, useEffect, useState, useRef} from "react";

import{
  Button,
  Card,
  Drawer,
  Form,
  notification,
} from "antd"

import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { AddTemplateCategory } from './action/template'


const TemplateCategory = (props) => {
  const { visible, onClose, getCategoryList,countryId } = props;
  const { getFieldDecorator } = props.form;
  const [template, setTemplate] = useState(null);

  let inputTypes = {
    fields: [
      {
        key: "categoryName",
        label: "Category Name",
        required: true,
        placeholder: "Category Name",
        type: "text",
      },
    ],
  };
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.countryId = countryId._id

        let x = await AddTemplateCategory(valData);

        console.log(x, 'xxxxxxxaaaaaaaaaaaaaaaxxxxxxxxxxxxxxx')
        if (!x.error) {
          notification.success({
            message: x.message,
          });
          // onClose();
          // getCategoryList();
          setTemplate(x.countryId);
          props.form.setFieldsValue({
            categoryName: "",
          });
        }
      }
    });
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (

    <Drawer placement="right" onClose={onClose} visible={visible} width={"40%"} title={"Add Template Category"}>
      <Card>
        <Form onSubmit={handleSubmit}>
          <div className='card unizportal'>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-12 "} key={key}>
                  <GetEachFormFields
                    {...props.form}
                    item={item}
                    formItemLayout={formItemLayout}
                  />

                </div>
              );
            })}
            <Form.Item>
              <Button type='primary' htmlType='submit'  className={'btn'}>
                Submit
              </Button>
            </Form.Item>
          </div>
          </div>
        </Form>
      </Card>
    </Drawer>


  );
};

export default Form.create()(TemplateCategory);
