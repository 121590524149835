import {customAxios as axios, getToken} from "../../../request";
import {
    createAgentUrl,
    getFinalChangesInInrUrl,
    reeudoRatesUrl,
    reeudoSourceUrl,
    createQuotationUrl,
    createAgentTestUrl,
    singleAgentUrl,
    allQuotationUrl, transactionUrl,
    singleQuotationUrl, getMetaInfoUrl,
    updateStudentUrl, getTransactionUrl,
    updateStudentDocUrl, downloadStudentDocUrl, createSenderUrl, updateSenderDocUrl, getDocumentsByIdUrl,
    downloadSenderDocUrl, createPaymentUrl, createBeneficiaryUrl, paymentInstructionUrl,
    saveAgentMarginUrl,
    getAgentMarginUrl,
    getFinalPaymentChangesUrl, singleAgentMarginUrl, updateAgentMarginUrl,
    updateStudentDataUrl, updateKycStatusUrl, getA2FormUrl, uploadA2FormUrl, updateSenderUrl,
    getSenderDocsUrl, getReferenceUsersUrl
} from "../apis";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";
import {publicUniversityGraphUrl} from "../../dashboardMarketing/apis/marketingManagerApi";
import {notification} from "antd";

export const reeudoRatesFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(reeudoRatesUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const reeudoSourceFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(reeudoSourceUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const getFinalChangesInInrFxn = (valData) => async (dispatch) => {
    // dispatch(showPageLoad())
    let {data} = await axios.post(getFinalChangesInInrUrl(), valData, getToken())
    // dispatch(hidePageLoad())
    return data
}

export const createAgentFxn = (valData) => async (dispatch) => {

    dispatch(showPageLoad())
    let {data} = await axios.post(createAgentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const createQuotationFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(createQuotationUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const createAgentTestFxn = (valData) => async (dispatch) => {

    dispatch(showPageLoad())
    let {data} = await axios.post(createAgentTestUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const singleAgentMarginFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singleAgentMarginUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const allQuotationsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(allQuotationUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const singleQuotationFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singleQuotationUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const createTransactionFxn = (valData) => async (dispatch) => {

    dispatch(showPageLoad())
    let {data} = await axios.post(transactionUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const allTransactionFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getTransactionUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const getMetaInfoFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getMetaInfoUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const updateStudentFxn = (appId, valData) => async (dispatch) => {
    let config = {
        params: {appId},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(updateStudentUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const updateStudentDocFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateStudentDocUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const downloadStudentDocFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(downloadStudentDocUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const createSenderFxn = (appId, valData) => async (dispatch) => {
    let config = {
        params: {appId},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(createSenderUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const updateSenderDocFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateSenderDocUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const downloadSenderDocFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(downloadSenderDocUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const getDocumentByIdFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getDocumentsByIdUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const createPaymentFxn = (appId, valData) => async (dispatch) => {
    let config = {
        params: {appId},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(createPaymentUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}
export const createBeneficiaryFxn = (appId, valData) => async (dispatch) => {
    let config = {
        params: {appId},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(createBeneficiaryUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const paymentInstructionFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(paymentInstructionUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const getFinalPaymentChangesFxn = (valData) => async (dispatch) => {
    console.log(valData, "val-final")
    // dispatch(showPageLoad())
    let {data} = await axios.post(getFinalPaymentChangesUrl(), valData, getToken())
    // dispatch(hidePageLoad())
    return data
}

export const updateAgentMarginFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.put(updateAgentMarginUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const updateStudentDataFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateStudentDataUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const updateKycStatusFxn = (appId, valData) => async (dispatch) => {
    let config = {
        params: {appId},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(updateKycStatusUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const getA2FormFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getA2FormUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const uploadA2FormFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadA2FormUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}


export const updateSenderFxn = (appId, accountRef, valData) => async (dispatch) => {
    let config = {
        params: {appId, accountRef},
        ...(await getToken())
    };
    dispatch(showPageLoad())
    let {data} = await axios.post(updateSenderUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}
export const getSenderDocsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getSenderDocsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const getReferenceUsersFxn = async () => {
    let {data} = await axios.post(getReferenceUsersUrl(), {}, getToken())
    return data
}
