export const facilityImages = {
    wifi: require("../downloaded_Images/wifi.png"),

    "bills-included": require("../downloaded_Images/payment.png"),

    laundry: require("../downloaded_Images/washer.png"),

    "fully-furnished": require("../downloaded_Images/sofa.png"),

    gym: require("../downloaded_Images/gym.png"),

    games: require("../downloaded_Images/gamingConsole.png"),

    accessibility: require("../downloaded_Images/accessability.png"),

    "storage-space": require("../downloaded_Images/space.png"),

    "study-rooms": require("../downloaded_Images/study.png"),

    cinema: require("../downloaded_Images/cinema-screen.png"),

    "flexible-start-dates": require("../downloaded_Images/calendar.png"),

    support: require("../downloaded_Images/manager.png"),

    "bike-storage": require("../downloaded_Images/parking.png"),

    "social-area": require("../downloaded_Images/conference.png"),

    insurance: require("../downloaded_Images/life-insurance.png"),
    security: require("../downloaded_Images/cctv.png"),
    "air-conditioning": require("../downloaded_Images/air-conditioner.png"),
    "swimming-pool": require("../downloaded_Images/swimming-pool.png"),
    cleaning: require("../downloaded_Images/cleaning.png"),
    advice: require("../downloaded_Images/advice.png"),

    //   laundry: require("../downloaded_Images/washingMachine.png"),
    //   furnished: require("../downloaded_Images/furnished.png"),
};
