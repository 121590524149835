import { apiUrl2 } from '../../../../settings'

export const addTemplateCategoryUrl = () => {
  return `${apiUrl2}/addTemplateCategory`;
};
export const addTemplateUrl = () => {
  return `${apiUrl2}/addTemplate`;
};
export const templateListUrl = () => {
  return apiUrl2 + "/getTemplateList";
};
export const getSingleTemplateUrl = (templateId) => {
  return apiUrl2 + "/getSingleTemplate/" + templateId;
};
export const getTemplateCategoryListUrl = () => {
  return apiUrl2 + "/templateCategoryList";
};
export const addTemplateSectionUrl = () => {
  return apiUrl2 + "/addTemplateSection";
};
export const templateEditUrl = () => {
  return apiUrl2 + "/updateTemplateSection";
};
export const removeTemplateUrl = () => {
  return apiUrl2  + "/removeTemplateSection";
};
export const templateSOPUrl = () => {
  return `${apiUrl2}/templateForSop`;
};
// export const templateSOPUrl = () => {
//   return `${apiUrl2}/sectionFound`;
// };

export const GenerateSOPUrl = () => {
  return apiUrl2 + "/generateSop";
};
export const sopListUrl = () => {
  return `${apiUrl2}/sopList`;
};
export const updateTemplateUrl = () => {
  return apiUrl2 + "/updateTemplate";
};
export const updateTemplateActiveStatusUrl = () => {
  return apiUrl2 + "/updateTemplateAvailability";
};
