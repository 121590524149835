import React, { useEffect, useState } from 'react'
import {Button, Card, Col, Drawer, Form, notification, Row} from "antd";
import moment from "moment";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {
  addCourseCategoryFxn,
  addCourseCategoryStructureFxn,
  addCourseStructureFxn,
  addTemplateCourseSectionFxn
} from '../actions/courseAction'
import {useDispatch} from "react-redux";
import { ProgramLevel } from '../../../components/_utils/appUtils'
import { listAllCourseCategory } from '../../CourseCategory/actions'

const AddCourseCategory = (props) => {
  const [categoryList, setCategoryList] = useState([])
  const loadCourseCategory = async () => {
    let obj = {
      results: 100,
      count: 100
    }
    let { data } = await dispatch(listAllCourseCategory(obj))
    setCategoryList(data)
  }


  useEffect(() => {

    loadCourseCategory()
  }, [])
  let dispatch = useDispatch()
  let {visible, onClose, onSubmit, courseId, form: {getFieldDecorator, resetFields}} = props;
  let [editorKey, setEditorKey] = useState(moment())
  let emailFields = [
    {
      key: 'courseLevel',
      label: 'Course Level',
      required: true,
      type: 'select',
      options: ProgramLevel,
      onChange: courseLevel => {
        props.form.setFieldsValue({ courseLevel })
      }
    },
    {
      key: 'categoryId',
      label: 'Course Category',
      required: true,
      type: 'select',
      options: categoryList,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.name}`,
      onChange: categoryId => {
        props.form.setFieldsValue({ categoryId })
      }
    },
    {
      key: 'content',
      label: 'Content',
      required: true,
      placeholder: 'Content',
      type: 'ckeditor',
      editorKey: editorKey
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 24},
      md: {span: 24}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 24},
      md: {span: 24}
    }
  }

  const handleSubmit = (e) => {
    let {form} = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        let {success} = await dispatch(addTemplateCourseSectionFxn(valData))
        if (success) {
          setEditorKey(moment())
          resetFields()
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  return (
    <>
      <Drawer visible={visible} onClose={onClose} title={`Add Template Course Category`} width={"60%"}>
        <Card bordered={false}>
          <Form className={'vertical-form'} onSubmit={handleSubmit}>
            <Row gutter={16}>
              {emailFields.map((item, key) => {
                return (
                  <Col key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}

            </Row>
            <div className={'mt20 alignRight'}>
              <Button type={'success'} htmlType="submit">Submit</Button>
            </div>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}
const WrappedAddCourseStructure = Form.create()(AddCourseCategory)
export default WrappedAddCourseStructure
