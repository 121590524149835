import { apiUrl, apiUrl2 } from '../../../settings'

export const addUniversityUrl = () => {
  return apiUrl + '/university'
}

export const listUniversitiesUrl = () => {
  return apiUrl + '/university'
}
export const userWiseUniversityListUrl = () => {
  return apiUrl + '/userWiseUniversityList'
}

export const listUniversityUrl = (id) => {
  return apiUrl + '/university/' + id
}
export const quickSearchUniversity = () => {
  return apiUrl + '/quickSearch/university'
}

export const loadAllUniversityNameUrl = () => {
  return apiUrl + '/api/loadAllUniversityName'
}

export const universityIsFeaturedUrl = () => {
  return apiUrl + '/api/universityIsFeatured'
}
export const starUniversityUrl = () => {
    return apiUrl + '/api/starUniversity'
}

export const exportUniversityListUrl = () => {
    return apiUrl + '/api/exportUniversityList'
}
export const listCourseUrl = () => {
    return apiUrl + '/course'
}
export const updateTopUniversityUrl = () => {
    return apiUrl + '/api/topUniversity'
}
export const listAllUniversitiesAccTemplateUrl = () => {
  return apiUrl2 + '/universityAccToTemplate'
}

export const updateUniversityUrl = () => {
  return apiUrl2 + '/updateUniversity'
}

export const getSingleUniversityDataUrl = () => {
  return apiUrl2 + '/getUniversityById'
}
export const addAboutUniUrl = () => {
  return apiUrl2 + '/addAboutUni'
}
export const getAllUniUrl = () => {
  return apiUrl2 + '/getAboutUni'
}
export const deleteAllUniUrl = () => {
  return apiUrl2 + '/removeAboutUni'
}
