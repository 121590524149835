import { apiUrl, apiUrl2 } from '../../../settings'

export const getCornerStoneApplicationListUrl = () => {
  return apiUrl + '/getCornerStoneApplicationList'
}

export const getCornerStoneOfferListUrl = () => {
  return apiUrl + '/corner-stone/offer-list'
}

export const getCornerStoneLoaListUrl = () => {
  return apiUrl + '/corner-stone/loa-list'
}

export const cornerStoneUploadDocumentUrl = () => {
  return apiUrl + '/corner-stone/upload-documents'
}

export const interestedRequestAddUrl = () => {
  return apiUrl + '/interested-request/add'
}
export const interestedRequestListUrl = () => {
  return apiUrl + '/interested-request/list'
}

export const cornerStoneAddNoteUrl = () => {
  return apiUrl + '/cornerstone/add-note'
}

export const cornerStoneNoteListUrl = () => {
  return apiUrl + '/cornerstone/note-list'
}

export const updateInterestedListUrl = () => {
  return apiUrl + '/interested-request/update'
}

export const cornerStoneVisaApprovedAppsUrl = () => {
  return apiUrl2 + '/cornerstone/visa-approved-apps'
}

export const uploadScreenshotsUrl = () => {
  return apiUrl2 + '/cornerstone/upload-screenshots'
}

export const getScreenshotsUrl = () => {
  return apiUrl2 + '/cornerstone/get-screenshots'
}

export const reuploadScreenshotsUrl = () => {
  return apiUrl2 + '/cornerstone/reupload-screenshots'
}

export const updateScreenshotStatusUrl = () => {
  return apiUrl2 + '/cornerstone/update-screenshot-status'
}

export const getSingleScreenshotUrl = () => {
  return apiUrl2 + '/cornerstone/get-single-screenshot'
}
export const getSingleScreenshotDocUrl = () => {
  return apiUrl2 + '/cornerstone/get-single-screenshot-doc'
}

export const uploadOtherScreenshotUrl = () => {
  return apiUrl2 + '/cornerstone/upload-other-screenshots'
}

export const relatedUniversityVisaApprovedAgentsUrl = () => {
  return apiUrl2 + '/relatedUniversityVisaApprovedAgents'
}
